import { Component, OnInit } from '@angular/core';
import { ExperiencesService } from '../experiences/experiences/experiences.service';
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-positioning',
  templateUrl: './positioning.component.html',
  styleUrls: ['./positioning.component.scss']
})
export class PositioningComponent implements OnInit {
  experiences: any[] = [];
  selectedValues: any[] = [];
  experienceSelected: any;
  maxExperiences: number = 10;

  constructor(
    private experienceService: ExperiencesService,
  ) {
    this.experienceService.getExperiences().subscribe((experiences => {
      this.experiences = experiences['hydra:member'];
      this.selectedValues = this.experiences.map((experience) => experience['@id']);
    }));
  }

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.experiences, event.previousIndex, event.currentIndex);
  }

  loadExperiences(experienceId) {
    //avoid the variable experienceSelected to be greater than 2 using categoriesId to find the new categories
    if (experienceId.length > 2) {
      experienceId.splice(2, 1);
      this.experienceSelected = experienceId;
      return;
    }

  }

}
