import { Component, OnInit, ViewChild } from '@angular/core';
import { LocationService } from '../locations/location.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-locations-list',
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.css']
})
export class LocationsListComponent implements OnInit {
  columns = [
    { prop: 'name', name: 'Nombre' },
    { prop: 'country', name: 'País' },
    { prop: 'civitatisId', name: 'Civitatis Id' }];
  rows = [];
  searchForm: UntypedFormGroup;
  alert = {message: null, type: null};
  @ViewChild(DatatableComponent) table: DatatableComponent;
  dialogRef: BsModalRef;

  constructor(
    private locationsService: LocationService,
    private searchFormBuilder: UntypedFormBuilder,
    public dialog: BsModalService,
  ) {
    this.locationsService.getLocations().subscribe((locations => {
      this.rows = locations[ 'hydra:member' ];
    }));
  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      name: [''],
      country: ['']
    });
  }

  applyFilter() {
    this.locationsService.getLocations(this.searchForm.value).subscribe(
      data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
      }
    );
  }

  deleteLocation(locationId) {
    this.dialogRef = this.dialog.show(ConfirmationDialogComponent, {
      initialState: {
        confirmTitle: '¿Quieres eliminar esta ubicación?',
        confirmMessage: 'No podrás volver a recuperarla',
        callback: (confirm) => {
          if (confirm) {
            this.locationsService.deleteLocation(locationId).subscribe(
              (result) => {
                this.alert.type = 1;
                this.alert.message = 'Localidad eliminada correctamente';
                setTimeout(() => {
                  this.alert = {message: null, type: null};
                }, 5000);
              },
              error => {
                this.alert.message = error.error.code + '- ' + error.error.message;
                this.alert.type = 2;
                setTimeout(() => {this.alert = {message: null, type: null};
                }, 5000);
              },
            );
            setTimeout(() => {
              this.locationsService.getLocations().subscribe((locations => {
                this.rows = locations[ 'hydra:member' ];
                this.table.offset = 0;
              }));
            }, 2000);
          }
          this.dialogRef = null;
        }
      }
    });
  }

}
