<div class="d-flex align-items-center">
  <h1 class="page-title m-0"><i class="fa fa-binoculars small"></i> Experiencias</h1>
  <!-- <a href="/nueva-experiencia" class="btn btn-primary ml-3">Añadir nueva Experiencia</a> -->
</div>
<div class="mt-4" *ngIf="alert.message">
  <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
</div>
<div class="card mt-4">
  <div class="card-body p-3">
    <h5>Buscar por: </h5>
    <form [formGroup]="searchForm" (submit)="applyFilter()">
      <div class="form-row flex-fill">
        <div class="col">
          <input placeholder="ID" type="number" formControlName="id" class="form-control form-control-sm">
        </div>
        <div class="col">
          <input placeholder="Nombre" type="text" formControlName="name" class="form-control form-control-sm">
        </div>
        <div class="col">
          <input placeholder="Ubicación" type="text" formControlName="location" class="form-control form-control-sm">
        </div>
        <div class="col">
          <input placeholder="Maker" type="email" formControlName="maker" class="form-control form-control-sm">
        </div>
        <button type="submit" class="btn btn-success btn-sm col-md-2">Buscar</button>
      </div>
    </form>
  </div>
</div>
<div class="card mt-2">
  <div class="card-body p-4">
    <span class="checkboxContainer">Mostrar columnas:</span>
    <div class="d-flex mb-3 checkboxContainer">
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="Id">ID</small>
        <input [checked]="checkCheckbox('id')" (click)="enableColumns({ prop: 'id', name: 'ID', order: 1 })"
          type="checkbox" name="id">
      </div>
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="Name">Nombre</small>
        <input [checked]="checkCheckbox('name')" (click)="enableColumns({ prop: 'name', name: 'Nombre', order: 2 })"
          type="checkbox" name="Name">
      </div>
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="Location">Ubicación</small>
        <input [checked]="checkCheckbox('location.name')"
          (click)="enableColumns({ prop: 'location.name', name: 'Ubicación', order: 3 })" type="checkbox"
          name="Location">
      </div>
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="Maker">Maker</small>
        <input [checked]="checkCheckbox('maker.email')"
          (click)="enableColumns({ prop: 'maker.email', name: 'Maker', order: 4 })" type="checkbox" name="Maker">
      </div>
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="Active">Activa</small>
        <input [checked]="checkCheckbox('activeColumn')" (click)="enableColumns('activeColumn')" type="checkbox"
          name="Active">
      </div>
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="Spanish">Español</small>
        <input [checked]="checkCheckbox('spanishColumn')" (click)="enableColumns('spanishColumn')" type="checkbox"
          name="Spanish">
      </div>
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="English">Inglés</small>
        <input [checked]="checkCheckbox('englishColumn')" (click)="enableColumns('englishColumn')" type="checkbox"
          name="English">
      </div>
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="Actions">Acciones</small>
        <input [checked]="checkCheckbox('actionsColumn')" (click)="enableColumns('actionsColumn')" type="checkbox"
          name="Actions">
      </div>
    </div>
    <ngx-datatable #table class="bootstrap" [rowHeight]="'auto'" [columns]="columns" [columnMode]="'force'"
      [headerHeight]="50" [footerHeight]="50" [limit]="10" [rows]="rows">
      <ngx-datatable-column *ngFor="let column of columns; let i = index;" name="{{column.name}}"
        prop="{{column.prop}}">
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="activeColumn == true" name="Activa" prop="isActive">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <input type="checkbox" class="form-check-input" id="isActive"
            (click)="isActive($event.target['checked'], row)" [checked]="row.isActive">
          <label class="form-check-label" for="isActive"></label>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="spanishColumn == true" name="Español" prop="isSpanishChecked">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <input type="checkbox" class="form-check-input" id="isSpanishChecked"
            (click)="isActive($event.target['checked'], row['@id'].replace('/experiences/', ''))"
            [checked]="row.isSpanishChecked" disabled>
          <label class="form-check-label" for="isSpanishChecked"></label>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="englishColumn == true" name="Inglés" prop="isEnglishChecked">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <input type="checkbox" class="form-check-input" id="isEnglishChecked"
            (click)="isActive($event.target['checked'], row['@id'].replace('/experiences/', ''))"
            [checked]="row.isEnglishChecked" disabled>
          <label class="form-check-label" for="isEnglishChecked"></label>
        </ng-template>
      </ngx-datatable-column>
      <!-- <ngx-datatable-column *ngIf="true == true" name="Score" prop="score">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <input type="text" class="form-control form-control-sm w-50" id="score"
            (click)="isActive($event.target['checked'], row['@id'].replace('/experiences/', ''))" [value]="row.score" disabled>
          <label class="form-check-label" for="score"></label>
        </ng-template>
      </ngx-datatable-column> -->
      <!-- <ngx-datatable-column *ngIf="true == true" name="Enhancer" prop="enhancer">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <input type="text" class="form-control form-control-sm w-50" id="enhancer"
            (click)="isActive($event.target['checked'], row['@id'].replace('/experiences/', ''))">
          <label class="form-check-label" for="enhancer"></label>
        </ng-template>
      </ngx-datatable-column> -->
      <ngx-datatable-column *ngIf="actionsColumn == true" name="Acciones" prop="actions">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <!-- <a class="btn btn-sm btn-success mr-1" [href]="'/editar-experiencia/'+row['@id'].replace('/experiences/', '')" -->
          <a class="btn btn-sm btn-success mr-1" (click)="goExperience(row)" tooltip="Editar" placement="bottom">
            <i class="fa fa-pencil"></i>
          </a>
          <button class="btn btn-sm btn-outline-primary" (click)="deleteExperience(row['@id'])" tooltip="Eliminar"
            placement="bottom">
            <i class="fa fa-trash"></i>
          </button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>