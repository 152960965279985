<div class="container-fluid w-430 pt-4 mb-5">
  <div class="justify-content-center mt-5">
    <div class="card">
      <div class="card-header bg-white text-center">
        <img src="assets/venntur-isotype-38.png">
        <h1>Iniciar sesión</h1>
      </div>
      <div class="card-body">
        <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
          <div class="row">
            <div class="col-12 form-group">
              <label>E-mail</label>
              <input placeholder="E-mail" type="text" class="form-control" formControlName="email">

            </div>
            <div class="col-12 form-group">
              <label>Contraseña</label>
              <input type="password" appPassToggle placeholder="Contraseña" class="form-control" formControlName="password">
            </div>
          </div>
          <button class="btn btn-primary w-100" type="submit">
            Iniciar sesión
          </button>
        </form>
      </div>
    </div>
  </div>
  <div class="mt-4" *ngIf="alert.message">
    <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
  </div>
</div>
