import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Taker, TakerCollection } from './taker.model';


const API_URL = environment.apiUrl;

@Injectable()
export class TakersService {
  constructor(
    private http: HttpClient
  ) { }

  public postTaker(taker): Observable<Taker> {
    return this.http
      .post<Taker>(API_URL + '/takers', taker);
  }

  public getTaker(id: string): Observable<Taker> {
    return this.http
      .get<Taker>(API_URL + '/takers/' + id);
  }

  public getTakers(filters?: any): Observable<TakerCollection> {
    if (filters === undefined) { filters = {}; }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('name', String(filters.name));
    }
    if (filters['surname']) {
      httpParams = httpParams.set('document', String(filters.document));
    }
    return this.http
      .get<TakerCollection>(API_URL + '/takers', { params: httpParams });
  }

  public deleteTaker(takerId: string): any {
    return this.http.delete<Taker>(API_URL + takerId);
  }

  public putTaker(takerId: string, taker: Taker): Observable<Taker> {
    return this.http
      .put<Taker>(API_URL + '/takers/' + takerId, taker);
  }
}
