import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Reseller, ResellerCollection } from './reseller.model';


const API_URL = environment.apiUrl;

@Injectable()
export class ResellerService {
  constructor(private http: HttpClient) { }

  public getResellers(filters?: any): Observable<ResellerCollection> {
    if (filters === undefined) { filters = {}; }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('name', String(filters.name));
    }
    if (filters['fiscalName']) {
      httpParams = httpParams.set('fiscalName', String(filters.fiscalName));
    }
    if (filters['cif']) {
      httpParams = httpParams.set('cif', String(filters.cif));
    }
    if (filters['urlWeb']) {
      httpParams = httpParams.set('urlWeb', String(filters.urlWeb));
    }
    if (filters['tags']) {
      filters.tags.forEach( tag =>
        httpParams = httpParams.append('tags.id[]', tag)
      );
    }
    if (filters['locations']) {
      filters.locations.forEach( location =>
        httpParams = httpParams.append('locations.id[]', location)
      );
    }
    if (filters['isFavorites']) {
      httpParams = httpParams.append('makers.id[]', String(filters.makerId));
    }
    return this.http
      .get<ResellerCollection>(API_URL + '/resellers', { params: httpParams });
  }

  public getResellerByUserId(userId: number): Observable<any> {
    return this.http
      .get<any>(API_URL + '/users/' + userId + '/reseller');
  }

  public getResellersByMakerId(makerId: number, filters: any): Observable<any> {
    if (filters === undefined) { filters = {}; }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('reseller.name', String(filters.name));
    }
    if (filters['fiscalName']) {
      httpParams = httpParams.set('reseller.fiscalName', String(filters.fiscalName));
    }
    if (filters['urlWeb']) {
      httpParams = httpParams.set('reseller.urlWeb', String(filters.urlWeb));
    }
    if (filters['tags']) {
      filters.tags.forEach( tag =>
        httpParams = httpParams.append('reseller.tags.id[]', tag)
      );
    }
    if (filters['locations']) {
      filters.locations.forEach( location =>
        httpParams = httpParams.append('reseller.locations.id[]', location)
      );
    }
    return this.http
      .get<any>(API_URL + '/makers/' + makerId + '/favorite_resellers', { params: httpParams });
  }

  public getReseller(resellerId: string, language = 'es'): Observable<any> {
    return this.http
      .get<any>(API_URL + resellerId, { headers: {
      'Accept-Language': language
    }});
  }

  public getName() {
    return JSON.parse(localStorage.getItem('reseller')) ? JSON.parse(localStorage.getItem('reseller'))['name'] : null;
  }

  public getId() {
    return JSON.parse(localStorage.getItem('reseller')) ? JSON.parse(localStorage.getItem('reseller'))['id'] : null;
  }

  public putReseller(resellerId: string, reseller: Reseller, language = 'es'): Observable<Reseller> {
    return this.http
      .put<Reseller>(API_URL + '/resellers/' + resellerId, reseller, { headers: {
      'Accept-Language': language
    }});
  }

  public postReseller(reseller): Observable<Reseller> {
    return this.http
      .post<Reseller>(API_URL + '/resellers', reseller);
  }

  public postContact(contact): Observable<any> {
    return this.http
      .post<any>(API_URL + '/contacts', contact);
  }

  public deleteContact(contactId: string): any {
    return this.http.delete<any>(API_URL + contactId);
  }

  public getContact(contactId: string, language = 'es'): Observable<any> {
    return this.http
      .get<any>(API_URL + '/contacts/' + contactId, { headers: {
          'Accept-Language': language
        }});
  }

  public getContactsByResellerId(resellerId: number): Observable<any> {
    return this.http
      .get<any>(API_URL + '/resellers/' + resellerId + '/contacts');
  }

  public putContact(contactId: string, contact, language = 'es'): Observable<any> {
    return this.http
      .put<Reseller>(API_URL + '/contacts/' + contactId, contact, { headers: {
          'Accept-Language': language
      }});
  }

  public postFavoriteExperience(favoriteExperience): Observable<any> {
    return this.http
      .post<any>(API_URL + '/favorite_experiences', favoriteExperience);
  }

  public deleteFavoriteExperience(favoriteExperienceId: string): any {
    return this.http.delete<any>(API_URL + favoriteExperienceId);
  }

  public putFavoriteExperience(favoriteExperienceId: string, favoriteExperience: any): Observable<any> {
    return this.http
      .put<any>(API_URL + favoriteExperienceId, favoriteExperience);
  }

  public postFavoriteMaker(favoriteMaker): Observable<any> {
    return this.http
      .post<any>(API_URL + '/favorite_makers', favoriteMaker);
  }

  public deleteFavoriteMaker(favoriteMakerId: string): any {
    return this.http.delete<any>(API_URL + favoriteMakerId);
  }

  public putFavoriteMaker(favoriteMakerId: string, favoriteMaker: any): Observable<any> {
    return this.http
      .put<any>(API_URL + favoriteMakerId, favoriteMaker);
  }
}
