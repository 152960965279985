import { UntypedFormGroup, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Custom validator functions for reactive form validation
 */
export class CustomValidators {
  /**
   * Validates that child controls in the form group are equal
   */

  static dniOrNieValidator(control: AbstractControl) {
    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const str = control.value ? control.value.toString().toUpperCase() : '';

    const isNIF = nifRexp.test(str);
    const isNIE = nieRexp.test(str);
    if (!isNIF && !isNIE) {
      return {
        dniOrNie: 'Formato de documento no válido',
      };
    }

    const nie = str
      .replace(/^[X]/, '0')
      .replace(/^[Y]/, '1')
      .replace(/^[Z]/, '2');

    const letter = str.substr(-1);
    const charIndex = parseInt(nie.substr(0, 8), 10) % 23;
    if (validChars.charAt(charIndex) !== letter) {
      return {
        dniOrNie: 'La letra no coincide con el documento',
      };
    }
    return null;
  }

  static spanishDocumentValidator: ValidatorFn = (formGroup: UntypedFormGroup) => {
    if (formGroup.get('country').value.code !== 'ES') {
      return null;
    }
    const str = formGroup.get('document').value ? formGroup.get('document').value.toString().toUpperCase() : '';

    if (CustomValidators.isValidCif(str) === null) {
      return null;
    } else {
      return CustomValidators.dniOrNieValidator(formGroup.get('document'));
    }
  }

  private static isValidCif(cif) {
    if (!cif || cif.length !== 9) {
      return {
        invalidCif: 'Documento inválido',
      };
    }

    const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
    const digits = cif.substr(1, cif.length - 2);
    const letter = cif.substr(0, 1);
    const control = cif.substr(cif.length - 1);
    let sum = 0;
    let i;
    let digit;

    if (!letter.match(/[A-Z]/)) {
      return {
        invalidCif: 'Documento inválido',
      };
    }

    for (i = 0; i < digits.length; ++i) {
      digit = parseInt(digits[i], 10);

      if (isNaN(digit)) {
        return {
          invalidCif: 'Documento inválido',
        };
      }

      if (i % 2 === 0) {
        digit *= 2;
        if (digit > 9) {
          digit = Math.floor(digit / 10) + (digit % 10);
        }

        sum += digit;
      } else {
        sum += digit;
      }
    }

    sum %= 10;
    if (sum !== 0) {
      digit = 10 - sum;
    } else {
      digit = sum;
    }

    if (letter.match(/[ABEH]/)) {
      return String(digit) === control ? null : {
        invalidCif: 'Documento inválido',
      };
    }
    if (letter.match(/[NPQRSW]/)) {
      return letters[digit] === control ? null : {
        invalidCif: 'Documento inválido',
      };
    }

    return String(digit) === control || letters[digit] === control ? null : {
      invalidCif: 'Documento inválido',
    };
  }

  static domainsRestriction(allowedDomains: any = '') {
    return (control: AbstractControl): { [key: string]: any } | null => {
     if (!control.value) {
       return null;
     }
     const currentDomain = '@' + control.value.split('@')[1];
     if (control.value === null || control.value === '' || !allowedDomains ||
        allowedDomains.length === 0 || allowedDomains.includes(currentDomain)) {
        return null;
      } else {
        return { unallowedDomain: { value: control.value}};
      }
    };
  }
}

/**
 * Collection of reusable RegExps
 */
export const regExps: { [key: string]: RegExp } = {
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{4,15}$/,
  url: /^(http(s)?:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
  phone: /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{2,3})[-. )]*)?((\d{2,3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/,
  domain: /^(@[-a-zA-Z0-9._]{2,256}\.[a-z]{2,14})(\,\s?@[-a-zA-Z0-9._]{2,256}\.[a-z]{2,14})*$/,
  lettersAndNumbers: /^([A-Za-z0-9 \xc0-\xff\d])*$/i,
  onlyLetters: /^([A-Za-zÀ-ÖØ-öø-ÿ ])+$/,
  onlyNumbers: /^([0-9])+$/,
  slug: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
};

/**
 * Collection of reusable error messages
 */
export const errorMessages: ValidationErrors = {
  languageName: 'El nombre del idioma es obligatorio',
  locationName: 'El nombre de la localidad es obligatorio',
  countryName: 'El nombre del país es obligatorio',
  categoryName: 'El nombre de la categoría es obligatorio',
  tagName: 'El nombre de la etiqueta es obligatorio',
  password: 'La contraseña debe tener entre 5 y 15 caracteres, y contener al menos una mayúscula, una minúscula y un número',
  makerDocumentType: 'El tipo de documento es obligatorio',
  document: 'El documento debe ser válido',
  makerName: 'El nombre del maker es obligatorio',
  makerWeb: 'La web del maker es obligatoria. Ej: https://dominio.com',
  mediaMaker: 'El logo del maker es obligatorio',
  email: 'El email debe ser un correo electrónico válido (nombre@dominio)',
  phone: 'El teléfono debe ser válido, incluir perfijo del país y no contener espacios o caracteres especiales. Ej: +34600123456',
  experienceName: 'El nombre de la experiencia es obligatorio',
  experienceUrl: 'La url debe ser un enlace válido. Ej: https://dominio.com',
  experienceUrlButtonText: 'El texto del botón es obligatorio',
  experienceDescription: 'La descripción de la experiencia debe tener entre 120 y 600 caracteres',
  experienceDuration: 'La duración de la experiencia es obligatoria',
  experienceLanguages: 'El idioma de la experiencia es obligatorio',
  experienceCategories: 'La categoría de la experiencia es obligatoria',
  experienceTags: 'La etiqueta de la experiencia es obligatoria',
  experienceCountry: 'El país de la experiencia es obligatorio',
  experienceLocation: 'La localidad de la experiencia es obligatoria',
  experienceMaker: 'El maker de la expeiencia es obligatorio',
  mediaExperiences: 'La imagen de la experiencia es obligatoria'
};


export function getMessage(errorIndex: string, error: any = {}) {
  const messages: ValidationErrors = {
    ...errorMessages,
  };

  return messages[errorIndex];
}

export function getMessageTranslations(errorIndex: string, error: any = {}, translateService) {
  const messages: ValidationErrors = {
      invalidAge: translateService.instant('Para este evento debes tener entre ') + error.minAge +
        translateService.instant(' y ') + error.maxAge + translateService.instant(' años ') +
        translateService.instant(error.when) + translateService.instant(' y ') + translateService.instant(' tendrás ') + error.age
  };

  return messages[errorIndex];
}
