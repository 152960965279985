import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ExperiencesService } from './experiences.service';
import { Experience } from './experience.model';
import { CategoriesService } from '../../categories/categories/categories.service';
import { Category } from '../../categories/categories/category.model';
import { LanguagesService } from '../../languages/languages/languages.service';
import { Language } from '../../languages/languages/language.model';
import { HttpEventType } from '@angular/common/http';
import { MediaExperiencesService } from './media-experiences.service';
import { ReplaySubject } from 'rxjs';
import { LocationService } from '../../locations/locations/location.service';
import { CountryService } from '../../locations/locations/country.service';
import { Country } from '../../locations/locations/country.model';
import { Location } from '../../locations/locations/location.model';
import { Maker } from '../../makers/makers/maker.model';
import { MakersService } from '../../makers/makers/makers.service';
import { errorMessages, regExps } from '../../shared/custom-validators';
import { Tag } from '../../tags/tags/tag.model';
import { TagsService } from '../../tags/tags/tags.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-experiences',
  templateUrl: './experiences.component.html',
  styleUrls: ['./experiences.component.css']
})
export class ExperiencesComponent implements OnInit {
  experienceForm: UntypedFormGroup;
  experience: Experience;
  makers: Maker[] = [];
  makerSelected = [];
  categories: Category[] = [];
  categoriesSelected = [];

  allCategories: Category[] = [];
  subcategories: Category[] = [];
  subcategoriesSelected = [];


  tags: Tag[] = [];
  tagsSelected = [];
  languages: Language[] = [];
  languagesSelected = [];
  promotionTypes = ['Descuento', '2x1', 'Niños gratis'];
  promotionTypeSelected = null;
  selectedFile: File;
  uploadedFiles = [];
  selectedFiles = [];
  mediaExperiences = [];
  images = [];
  uploadingProgressing = false;
  uploadComplete = false;
  serverResponse: any;
  uploadProgress = 0;
  alert = {message: null, type: null};
  errors = errorMessages;
  countries = [];
  title = 'Nueva experiencia';
  public filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
  countrySelected = null;

  locations: Location[] = [];
  public filteredLocations: ReplaySubject<Location[]> = new ReplaySubject<Location[]>(1);
  locationSelected = null;

  descriptionText = '';
  descriptionMaxChar = 600;

  fileToUpload: File = null;
  @ViewChild('labelUpload')
  labelUpload: ElementRef;

  monthList: string[] = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ];
  urlButtonTexts = [
    {value: 'more-info', text: '+ Info'},
    {value: 'book', text: 'Reservar'},
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private experienceService: ExperiencesService,
    private makerService: MakersService,
    private categoryService: CategoriesService,
    private tagService: TagsService,
    private languageService: LanguagesService,
    private mediaExperienceService: MediaExperiencesService,
    private countryService: CountryService,
    private locationService: LocationService,
    private el: ElementRef,
    public translate: TranslateService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.id) {
      this.title = 'Editar experiencia';
      setTimeout(() => {
        this.editExperience(this.activatedRoute.snapshot.params.id);
      }, 1000);
    } else {
      this.setRelations();
    }
  }

  createForm() {
    this.experienceForm = this.formBuilder.group({
      name: [ '',
        Validators.required
      ],
      url: [ '', [
        Validators.pattern(regExps.url),
        Validators.required]
      ],
      urlButtonText: [ '',
        //Validators.required
      ],
      description: [ '',
        [Validators.required,
          Validators.minLength(120),
          Validators.maxLength(600)]
      ],
      seasonStartMonth: [ ,
      ],
      seasonEndMonth: [ ,
      ],
      duration: [ ,
        Validators.required
      ],
      promotionType: [ ,
      ],
      promotion: [ '',
      ],
      sustainableSeal: [ false,
      ],
      reviewTripadvisor: [ '',
      ],
      mediaExperiences: [ '',
        Validators.required
      ],
      maker: [ '',
        Validators.required
      ],
      languages: [ '',
        Validators.required
      ],
      categories: [ '',
        Validators.required
      ],
      subcategories: [ ''],
      tags: [ '',
        Validators.required
      ],
      country: [ '',
        Validators.required
      ],
      location: [ '',
        Validators.required
      ],
      isEnglishChecked: [ false ]
    });
  }

  setRelations() {
    this.categoryService.getCategories({}, this.translate.getDefaultLang()).subscribe(categories => {
      
      this.allCategories = categories['hydra:member'];

        categories['hydra:member'].forEach((category) => {
          if (category.parents.length == 0) {
            this.categories.push(category);
          }
        });
      
      //this.categories = categories['hydra:member'];
    });
    this.tagService.getTags({}, this.translate.getDefaultLang()).subscribe(tags => {
      this.tags = tags['hydra:member'];
    });
    this.languageService.getLanguages({}, this.translate.getDefaultLang()).subscribe(languages => {
      this.languages = languages['hydra:member'];
    });
    this.countryService.getCountries().subscribe(countries => {
      this.countries = countries;
    });
    this.makerService.getMakers().subscribe(makers => {
      this.makers = makers['hydra:member'];
    });
  }

  deleteExperience(experienceId) {
    const id = experienceId.replace('/experiences/', '');
    this.experienceService.deleteExperience(id).subscribe(() => {
      },
      error => {
      });
  }

  editExperience(experienceId) {
    this.experienceService.getExperience(experienceId, this.translate.getDefaultLang()).subscribe((experience: Experience) => {
      this.experience = experience;
      this.experienceForm.get('name').setValue(experience.name);
      this.experienceForm.get('url').setValue(experience.url);
      this.experienceForm.get('urlButtonText').setValue(experience.urlButtonText);
      this.experienceForm.get('description').setValue(experience.description);
      this.experienceForm.get('duration').setValue(experience.duration);
      this.experienceForm.get('promotionType').setValue(experience.promotionType);
      this.experienceForm.get('promotion').setValue(experience.promotion);
      this.experienceForm.get('sustainableSeal').setValue(experience.sustainableSeal);
      this.experienceForm.get('reviewTripadvisor').setValue(experience.reviewTripadvisor);
      this.experienceForm.get('seasonStartMonth').setValue(experience.seasonStartMonth);
      this.experienceForm.get('seasonEndMonth').setValue(experience.seasonEndMonth);
      this.experienceForm.get('isEnglishChecked').setValue(experience['isEnglishChecked']);
      const experiences = [];
      experience.languages.forEach(language => {
        experiences.push(language['@id']);
      });
      this.experienceForm.get('languages').setValue(experiences);




        const categories = [];
        const categoriesObject = [];
        const subcategories = [];



        experience.categories.forEach((category) => {

          if (category.parents.length == 0) {
            categories.push(category['@id']);
            categoriesObject.push(category);
          } else {

            subcategories.push(category['@id']);
            //this.subcategories.push(category);
          }

        });

        console.log("Categorie LOADED");
        console.log(categories);

        console.log("SUBCategorie LOADED");
        console.log(subcategories);

        
        
        
        this.experienceForm.get('categories').setValue(categories);

        this.experienceForm.get('subcategories').setValue(subcategories);
       
        
        setTimeout(() => {
          if(categories.length > 0){
            this.loadSubcategories(categories);
          }
        }, 1000);

     /*  const categories = [];
      experience.categories.forEach(category => {
        categories.push(category['@id']);
      });
      this.experienceForm.get('categories').setValue(categories); */





      const tags = [];
      if (experience.tags) {
        experience.tags.forEach(tag => {
          tags.push(tag['@id']);
        });
        this.experienceForm.get('tags').setValue(tags);
      }
      this.experienceForm.get('country').setValue(experience.country);
      this.loadLocations(experience.country);
      this.experienceForm.get('location').setValue(experience.location['@id']);
      this.experienceForm.get('maker').setValue(experience.maker['@id']);
      experience.mediaExperiences.forEach(mediaExperience => {
        this.mediaExperiences.push({
          url: 'https://venntur.com/assets/experience/files/' + mediaExperience['contentUrl'],
          id: mediaExperience['@id']
        });
        this.uploadedFiles.push(mediaExperience['@id']);
      });
      this.experienceForm.get('mediaExperiences').setValue(this.uploadedFiles);
      this.setRelations();
    });
  }

  updateExperience() {
    if (this.experienceForm.invalid) {
      this.showErrors(this.experienceForm);
      return;
    }

    const categories = [];
    const subcategories = [];

    this.experienceForm.get('categories').value.forEach((category) => {
      categories.push(category);
    });

    this.experienceForm.get('subcategories').value.forEach((subcategory) => {
      categories.push(subcategory);
    });

    console.log("Categories TO PUSH!!!!!!!!!");
    console.log(categories);

    

    const experience = {
      name: this.experienceForm.get('name').value,
      url: this.experienceForm.get('url').value,
      urlButtonText: this.experienceForm.get('urlButtonText').value,
      description: this.experienceForm.get('description').value,
      duration: this.experienceForm.get('duration').value,
      promotionType: this.experienceForm.get('promotionType').value,
      promotion: this.experienceForm.get('promotion').value,
      sustainableSeal: this.experienceForm.get('sustainableSeal').value,
      reviewTripadvisor: this.experienceForm.get('reviewTripadvisor').value,
      seasonStartMonth: this.experienceForm.get('seasonStartMonth').value,
      seasonEndMonth: this.experienceForm.get('seasonEndMonth').value,
      mediaExperiences: this.experienceForm.get('mediaExperiences').value,
      maker: this.experienceForm.get('maker').value,
      languages: this.experienceForm.get('languages').value,
      categories: categories,
      tags: this.experienceForm.get('tags').value,
      country: this.experienceForm.get('country').value,
      location: this.experienceForm.get('location').value,
      isEnglishChecked: this.translate.getDefaultLang() === 'en'
    };
    const editedExperience = new Experience(experience);
    this.experienceService.putExperience(this.experience.id, editedExperience, this.translate.getDefaultLang())
      .subscribe(
        (result) => {
          this.alert.type = 1;
          this.alert.message = 'Experiencia guardada correctamente';
          setTimeout(() => {
            this.alert = {message: null, type: null};
          }, 5000);
          this.experience = null;
          this.createForm();
          location.replace('/experiencias');
        },
        error => {
          this.alert.message = error.message;
          this.alert.type = 2;
          setTimeout(() => {this.alert = {message: null, type: null};
          }, 5000);
        },
        () => console.log('complete'));
  }

  createExperience() {
    if (this.experienceForm.invalid) {
      this.showErrors(this.experienceForm);
      return;
    }
    const experience = {
      name: this.experienceForm.get('name').value,
      url: this.experienceForm.get('url').value,
      urlButtonText: this.experienceForm.get('urlButtonText').value,
      description: this.experienceForm.get('description').value,
      duration: this.experienceForm.get('duration').value,
      promotionType: this.experienceForm.get('promotionType').value,
      promotion: this.experienceForm.get('promotion').value,
      sustainableSeal: this.experienceForm.get('sustainableSeal').value,
      reviewTripadvisor: this.experienceForm.get('reviewTripadvisor').value,
      seasonStartMonth: this.experienceForm.get('seasonStartMonth').value,
      seasonEndMonth: this.experienceForm.get('seasonEndMonth').value,
      mediaExperiences: this.experienceForm.get('mediaExperiences').value,
      maker: this.experienceForm.get('maker').value,
      languages: this.experienceForm.get('languages').value,
      categories: this.experienceForm.get('categories').value,
      tags: this.experienceForm.get('tags').value,
      country: this.experienceForm.get('country').value,
      location: this.experienceForm.get('location').value
    };
    this.experienceService.postExperience(experience).subscribe((result) => {
      this.alert.type = 1;
      this.alert.message = 'Experiencia creada correctamente';
      setTimeout(() => {
        this.alert = {message: null, type: null};
        location.replace('/experiencias');
      }, 3000);
      this.createForm();
    }, (error) => {
      console.log(error);
      this.alert.message = error.message;
      this.alert.type = 2;
      setTimeout(() => {this.alert = {message: null, type: null};
      }, 5000);
    });
  }

  onFileChanged(event) {
    if (event.target.files[ 0 ].type === 'image/jpeg' || event.target.files[ 0 ].type === 'image/png') {
      this.selectedFile = event.target.files[ 0 ];
    }
  }

  onFileChange(eventFiles) {
    if (eventFiles.target.files && eventFiles.target.files[0]) {
      const filesAmount = eventFiles.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();
        this.selectedFiles.push(eventFiles.target.files[i]);

        reader.onload = (event:any) => {
          this.images.push({url: event.target.result, name: eventFiles.target.files[i].name});
        };

        reader.readAsDataURL(eventFiles.target.files[i]);
      }
    }
  }

  onUpload() {
    const index = 0;
    this.selectedFiles.forEach(file => {
      this.mediaExperienceService.mediaExperiencesUpload(
        file).subscribe(result => {
        this.handleProgress(result, file.name, index);
      }, error => {
        this.alert.message = error.error.code + '- ' + error.error.message;
        this.alert.type = 2;
        setTimeout(() => {this.alert = {message: null, type: null};
        }, 5000);
      });
    });
  }

  handleProgress(event, fileName, index) {
    if (event.type === HttpEventType.DownloadProgress) {
      this.uploadingProgressing = true;
      this.uploadProgress = Math.round(100 * event.loaded / event.total);
    }

    if (event.type === HttpEventType.UploadProgress) {
      this.uploadingProgressing = true;
      this.uploadProgress = Math.round(100 * event.loaded / event.total);
    }

    if (event.type === HttpEventType.Response) {
      this.uploadComplete = true;
      this.uploadingProgressing = false;
      this.serverResponse = event.body;

      this.uploadedFiles.push(event.body['@id']);

      this.mediaExperiences.push({url: 'https://venntur.com/' + event.body['contentUrl'], id: event.body['@id']});

      this.selectedFiles.splice(index, 1);
      this.images = this.images.filter((el) => el.name !== fileName);

      this.alert.type = 1;
      this.alert.message = 'Imagen guardada correctamente';
      setTimeout(() => {
        this.alert = {message: null, type: null};
      }, 5000);

      this.experienceForm.get('mediaExperiences').setValue(this.uploadedFiles);
    }
  }

  loadLocations(countryCode: string) {
    this.locationService.getLocationsFiltered({'country': countryCode}, this.translate.getDefaultLang()).subscribe(
      (locations) => {
        this.locations = locations;
        this.filteredLocations.next(this.locations.slice());
      }
    );
  }

  deleteMediaExperience(image, uploaded) {
    if (uploaded) {
      this.mediaExperienceService.deleteMediaExperiences(image).subscribe(() => {
          const index = this.uploadedFiles.indexOf(image);
          this.uploadedFiles.splice(index, 1);
          this.mediaExperiences = this.mediaExperiences.filter((el) => el.id !== image);
          this.experienceForm.get('mediaExperiences').setValue(this.uploadedFiles);
        }
      );
    } else {
      this.selectedFiles = this.selectedFiles.filter((el) => el.name !== image);
      this.images = this.images.filter((el) => el.name !== image);
    }
  }

  onUploadedImage(uploadedImage: object, formControlName: string) {
    this.mediaExperiences.push({
      url: 'https://venntur.com/' + uploadedImage['url'],
      id: uploadedImage['id']
    });
    this.uploadedFiles.push(uploadedImage['id']);
    this.experienceForm.get(formControlName).patchValue(this.uploadedFiles);
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector( 'form .ng-invalid' );
    firstInvalidControl.focus();
    return;
  }

  showError(formValue) {
    return this.experienceForm.get(formValue).invalid && this.experienceForm.get(formValue).touched;
  }

  loadSubcategories(categoriesId) {



    console.log("CategoriesId");
    console.log(categoriesId);
    console.log(categoriesId.length);

    //avoid the variable categoriesSelected to be greater than 2 using categoriesId to find the new categories
    if (categoriesId.length > 2) {
      console.log("CategoriesId length 3");
      categoriesId.splice(2, 1);
      this.categoriesSelected = categoriesId;
      return;
    }

    console.log(categoriesId);

    var sub = [];
    this.subcategories = [];

    // get the this.categories from the array categoriesId
    this.allCategories.forEach((category) => {

      console.log("Category");
      console.log(category);

      if (category.parents != null) {
        category.parents.forEach((parent) => {
          if (categoriesId.includes(parent['@id'])) {
            this.subcategories.push(category);
          }
        });
      }



      /*  if (categoriesId.includes(category['@id'])) {
 
         console.log("Category");
         console.log(category);
 
         if(category.parents != null){
 
           console.log("Category parents");
           console.log(category.parents);
           this.subcategories.push(category);
 
 
         }
 
       } */

      //avoid this.subcategories from having duplicates
      this.subcategories = this.subcategories.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t['@id'] === thing['@id']
        ))
      );


    });



  }

  validateTags(tags) {

    if (tags.length > 2) {
      console.log("CategoriesId length 3");
      tags.splice(2, 1);
      this.tagsSelected = tags;
      return;
    }

  }

  changeLanguage(language) {
    this.translate.setDefaultLang(language);
    this.mediaExperiences = [];
    setTimeout(() => {
      this.editExperience(this.activatedRoute.snapshot.params.id);
    }, 1000);
  }
}
