import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'admin';

  showSidebar: boolean = true;

  constructor(private router: Router, private location: Location) {
    router.events.subscribe(val => {
      this.showSidebar = location.path() !== '/login';
    });
    // router.events.forEach((event) => {
    //   if (event instanceof NavigationStart) {
    //     if ((event['url'] == '/login') ) {
    //       this.showSidebar = false;
    //     } else {
    //       this.showSidebar = true;
    //     }
    //   }
    // });
  }

  ngOnInit() {
    // Scroll to top after route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
  }
}
