import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { UsersService } from './users.service';
import { User } from './user.model';
import {errorMessages, regExps} from '../../shared/custom-validators';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  userForm: UntypedFormGroup;
  user: User;
  title = 'Nuevo usuario';
  alert = {message: null, type: null};
  errors = errorMessages;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private userService: UsersService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.id) {
      this.title = 'Editar usuario';
      this.editUser(this.activatedRoute.snapshot.params.id);
    }
  }

  createForm() {
    this.userForm = this.formBuilder.group({
      email: [ '', [
        Validators.required,
        Validators.email
      ]],
      password: [ '', [
        Validators.required,
        Validators.pattern(regExps.password)
        ]
      ]
    });
  }

  deleteUser(userId) {
    const id = userId.replace('/users/', '');
    this.userService.deleteUser(id).subscribe(() => {
      },
      error => {
      });
  }

  editUser(userId) {
    this.userService.getUser(userId).subscribe((user: User) => {
      this.user = user;
      this.userForm.get('email').setValue(user.email);
      this.userForm.get('password').setValue(user.password);
    });
  }

  updateUser() {
    if (this.userForm.invalid) {
      this.showErrors(this.userForm);
      return;
    }
    const user = {
      email: this.userForm.get('email').value,
      password: this.userForm.get('password').value
    };
    const editedUser = new User(user);
    this.userService.putUser(this.user.id, editedUser)
      .subscribe(
        (result) => {
          this.user = null;
          this.createForm();
          this.alert.type = 1;
          this.alert.message = 'Usuario guardado correctamente';
          location.replace('/usuarios');
          setTimeout(() => {
            this.alert = {message: null, type: null};
          }, 5000);
        },
        error => {
          this.alert.message = error.error.code + '- ' + error.error.message;
          this.alert.type = 2;
          setTimeout(() => {this.alert = {message: null, type: null};
          }, 5000);
        },
        () => console.log('complete'));
  }

  createUser() {
    if (this.userForm.invalid) {
      this.showErrors(this.userForm);
      return;
    }
    const user = {
      email: this.userForm.get('email').value,
      password: this.userForm.get('password').value
    };
    this.userService.postUser(user).subscribe((result) => {
      this.alert.type = 1;
      this.alert.message = 'Usuario creado correctamente';
      setTimeout(() => {
        this.alert = {message: null, type: null};
      }, 5000);
      this.createForm();
      location.replace('/usuarios');
    }, (error) => {
      this.alert.message = error.error.code + '- ' + error.error.message;
      this.alert.type = 2;
      setTimeout(() => {this.alert = {message: null, type: null};
      }, 5000);
    });
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    return;
  }

  showError(formValue) {
    return this.userForm.get(formValue).invalid && this.userForm.get(formValue).touched;
  }
}
