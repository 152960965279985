export class MakerCollection {
  total: number;
  makers: Maker[];
}

export class Maker {
  id: string;
  name: string;
  documentType: string;
  document: string;
  mediaMakers: any[];
  mediaMakersHeader: any[];
  mediaMakersLogo: any[];
  mediaMakersStaff: any[];
  phrase: string;
  phone: string;
  whatsapp: string;
  email: string;
  web: string;
  sustainableSeal: boolean;
  user: string;
  tripadvisor: string;
  description: string;
  founders: string;
  director: string;
  operations: string;
  technology: string;
  customerId: string;
  instagram: string;
  twitter: string;
  linkedIn: string;
  facebook: string;
  tags: any[];
  destinations: any[];
  slug: string;
  constructor(values: any = {}) {
    Object.assign(this, values);
  }
}
