export class PlanCollection {
  total: number;
  languages: Plan[];
}

export class Plan {
  id: string;
  name: string;
  price: string;
  isActive: boolean;
  shortName: string;
  maxProducts: number;
  sku: string;
  paymentType: string;
  stripeId: string;
  description: string;
  level: number;
  
  constructor(values: any = {}) {
    Object.assign(this, values);
  }
}
