import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { errorMessages } from '../shared/custom-validators';
import {ExperiencesService} from '../experiences/experiences/experiences.service';

@Component({
  selector: 'app-imports',
  templateUrl: './imports.component.html',
  styleUrls: ['./imports.component.css']
})
export class ImportsComponent implements OnInit {
  importForm: UntypedFormGroup;
  imports = ['Civitatis'];
  importsSelected = 'Civitatis';
  title = 'Importaciones';
  isLoading = true;
  quantityImports = 'Buscando experiencias para importar.....';
  alert = {message: null, type: null};
  errors = errorMessages;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private experiencesService: ExperiencesService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.experiencesService.getQuantityImports(localStorage.getItem('civitatis_token')).subscribe( result => {
        this.quantityImports = result['quantity'];
        this.importForm.get('experienceImports').setValue(result['experienceImports']);
        this.isLoading = false;
      }
    );
  }

  createForm() {
    this.importForm = this.formBuilder.group({
      imports: [ 'Civitatis',
        Validators.required
      ],
      experienceImports: ['',
        Validators.required
      ]
    });
  }

  makeImport() {
    this.isLoading = true;
    this.experiencesService.postImports({imports: this.importForm.get('imports').value,
      experienceImports: this.importForm.get('experienceImports').value,
      token: localStorage.getItem('civitatis_token')}).subscribe(result => {
        this.alert.message = result['result'];
        this.alert.type = 1;
        this.isLoading = false;
    });
  }
}
