import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Language, LanguageCollection } from './language.model';
import { map } from 'rxjs/operators';


const API_URL = environment.apiUrl;

@Injectable()
export class LanguagesService {
  constructor(
    private http: HttpClient
  ) { }

  public postLanguage(language): Observable<Language> {
    return this.http
      .post<Language>(API_URL + '/languages', language);
  }

  public getLanguage(id: string, language): Observable<Language> {
    return this.http
      .get<Language>(API_URL + '/languages/' + id, { headers: {
          'Accept-Language': language
      }});
  }

  public getLanguages(filters?: any, language = 'es'): Observable<LanguageCollection> {
    if (filters === undefined) { filters = {}; }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('name', String(filters.name));
    }
    return this.http
      .get<LanguageCollection>(API_URL + '/languages', { params: httpParams , headers: {
          'Accept-Language': language
      }});
  }

  public deleteLanguage(languageId: string): any {
    return this.http.delete<Language>(API_URL + languageId);
  }

  public putLanguage(languageId: string, language: Language, locale = 'es'): Observable<Language> {
    return this.http
      .put<Language>(API_URL + languageId, language, { headers: {
          'Accept-Language': locale
      }});
  }
}
