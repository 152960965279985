import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './core/navbar/navbar.component';
import { SidebarComponent } from './core/sidebar/sidebar.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ExperiencesListComponent } from './pages/experiences/experiences-list/experiences-list.component';
import { ExperiencesComponent } from './pages/experiences/experiences/experiences.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ExperiencesService } from './pages/experiences/experiences/experiences.service';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { CategoriesComponent } from './pages/categories/categories/categories.component';
import { CategoriesService } from './pages/categories/categories/categories.service';
import { LanguagesComponent } from './pages/languages/languages/languages.component';
import { LanguagesService } from './pages/languages/languages/languages.service';
import { LanguagesListComponent } from './pages/languages/languages-list/languages-list.component';
import { CategoriesListComponent } from './pages/categories/categories-list/categories-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MediaExperiencesService } from './pages/experiences/experiences/media-experiences.service';
import { LocationsComponent } from './pages/locations/locations/locations.component';
import { LocationsListComponent } from './pages/locations/locations-list/locations-list.component';
import { CountryService } from './pages/locations/locations/country.service';
import { LocationService } from './pages/locations/locations/location.service';
import { UsersComponent } from './pages/users/users/users.component';
import { UsersListComponent } from './pages/users/users-list/users-list.component';
import { UsersService } from './pages/users/users/users.service';
import { MakersComponent } from './pages/makers/makers/makers.component';
import { MakersListComponent } from './pages/makers/makers-list/makers-list.component';
import { MakersService } from './pages/makers/makers/makers.service';
import { MediaMakersService } from './pages/makers/makers/media-makers';
import { LoginComponent } from './pages/login/login.component';
import { AuthService } from './pages/shared/auth.service';
import { AuthGuard } from './pages/shared/auth-guard.service';
import { LoggedInGuard } from './pages/shared/logged-in-guard.service';
import { ApiAuthInterceptor } from './app.api-auth-interceptor';
import { CropperComponent } from './pages/shared/cropper/cropper.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { TagsService } from './pages/tags/tags/tags.service';
import { TagsComponent } from './pages/tags/tags/tags.component';
import { TagsListComponent } from './pages/tags/tags-list/tags-list.component';
import { TakersComponent } from './pages/takers/takers/takers.component';
import { TakersListComponent } from './pages/takers/takers-list/takers-list.component';
import { TakersService } from './pages/takers/takers/takers.service';
import { ConfirmationDialogComponent } from './pages/shared/confirmation-dialog/confirmation-dialog.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AppPasswordDirective } from './app-password.directive';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PlansComponent } from './pages/plans/plans/plans.component';
import { PlansListComponent } from './pages/plans/plans-list/plans-list.component';
import { PlansService } from './pages/plans/plans/plans.service';
import { Cropper2Component } from './pages/shared/cropper/cropper2.component';
import { Cropper3Component } from './pages/shared/cropper/cropper3.component';
import { Cropper4Component } from './pages/shared/cropper/cropper4.component';
import { ResellerComponent } from './pages/resellers/reseller/reseller.component';
import { ResellersListComponent } from './pages/resellers/resellers-list/resellers-list.component';
import { ResellerService } from './pages/resellers/reseller/reseller.service';
import { MediaResellerService } from './pages/resellers/reseller/media-reseller';
import { ImportsComponent } from './pages/imports/imports.component';
import { ZonesComponent } from './pages/locations/zones/zones.component';
import { ZonesListComponent } from './pages/locations/zones-list/zones-list.component';
import { ZoneService } from './pages/locations/zones/zone.service';
import { PositioningComponent } from './pages/positioning/positioning.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    DashboardComponent,
    NavbarComponent,
    SidebarComponent,
    ExperiencesListComponent,
    ExperiencesComponent,
    CategoriesComponent,
    LanguagesComponent,
    LanguagesListComponent,
    CategoriesListComponent,
    LocationsComponent,
    LocationsListComponent,
    UsersComponent,
    UsersListComponent,
    MakersComponent,
    MakersListComponent,
    TakersComponent,
    TakersListComponent,
    ResellerComponent,
    ResellersListComponent,
    PlansComponent,
    PlansListComponent,
    LoginComponent,
    CropperComponent,
    Cropper2Component,
    Cropper3Component,
    Cropper4Component,
    TagsComponent,
    TagsListComponent,
    ConfirmationDialogComponent,
    NotFoundComponent,
    AppPasswordDirective,
    ImportsComponent,
    ZonesComponent,
    ZonesListComponent,
    PositioningComponent
  ],
  exports: [CommonModule],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    NgxDatatableModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    AngularCropperjsModule,
    DragDropModule,
    FormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [HttpClient]
      }
    }),

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiAuthInterceptor,
      multi: true,
    },
    ExperiencesService,
    CategoriesService,
    LanguagesService,
    MediaExperiencesService,
    CountryService,
    LocationService,
    ZoneService,
    UsersService,
    MakersService,
    TakersService,
    ResellerService,
    TagsService,
    MediaMakersService,
    MediaResellerService,
    PlansService,
    AuthService,
    AuthGuard,
    LoggedInGuard,
    TranslateService
  ],
})

export class AppModule { }
