export class LanguageCollection {
  total: number;
  languages: Language[];
}

export class Language {
  id: string;
  name: string;
  constructor(values: any = {}) {
    Object.assign(this, values);
  }
}
