import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appPassToggle]'
})
export class AppPasswordDirective {
  visible: boolean = false;

  constructor(
    private elem: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    const inputElem = this.elem.nativeElement;

    // create wrapper around input .input-group
    const wrapper = document.createElement('div');
    wrapper.classList.add('input-group');
    inputElem.parentNode.insertBefore(wrapper, inputElem);
    wrapper.appendChild(inputElem);

    // create toggle element
    const toggleElem = this.renderer.createElement('div');
    toggleElem.classList.add('input-group-append');
    toggleElem.innerHTML = `<span class="input-group-text"><i class="fa fa-eye-slash" id="toggle-icon"></i></span>`;

    // append toggle element to input wrapper
    this.renderer.appendChild(wrapper, toggleElem);

    // listen to click event on toggle button
    this.renderer.listen(toggleElem, 'click', (event) => {
      const toggleIcon = document.getElementById('toggle-icon');
      this.visible = !this.visible;

      if (this.visible) {
        this.renderer.addClass(toggleIcon, 'fa-eye');
        this.renderer.removeClass(toggleIcon, 'fa-eye-slash');
      } else {
        this.renderer.addClass(toggleIcon, 'fa-eye-slash');
        this.renderer.removeClass(toggleIcon, 'fa-eye');
      }

      this.renderer.setAttribute(inputElem, 'type', this.visible ? 'text' : 'password');
    });
  }

}
