<h1 class="page-title"><i class="fa fa-map-pin small"></i> {{title}}</h1>
<div class="row justify-content-center">
  <button (click)="changeLanguage('es')" class="btn btn-primary mr-2 mb-2">ES</button>
  <button (click)="changeLanguage('en')" class="btn btn-primary mr-2 mb-2">EN</button>
</div>
<div class="card mt-4">
  <div class="card-body p-4">
    <form [formGroup]="zoneForm">
      <div class="row">
        <div class="col-12 form-group">
          <label>Nombre <span class="form-required text-danger">*</span></label>
          <input placeholder="Nombre" type="text" class="form-control" formControlName="name">
          <small class="text-danger" *ngIf="showError('name')">
            {{errors['zoneName']}}
          </small>
        </div>
        <div class="col-12 form-group">
          <label>Ubicación <span class="form-required text-danger">*</span></label>
          <ng-select placeholder="Seleccionar la ubicación" aria-label="Location" formControlName="location" [(ngModel)]="locationSelected" required>
            <ng-option *ngFor="let location of locations" [value]="location['@id']">{{ location.name }}</ng-option>
          </ng-select>
          <small class="text-danger" *ngIf="showError('location')">
            {{errors['locationName']}}
          </small>
        </div>
        <div class="col-12 form-group">
          <label>Civitatis Id <span class="form-required text-danger"></span></label>
          <ng-select bindLabel="Civitatis Id"
                     placeholder="Seleccionar ubicación de civitatis"
                     appendTo="body"
                     formControlName="civitatisId"
                     [(ngModel)]="civitatisLocationSelected">
            <ng-option [value]="civitatisZone['id']" *ngFor="let civitatisZone of civitatisZones">{{civitatisZone.name}}</ng-option>
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button type="button" class="btn btn-primary mr-2" *ngIf="zone"
                  (click)="updateZone()" >
            Guardar Localidad
          </button>
          <button type="button" class="btn btn-primary mr-2" *ngIf="!zone"
                  (click)="createZone()" >
            Crear nueva Localidad
          </button>
          <a href="/zonas" class="btn btn-light">Cancelar</a>
        </div>
      </div>
    </form>
    <div class="mt-4" *ngIf="alert.message">
      <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
    </div>
  </div>
</div>
