<div>
    <div>
        <h1 class="page-title m-0"><i class="fa fa-line-chart"></i>Posicionamiento</h1>
    </div>
    <div class="d-flex justify-content-center" *ngIf="experiences.length == 0">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div cdkDropList class="card example-list p-3 mt-4" (cdkDropListDropped)="drop($event)"
        *ngIf="experiences.length != 0">
        <div class="example-box card" *ngFor="let experience of experiences.slice(0,maxExperiences);let i = index" cdkDrag
            [cdkDragDisabled]="experience.order == null || undefined">
            <div class="d-flex justify-content-center align-items-center">
                <div class="px-3 py-4 border-right position">
                    {{i+1}}
                </div>
                <ng-container *ngIf="experience.order == null || undefined">
                    <div class="p-3 w-100">
                        <ng-select class="w-100" placeholder="Buscar experiencia..." (change)="loadExperiences($event)">
                            <ng-option [value]="experience['@id']" *ngFor="let experience of experiences">{{
                                experience.name
                                }}</ng-option>
                        </ng-select>
                    </div>
                    <div class="p-3 border-left ml-auto actions"
                        [ngClass]="i != experiences.length-1 ? 'justify-content-between' : ''">
                        <div *ngIf="i != experiences.length-1">
                            <i class="fa fa-arrow-down" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="i != 0">
                            <i class="fa fa-arrow-up" aria-hidden="true"></i>
                        </div>
                        <div>
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="experience.order == i+1">
                    <div class="p-3 w-100">
                        <ng-select class="w-100" [placeholder]="experience.name" (change)="loadExperiences($event)"
                            [(ngModel)]="selectedValues[i]">
                            <ng-option [value]="experience['@id']" *ngFor="let experience of experiences">{{
                                experience.name
                                }}</ng-option>
                        </ng-select>
                    </div>
                    <div class="p-3 border-left ml-auto actions"
                        [ngClass]="i != experiences.length-1 ? 'justify-content-between' : ''">
                        <div *ngIf="i != experiences.length">
                            <i class="fa fa-arrow-down" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="i != 0">
                            <i class="fa fa-arrow-up" aria-hidden="true"></i>
                        </div>
                        <div>
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="add-page" (click)="maxExperiences = maxExperiences + 10">
            <i class="fa fa-plus" aria-hidden="true"></i>
        </div>
    </div>
</div>