<nav class="navbar navbar-expand-lg default-layout-navbar col-lg-12 col-12 fixed-top d-flex flex-row navbar-light bg-white">
  <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-between">
    <a class="navbar-brand brand-logo" routerLink="/"><img src="assets/venntur-logo-150.png" alt="Venntur" /></a>
    <a class="navbar-brand brand-logo-mini" routerLink="/"><img src="assets/venntur-isotype-38.png" alt="Venntur" /></a>
    <button class="btn btn-light" type="button" data-toggle="minimize" (click)="toggleSidebar()" *ngIf="authService.isAuthenticated() | async">
      <i class="fa fa-bars"></i>
    </button>
  </div>
  <div class="navbar-menu-wrapper d-flex align-items-stretch ml-auto">
    <ul class="navbar-nav navbar-nav-right" *ngIf="authService.isAuthenticated() | async">
      <li class="nav-item nav-profile">
        <span class="nav-link text-black-50">
          <i class="fa fa-user-circle text-success"></i> <span class="ml-1">Admin</span>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link text-black-50">|</span>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="logout()" *ngIf="authService.isAuthenticated() | async">
          <i class="fa fa-power-off text-primary"></i> Logout
        </a>
      </li>
    </ul>
  </div>
</nav>
