import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Experience, ExperienceCollection } from './experience.model';
import { map } from 'rxjs/operators';


const API_URL = environment.apiUrl;

@Injectable()
export class ExperiencesService {
  constructor(
    private http: HttpClient
  ) { }

  public postExperience(experience): Observable<Experience> {
    return this.http
      .post<Experience>(API_URL + '/experiences', experience);
  }

  public getExperience(id: string, language = 'es'): Observable<Experience> {
    return this.http
      .get<Experience>(API_URL + '/experiences/' + id, {
        headers: {
          'Accept-Language': language
        }
      });
  }

  public getExperiencesByMakerId(makerId: number): Observable<ExperienceCollection> {
    return this.http
      .get<ExperienceCollection>(API_URL + '/makers/' + makerId + '/experiences');
  }

  public getExperiences(filters?: any): Observable<ExperienceCollection> {
    if (filters === undefined) { filters = {}; }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('name', String(filters.name));
    }
    if (filters['location']) {
      httpParams = httpParams.set('location.name', String(filters.location));
    }
    if (filters['maker']) {
      httpParams = httpParams.set('maker.email', String(filters.maker));
    }
    if (filters['id']) {
      httpParams = httpParams.set('id', String(filters.id));
    }
    return this.http
      .get<ExperienceCollection>(API_URL + '/experiences?order[id]&itemsPerPage=600', { params: httpParams });
  }

  public deleteExperience(experienceId: string): any {
    return this.http.delete<Experience>(API_URL + experienceId);
  }

  public putExperience(experienceId: string, experience: Experience, language = 'es'): Observable<Experience> {
    return this.http
      .put<Experience>(API_URL + '/experiences/' + experienceId, experience, {
        headers: {
          'Accept-Language': language
        }
      });
  }

  public postImports(imports): Observable<any> {
    return this.http
      .post<any>(API_URL + '/imports/imports-request', imports);
  }

  public getQuantityImports(token): Observable<any> {
    return this.http
      .get<any>(API_URL + '/imports/quantity-imports?token=' + token);
  }

  public sendActiveNotification(data): Observable<any> {
    return this.http
      .post<any>(API_URL + '/send/is_active_notification', data);
  }
}
