import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Category } from './category.model';
import { CategoriesService } from './categories.service';
import { errorMessages } from '../../shared/custom-validators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  categoryForm: UntypedFormGroup;
  category: Category;
  title = 'Nueva categoría';
  alert = {message: null, type: null};
  errors = errorMessages;
  categories: any = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private categoryService: CategoriesService,
    private el: ElementRef,
    public translate: TranslateService
  ) {
    this.createForm();
  }

  ngOnInit(): void {

    this.categoryService.getCategories(this.translate.getDefaultLang()).subscribe((categories: any) => {
      this.categories = [];
      console.log(this.categories);

      categories['hydra:member'].forEach((category) => {
        if(category.parents.length == 0) {
          this.categories.push(category);
        } else{
          //console.log("Omitidos: ");
          //console.log(category);
        }
      });


    });


    if (this.activatedRoute.snapshot.params.id) {
      this.title = 'Editar categoría';
      this.editCategory(this.activatedRoute.snapshot.params.id);
    }
  }

  createForm() {
    this.categoryForm = this.formBuilder.group({
      name: [ '',
        Validators.required
      ],
      description: [ '',
      ],
      parents: ['']
    });
  }

  deleteCategory(categoryId) {
    const id = categoryId.replace('/categories/', '');
    this.categoryService.deleteCategory(id).subscribe(() => {
      },
      error => {
      });
  }

  editCategory(categoryId) {
    console.log(this.translate.getDefaultLang());
    this.categoryService.getCategory(categoryId, this.translate.getDefaultLang()).subscribe((category: Category) => {
      this.category = category;

      var parents = [];

      category.parents.forEach((parent) => {
        parents.push(parent['@id']);
      });

      this.categoryForm.get('name').setValue(category.name);
      this.categoryForm.get('description').setValue(category.description);
      this.categoryForm.get('parents').setValue(parents ? parents : '');
    });
  }

  updateCategory() {
    if (this.categoryForm.invalid) {
      this.showErrors(this.categoryForm);
      return;
    }
    const category = {
      name: this.categoryForm.get('name').value,
      description: this.categoryForm.get('description').value,
      parents: this.categoryForm.get('parents').value
    };
    const editedCategory = new Category(category);
    this.categoryService.putCategory(this.category['@id'], editedCategory, this.translate.getDefaultLang())
      .subscribe(
        (result) => {
          this.category = null;
          this.createForm();
          this.alert.type = 1;
          this.alert.message = 'Categoría guardada correctamente';
          setTimeout(() => {
            this.alert = {message: null, type: null};
          }, 5000);
          location.replace('/categorias');
        },
        error => {
          this.alert.message = error.error.code + '- ' + error.error.message;
          this.alert.type = 2;
          setTimeout(() => {this.alert = {message: null, type: null};
          }, 5000);
        },
        () => console.log('complete'));
  }

  createCategory() {
    if (this.categoryForm.invalid) {
      this.showErrors(this.categoryForm);
      return;
    }
    const category = {
      name: this.categoryForm.get('name').value,
      description: this.categoryForm.get('description').value,
      parents: this.categoryForm.get('parents').value
    };
    console.log(category);

    this.categoryService.postCategory(category).subscribe((result) => {
      this.createForm();
      this.alert.type = 1;
      this.alert.message = 'Categoría creada correctamente';
      setTimeout(() => {
        this.alert = {message: null, type: null};
      }, 5000);
      location.replace('/categorias');
    }, (error) => {
      this.alert.message = error.error.code + '- ' + error.error.message;
      this.alert.type = 2;
      setTimeout(() => {this.alert = {message: null, type: null};
      }, 5000);
    });
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector( 'form .ng-invalid' );
    firstInvalidControl.focus();
    return;
  }

  showError(formValue) {
    return this.categoryForm.get(formValue).invalid && this.categoryForm.get(formValue).touched;
  }

  changeLanguage(language) {
    this.translate.setDefaultLang(language);
    setTimeout(() => {
      this.editCategory(this.activatedRoute.snapshot.params.id);
    }, 1000);
  }
}
