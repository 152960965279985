import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Tag, TagCollection } from './tag.model';


const API_URL = environment.apiUrl;

@Injectable()
export class TagsService {
  constructor(
    private http: HttpClient
  ) { }

  public postTag(tag): Observable<Tag> {
    return this.http
      .post<Tag>(API_URL + '/tags', tag);
  }

  public getTag(id: string, language): Observable<Tag> {
    return this.http
      .get<Tag>(API_URL + '/tags/' + id, { headers: {
          'Accept-Language': language
      }});
  }

  public getTags(filters?: any, language = 'es'): Observable<TagCollection> {
    if (filters === undefined) { filters = {}; }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('name', String(filters.name));
    }
    return this.http
      .get<TagCollection>(API_URL + '/tags', { params: httpParams, headers: {
          'Accept-Language': language
      }});
  }

  public deleteTag(tagId: string): any {
    return this.http.delete<Tag>(API_URL + tagId);
  }

  public putTag(tagId: string, tag: Tag, language = 'es'): Observable<Tag> {
    return this.http
      .put<Tag>(API_URL + tagId, tag, { headers: {
          'Accept-Language': language
      }});
  }

  public getCivitatisTags(token): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/imports/civitatis-tags?token=' + token);
  }
}
