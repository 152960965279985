import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../pages/shared/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public iconOnlyToggled = false;

  constructor(
    public authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    this.iconOnlyToggled = !this.iconOnlyToggled;
    if(this.iconOnlyToggled) {
      body.classList.add('sidebar-icon-only');
    } else {
      body.classList.remove('sidebar-icon-only');
    }
  }

}
