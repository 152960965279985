<h1 class="page-title"><i class="fa fa-tags small"></i> {{title}}</h1>
<div class="row justify-content-center">
  <button (click)="changeLanguage('es')" class="btn btn-primary mr-2 mb-2">ES</button>
  <button (click)="changeLanguage('en')" class="btn btn-primary mr-2 mb-2">EN</button>
</div>
<div class="card mt-4">
  <div class="card-body p-4">
    <form [formGroup]="tagForm">
      <div class="row">
        <div class="col-12 form-group">
          <label>Nombre <span class="form-required text-danger">*</span></label>
          <input placeholder="Nombre" type="text" class="form-control" formControlName="name">
          <small class="text-danger" *ngIf="showError('name')">
            {{errors['tagName']}}
          </small>
        </div>
        <div class="col-12 form-group">
          <label>Descripción</label>
          <textarea placeholder="Descripción" class="form-control" formControlName="description"></textarea>
        </div>
      </div>
      <div class="col-12 form-group">
        <label>Civitatis Id <span class="form-required text-danger"></span></label>
        <ng-select bindLabel="Civitatis Id"
                   placeholder="Seleccionar etiqueta de civitatis"
                   appendTo="body"
                   formControlName="civitatisId"
                   [(ngModel)]="civitatisTagSelected">
          <ng-option [value]="civitatisTag['id']" *ngFor="let civitatisTag of civitatisTags">{{civitatisTag.description}}</ng-option>
        </ng-select>
      </div>
      <div class="row">
        <div class="col-12">
          <button type="button" class="btn btn-primary mr-2" *ngIf="tag"
                  (click)="updateTag()" >
            Guardar Etiqueta
          </button>
          <button type="button" class="btn btn-primary mr-2" *ngIf="!tag"
                  (click)="createTag()" >
            Crear nueva Etiqueta
          </button>
          <a href="/etiquetas" class="btn btn-light">Cancelar</a>
        </div>
      </div>
    </form>
    <div class="mt-4" *ngIf="alert.message">
      <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
    </div>
  </div>
</div>
