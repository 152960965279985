import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Tag } from './tag.model';
import { TagsService } from './tags.service';
import { errorMessages } from '../../shared/custom-validators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit {
  tagForm: UntypedFormGroup;
  tag: Tag;
  title = 'Nueva etiqueta';
  civitatisTags = null;
  civitatisTagSelected = null;
  alert = {message: null, type: null};
  errors = errorMessages;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private tagService: TagsService,
    private el: ElementRef,
    public translate: TranslateService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.id) {
      this.title = 'Editar etiquetas';
      this.editTag(this.activatedRoute.snapshot.params.id);
    }
    this.tagService.getCivitatisTags(localStorage.getItem('civitatis_token')).subscribe(result => {
      this.civitatisTags = result['civitatisTags'];
    });
  }

  createForm() {
    this.tagForm = this.formBuilder.group({
      name: [ '',
        Validators.required
      ],
      description: [ '',
      ],
      civitatisId: [ ,
      ]
    });
  }

  deleteTag(tagId) {
    const id = tagId.replace('/tags/', '');
    this.tagService.deleteTag(id).subscribe(() => {
      },
      error => {
      });
  }

  editTag(tagId) {
    this.tagService.getTag(tagId, this.translate.getDefaultLang()).subscribe((tag: Tag) => {
      this.tag = tag;
      this.tagForm.get('name').setValue(tag.name);
      this.tagForm.get('description').setValue(tag.description);
      this.tagForm.get('civitatisId').setValue(tag.civitatisId);
    });
  }

  updateTag() {
    if (this.tagForm.invalid) {
      this.showErrors(this.tagForm);
      return;
    }
    const tag = {
      name: this.tagForm.get('name').value,
      description: this.tagForm.get('description').value,
      civitatisId: this.tagForm.get('civitatisId').value
    };
    const editedTag = new Tag(tag);
    this.tagService.putTag(this.tag['@id'], editedTag, this.translate.getDefaultLang())
      .subscribe(
        (result) => {
          this.tag = null;
          this.createForm();
          this.alert.type = 1;
          this.alert.message = 'Etiqueta guardada correctamente';
          setTimeout(() => {
            this.alert = {message: null, type: null};
          }, 5000);
          location.replace('/etiquetas');
        },
        error => {
          this.alert.message = error.error.code + '- ' + error.error.message;
          this.alert.type = 2;
          setTimeout(() => {this.alert = {message: null, type: null};
          }, 5000);
        },
        () => console.log('complete'));
  }

  createTag() {
    if (this.tagForm.invalid) {
      this.showErrors(this.tagForm);
      return;
    }
    const tag = {
      name: this.tagForm.get('name').value,
      description: this.tagForm.get('description').value,
      civitatisId: this.tagForm.get('civitatisId').value
    };
    this.tagService.postTag(tag).subscribe((result) => {
      this.createForm();
      this.alert.type = 1;
      this.alert.message = 'Etiqueta creada correctamente';
      setTimeout(() => {
        this.alert = {message: null, type: null};
      }, 5000);
      location.replace('/etiquetas');
    }, (error) => {
      this.alert.message = error.error.code + '- ' + error.error.message;
      this.alert.type = 2;
      setTimeout(() => {this.alert = {message: null, type: null};
      }, 5000);
    });
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector( 'form .ng-invalid' );
    firstInvalidControl.focus();
    return;
  }

  showError(formValue) {
    return this.tagForm.get(formValue).invalid && this.tagForm.get(formValue).touched;
  }

  changeLanguage(language) {
    this.translate.setDefaultLang(language);
    setTimeout(() => {
      this.editTag(this.activatedRoute.snapshot.params.id);
    }, 1000);
  }
}
