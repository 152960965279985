import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Language } from './language.model';
import { LanguagesService } from './languages.service';
import { errorMessages } from '../../shared/custom-validators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.css']
})
export class LanguagesComponent implements OnInit {
  languageForm: UntypedFormGroup;
  language: Language;
  title = 'Nuevo idioma';
  alert = {message: null, type: null};
  errors = errorMessages;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private languageService: LanguagesService,
    private el: ElementRef,
    public translate: TranslateService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.id) {
      this.title = 'Editar idioma';
      this.editLanguage(this.activatedRoute.snapshot.params.id);
    }
  }

  createForm() {
    this.languageForm = this.formBuilder.group({
      name: [ '',
        Validators.required
      ]
    });
  }

  deleteLanguage(languageId) {
    const id = languageId.replace('/languages/', '');
    this.languageService.deleteLanguage(id).subscribe(() => {
      },
      error => {
      });
  }

  editLanguage(languageId) {
    this.languageService.getLanguage(languageId, this.translate.getDefaultLang()).subscribe((language: Language) => {
      this.language = language;
      this.languageForm.get('name').setValue(language.name);
    });
  }

  updateLanguage() {
    if (this.languageForm.invalid) {
      this.showErrors(this.languageForm);
      return;
    }
    const language = {
      name: this.languageForm.get('name').value
    };
    const editedLanguage = new Language(language);
    this.languageService.putLanguage(this.language['@id'], editedLanguage, this.translate.getDefaultLang())
      .subscribe(
        (result) => {
          this.language = null;
          this.createForm();
          this.alert.type = 1;
          this.alert.message = 'Idioma guardado correctamente';
          setTimeout(() => {
            this.alert = {message: null, type: null};
          }, 5000);
          location.replace('/idiomas');
        },
        error => {
          this.alert.message = error.error.code + '- ' + error.error.message;
          this.alert.type = 2;
          setTimeout(() => {this.alert = {message: null, type: null};
          }, 5000);
        },
        () => console.log('complete'));
  }

  createLanguage() {
    if (this.languageForm.invalid) {
      this.showErrors(this.languageForm);
      return;
    }
    const language = {
      name: this.languageForm.get('name').value
    };
    this.languageService.postLanguage(language).subscribe((result) => {
      this.createForm();
      this.alert.type = 1;
      this.alert.message = 'Idioma creado correctamente';
      setTimeout(() => {
        this.alert = {message: null, type: null};
      }, 5000);
      location.replace('/idiomas');
    }, (error) => {
      this.alert.message = error.error.code + '- ' + error.error.message;
      this.alert.type = 2;
      setTimeout(() => {this.alert = {message: null, type: null};
      }, 5000);
    });
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector( 'form .ng-invalid' );
    firstInvalidControl.focus();
    return;
  }

  showError(formValue) {
    return this.languageForm.get(formValue).invalid && this.languageForm.get(formValue).touched;
  }

  changeLanguage(language) {
    this.translate.setDefaultLang(language);
    setTimeout(() => {
      this.editLanguage(this.activatedRoute.snapshot.params.id);
    }, 1000);
  }
}
