import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { ResellerService } from '../reseller/reseller.service';

@Component({
  selector: 'app-resellers-list',
  templateUrl: './resellers-list.component.html',
  styleUrls: ['./resellers-list.component.css']
})
export class ResellersListComponent implements OnInit {
  columns = [
    { prop: 'name', name: 'Nombre' },
    { prop: 'fiscalName', name: 'Nombre fiscal' },
    { prop: 'cif', name: 'CIF' }];
  rows = [];
  searchForm: UntypedFormGroup;
  alert = {message: null, type: null};
  @ViewChild(DatatableComponent) table: DatatableComponent;
  dialogRef: BsModalRef;

  constructor(
    private resellerService: ResellerService,
    private searchFormBuilder: UntypedFormBuilder,
    public dialog: BsModalService,
  ) {
    this.resellerService.getResellers().subscribe((makers => {
      this.rows = makers[ 'hydra:member' ];
    }));
  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      name: [''],
      fiscalName: [''],
      cif: ['']
    });
  }

  applyFilter() {
    this.resellerService.getResellers(this.searchForm.value).subscribe(
      data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
      }
    );
  }

  // deleteReseller(resellerId) {
  //   this.dialogRef = this.dialog.show(ConfirmationDialogComponent, {
  //     initialState: {
  //       confirmTitle: '¿Quieres eliminar este reseller?',
  //       confirmMessage: 'No podrás volver a recuperarlo',
  //       callback: (confirm) => {
  //         if (confirm) {
  //           this.resellerService.deleteReseller(resellerId).subscribe(
  //             (result) => {
  //               this.alert.type = 1;
  //               this.alert.message = 'Reseller eliminado correctamente';
  //               setTimeout(() => {
  //                 this.alert = {message: null, type: null};
  //               }, 5000);
  //             },
  //             error => {
  //               this.alert.message = error.error.code + '- ' + error.error.message;
  //               this.alert.type = 2;
  //               setTimeout(() => {this.alert = {message: null, type: null};
  //               }, 5000);
  //             },
  //           );
  //           setTimeout(() => {
  //             this.resellerService.getResellers().subscribe((resellers => {
  //               this.rows = resellers[ 'hydra:member' ];
  //               this.table.offset = 0;
  //             }));
  //           }, 2000);
  //         }
  //         this.dialogRef = null;
  //       }
  //     }
  //   });
  // }
}
