<h1 class="mb-4">Bienvenido a Venntur</h1>
<div class="row mb-4">
  <div class="col-md-6 mb-4">
    <div class="card text-center">
      <div class="card-body">
        <i class="fa fa-binoculars"></i>
        <h4 class="card-title">Crear nueva experiencia</h4>
        <a href="/nueva-experiencia/" class="btn btn-primary">Nueva experiencia</a>
      </div>
    </div>
  </div>
  <div class="col-md-6 mb-4">
    <div class="card text-center">
      <div class="card-body">
        <i class="fa fa-binoculars"></i>
        <h4 class="card-title">Ver todas las experiencias</h4>
        <a href="/experiencias/" class="btn btn-primary">Ver experiencias</a>
      </div>
    </div>
  </div>
  <div class="col-md-6 mb-4">
    <div class="card text-center">
      <div class="card-body">
        <i class="fa fa-tags"></i>
        <h4 class="card-title">Crear nueva etiqueta</h4>
        <a href="/nueva-etiqueta/" class="btn btn-primary">Nueva categoría</a>
      </div>
    </div>
  </div>
  <div class="col-md-6 mb-4">
    <div class="card text-center">
      <div class="card-body">
        <i class="fa fa-tags"></i>
        <h4 class="card-title">Ver todas las etiqueta</h4>
        <a href="/etiquetas/" class="btn btn-primary">Ver categorías</a>
      </div>
    </div>
  </div>
</div>
