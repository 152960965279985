<h1 class="page-title"><i class="fa fa-users small"></i> {{title}}</h1>
<div class="card mt-4">
  <div class="card-body p-4">
    <h4 class="border-bottom pb-2 mb-3"><i class="fa fa-lock small mr-1"></i> Datos acceso</h4>
      <form [formGroup]="userForm">
        <div class="row">
          <div class="col-md-6 form-group">
            <label>Email<span class="form-required text-danger">*</span></label>
            <input placeholder="email" type="email" class="form-control" formControlName="email">
            <small class="text-danger" *ngIf="showError('email')">
              {{errors['email']}}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>Contraseña<span class="form-required text-danger">*</span></label>
            <input placeholder="contraseña" maxlength="15" type="password" class="form-control" formControlName="password">
            <small class="text-danger" *ngIf="showError('password')">
              {{errors['password']}}
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button type="button" class="btn btn-primary" *ngIf="user"
                    (click)="updateUser()" >
              Guardar Usuario
            </button>
            <button type="button" class="btn btn-primary" *ngIf="!user"
                    (click)="createUser()" >
              Nuevo Usuario
            </button>
          </div>
        </div>
      </form>
      <div class="mt-4" *ngIf="alert.message">
        <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
      </div>
  </div>
</div>
