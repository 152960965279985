<div class="d-flex align-items-center">
    <h1 class="page-title"><i class="fa fa-map-pin small"></i> Zonas</h1>
    <a href="/nueva-zona" class="btn btn-primary ml-3">Añadir nueva Zona</a>
  </div>
  <div *ngIf="alert.message">
    <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
  </div>
  <div class="card mt-4">
    <div class="card-body p-3">
      <h5>Buscar por: </h5>
      <form [formGroup]="searchForm" (submit)="applyFilter()">
        <div class="form-row flex-fill">
          <div class="col">
            <input placeholder="Nombre" type="text" formControlName="name" class="form-control form-control-sm">
          </div>
          <div class="col">
            <input placeholder="País" type="text" formControlName="country" class="form-control form-control-sm">
          </div>
          <div class="col">
            <input placeholder="Ubicación" type="text" formControlName="location" class="form-control form-control-sm">
          </div>
          <button type="submit" class="btn btn-success btn-sm col-md-2">Buscar</button>
        </div>
      </form>
    </div>
  </div>
  <div class="card mt-2">
    <div class="card-body p-4">
      <ngx-datatable
        #table
        class="bootstrap"
        [rowHeight]="'auto'"
        [columns]="columns"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [limit]="10"
        [rows]="rows">
        <ngx-datatable-column *ngFor="let column of columns; let i = index;" name="{{column.name}}" prop="{{column.prop}}">
        </ngx-datatable-column>
        <ngx-datatable-column name="Acciones" prop="actions">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <a class="btn btn-sm btn-success mr-1" [href]="'/editar-zona/'+row['@id'].replace('/zones/', '')" tooltip="Editar" placement="bottom">
              <i class="fa fa-pencil"></i>
            </a>
            <button class="btn btn-sm btn-outline-primary" (click)="deleteZone(row['@id'])"  tooltip="Eliminar" placement="bottom">
              <i class="fa fa-trash"></i>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
  