export class ExperienceCollection {
  total: number;
  experiences: Experience[];
}

export class Experience {
  id: string;
  name: string;
  url: string;
  urlButtonText: string;
  description: string;
  duration: string;
  seasonStartMonth: string;
  seasonEndMonth: string;
  promotionType: string;
  promotion: string;
  sustainableSeal: boolean;
  reviewTripadvisor: string;
  mediaExperiences: any[];
  maker: string;
  languages: any[];
  categories: any[];
  tags: any[];
  country: string;
  location: string;
  constructor(values: any = {}) {
    Object.assign(this, values);
  }
}
