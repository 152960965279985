import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LocationService } from '../locations/location.service';
import { ZoneService } from './zone.service';
import { Zone } from './zone.model';
import { errorMessages } from '../../shared/custom-validators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.scss']
})
export class ZonesComponent implements OnInit {

  zoneForm: UntypedFormGroup;
  zone: Zone;
  civitatisZones = null;
  locations: any = [];
  locationSelected = null;
  civitatisLocationSelected = null;
  title = 'Nueva zona';
  alert = { message: null, type: null };
  errors = errorMessages;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private locationService: LocationService,
    private zoneService: ZoneService,
    private el: ElementRef,
    public translate: TranslateService,
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.id) {
      this.title = 'Editar zona';
      this.editZone(this.activatedRoute.snapshot.params.id);
    }
    this.locationService.getLocations().subscribe(locations => {
      this.locations = locations['hydra:member'];
      console.log('ubicaciones: ' + locations);
      
    });
    // this.zoneService.getCivitatisZones(localStorage.getItem('civitatis_token')).subscribe(result => {
    //   this.civitatisZones = result['civitatisZones'];
    // });
  }

  createForm() {
    this.zoneForm = this.formBuilder.group({
      name: ['',
        Validators.required
      ],
      location: ['',
        Validators.required
      ],
      civitatisId: []
    });
  }

  deleteZone(zoneId) {
    const id = zoneId.replace('/zones/', '');
    this.zoneService.deleteZone(id).subscribe(() => {
    },
      error => {
      });
  }

  editZone(zoneId) {
    this.zoneService.getZone(zoneId, this.translate.getDefaultLang()).subscribe((zone: Zone) => {
      this.zone = zone;
      this.zoneForm.get('name').setValue(zone.name);
      this.zoneForm.get('location').setValue(zone.location);
      this.zoneForm.get('civitatisId').setValue(zone.civitatisId);
    });
  }

  updateZone() {
    if (this.zoneForm.invalid) {
      this.showErrors(this.zoneForm);
      return;
    }
    const zonePlace = {
      name: this.zoneForm.get('name').value,
      location: this.zoneForm.get('location').value,
      civitatisId: this.zoneForm.get('civitatisId').value
    };
    const editedZone  = new Zone(zonePlace);
    this.zoneService.putZone(this.zone['@id'], editedZone, this.translate.getDefaultLang())
      .subscribe(
        (result) => {
          this.zone = null;
          this.createForm();
          this.alert.type = 1;
          this.alert.message = 'Zona guardada correctamente';
          setTimeout(() => {
            this.alert = { message: null, type: null };
          }, 5000);
          location.replace('/zonas');
        },
        error => {
          this.alert.message = error.error.code + '- ' + error.error.message;
          this.alert.type = 2;
          setTimeout(() => {
            this.alert = { message: null, type: null };
          }, 5000);
        },
        () => console.log('complete'));
  }

  createZone() {
    if (this.zoneForm.invalid) {
      this.showErrors(this.zoneForm);
      return;
    }
    const zonePlace = {
      name: this.zoneForm.get('name').value,
      country: this.zoneForm.get('country').value,
      civitatisId: this.zoneForm.get('civitatisId').value
    };
    this.zoneService.postZone(zonePlace).subscribe((result) => {
      this.createForm();
      this.alert.type = 1;
      this.alert.message = 'Zona creada correctamente';
      setTimeout(() => {
        this.alert = { message: null, type: null };
      }, 5000);
      location.replace('/zonas');
    }, (error) => {
      this.alert.message = error.error.code + '- ' + error.error.message;
      this.alert.type = 2;
      setTimeout(() => {
        this.alert = { message: null, type: null };
      }, 5000);
    });
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector('form .ng-invalid');
    firstInvalidControl.focus();
    return;
  }

  showError(formValue) {
    return this.zoneForm.get(formValue).invalid && this.zoneForm.get(formValue).touched;
  }

  changeLanguage(language) {
    this.translate.setDefaultLang(language);
    setTimeout(() => {
      this.editZone(this.activatedRoute.snapshot.params.id);
    }, 1000);
  }

}
