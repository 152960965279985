export class CategoryCollection {
  total: number;
  categories: Category[];
}

export class Category {
  id: string;
  name: string;
  description: string;
  parents: any;
  childs: any;
  constructor(values: any = {}) {
    Object.assign(this, values);
  }
}
