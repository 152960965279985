import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { Location, LocationCollection } from './location.model';

const API_URL = environment.apiUrl;

@Injectable()
export class LocationService {

  constructor(
    private http: HttpClient
  ) { }

  public getLocationsFiltered(filters: any = {}, language = 'es'): Observable<Location[]> {
    let httpParams = new HttpParams();
    if ( filters['per_page']) {
      httpParams = httpParams.set('itemsPerPage', String(filters['per_page']));
    } else {
      httpParams = httpParams.set('itemsPerPage', '100');
    }

    if (filters['country']) {
      if (typeof filters['country'] === 'object') {
        httpParams = httpParams.set('country', String(filters['country']['code']));
      }
      if (typeof filters['country'] === 'string') {
        httpParams = httpParams.set('country', String(filters['country']));
      }
    } else {
      httpParams = httpParams.set('country', 'es');
    }

    return this.http
      .get(API_URL + '/locations', { params: httpParams , headers: {
          'Accept-Language': language
        }})
      .pipe(map( (res: Response) => {
        return res['hydra:member'].map(item => {
          return new Location(item);
        });
      }));
  }

  public getLocations(filters?: any): Observable<LocationCollection> {
    if (filters === undefined) { filters = {}; }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('name', String(filters.name));
    }
    return this.http
      .get<LocationCollection>(API_URL + '/locations', { params: httpParams });
  }

  // public getAllLocations(): Observable<Location> {
  //   return this.http.get<Location>(API_URL + '/locations');
  // }

  public getLocation(id: number, language): Observable<Location> {
    return this.http.get<Location>(API_URL + '/locations/' + id.toString(), { headers: {
        'Accept-Language': language
    }});
  }

  public postLocation(location): Observable<Location> {
    return this.http
      .post<Location>(API_URL + '/locations', location);
  }

  public deleteLocation(locationId: string): any {
    return this.http.delete<Location>(API_URL + locationId);
  }

  public putLocation(locationId: number, location: Location, language = 'es'): Observable<Location> {
    return this.http
      .put<Location>(API_URL + locationId, location, { headers: {
          'Accept-Language': language
      }});
  }

  public getCivitatisLocations(token): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/imports/civitatis-locations?token=' + token);
  }
}
