<div class="d-flex align-items-center">
  <h1 class="page-title"><i class="fa fa-users small"></i> Makers</h1>
  <!-- <a href="/nuevo-maker" class="btn btn-primary ml-3">Añadir nuevo Maker</a> -->
</div>
<div *ngIf="alert.message">
  <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
</div>
<div class="card mt-4">
  <div class="card-body p-3">
    <h5>Buscar por: </h5>
    <form [formGroup]="searchForm" (submit)="applyFilter()">
      <div class="form-row flex-fill">
        <div class="col">
          <input placeholder="Nombre" type="text" formControlName="name" class="form-control form-control-sm">
        </div>
        <div class="col">
          <input placeholder="Documento" type="text" formControlName="document" class="form-control form-control-sm">
        </div>
        <button type="submit" class="btn btn-success btn-sm col-md-2">Buscar</button>
      </div>
    </form>
  </div>
</div>
<div class="card mt-2">
  <div class="card-body p-4">
    <span class="checkboxContainer">Mostrar columnas:</span>
    <div class="d-flex mb-3 checkboxContainer">
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="Name">Nombre</small>
        <input [checked]="checkCheckbox('name')" (click)="enableColumns({ prop: 'name', name: 'Nombre', order: 1 })" type="checkbox" name="Name">
      </div>
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="Document">Documento</small>
        <input [checked]="checkCheckbox('document')" (click)="enableColumns({ prop: 'document', name: 'Documento', order: 2 })" type="checkbox" name="Document">
      </div>
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="Document Type">Tipo de Documento</small>
        <input [checked]="checkCheckbox('documentType')" (click)="enableColumns({ prop: 'documentType', name: 'Tipo de Documento', order:3 })" type="checkbox" name="Document Type">
      </div>
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="Register Date">Fecha de Alta</small>
        <input [checked]="checkCheckbox('registerDate')" (click)="enableColumns({ prop: 'registerDate', name: 'Fecha de Alta', order: 4 })" type="checkbox" name="Register Date">
      </div>
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="Booking Engine">Motor de Reservas</small>
        <input [checked]="checkCheckbox('bookingEngine')" (click)="enableColumns({ prop: 'bookingEngine', name: 'Motor de Reservas', order: 5 })" type="checkbox" name="Booking Engine">
      </div>
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="Current Plan">Plan Actual</small>
        <input [checked]="checkCheckbox('currentPlan')" (click)="enableColumns({ prop: 'currentPlan', name: 'Plan Actual', order: 6 })" type="checkbox" name="Current Plan">
      </div>
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="Last Login">Ultimo Acceso</small>
        <input [checked]="checkCheckbox('lastLogin')" (click)="enableColumns({ prop: 'lastLogin', name: 'Ultimo Acceso', order: 7 })" type="checkbox" name="Last Login">
      </div>
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="Month Logins">Nº Accesos ultimos 30 días</small>
        <input [checked]="checkCheckbox('monthLogins')" (click)="enableColumns({ prop: 'monthLogins', name: 'Nº Accesos ultimos 30 días', order: 8 })" type="checkbox" name="Month Logins">
      </div>
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="Active Products">Nº Productos Activos</small>
        <input [checked]="checkCheckbox('activeProducts')" (click)="enableColumns({ prop: 'activeProducts', name: 'Nº Productos Activos', order: 9 })" type="checkbox" name="Actice Products">
      </div>
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="Maker Pin">Maker Pin</small>
        <input [checked]="checkCheckbox('makerPinStatus')" (click)="enableColumns({ prop: 'makerPinStatus', name: 'Maker Pin', order: 10 })" type="checkbox" name="Maker Pin">
      </div>
      <div class="mr-3 d-flex align-items-center">
        <small class="mr-1" for="Plan">Plan</small>
        <input [checked]="checkCheckbox('makerPlan.plan.name')" (click)="enableColumns({ prop: 'makerPlan.plan.name', name: 'Plan', order: 11 })" type="checkbox" name="Plan">
      </div>
      <div class="d-flex align-items-center">
        <small class="mr-1" for="Maker dist">Maker distribuidor</small>
        <input [checked]="checkCheckbox('specialColumn')" (click)="enableColumns('checkbox')" type="checkbox" name="Maker dist">
      </div>

    </div>
    <ngx-datatable #table class="bootstrap" [rowHeight]="'auto'" [columns]="columns" [columnMode]="'force'"
      [headerHeight]="50" [footerHeight]="50" [limit]="10" [rows]="rows">
      <ngx-datatable-column *ngFor="let column of columns; let i = index;" name="{{column.name}}"
        prop="{{column.prop}}">
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="checkboxColumn == true" name="Maker distribuidor" prop="makerReseller">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <input type="checkbox" class="form-check-input" id="makerReseller"
            (click)="changeMakerReseller($event.target['checked'], row.user['@id'].replace('/users/', ''), row.user.roles)"
            [checked]="row.user ? row.user.isMakerReseller : false">
          <label class="form-check-label" for="makerReseller"></label>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Acciones" prop="actions">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <!-- <a class="btn btn-sm btn-success mr-1" [href]="'/editar-maker/'+row['@id'].replace('/makers/', '')" -->
          <a class="btn btn-sm btn-success mr-1" (click)="goMaker(row)" 
            tooltip="Editar" placement="bottom">
            <i class="fa fa-pencil"></i>
          </a>
          <button class="btn btn-sm btn-outline-primary" (click)="deleteMaker(row['@id'])" tooltip="Eliminar"
            placement="bottom">
            <i class="fa fa-trash"></i>
          </button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>