export class TakerCollection {
  total: number;
  takers: Taker[];
}

export class Taker {
  id: string;
  name: string;
  surname: string;
  user: string;
  constructor(values: any = {}) {
    Object.assign(this, values);
  }
}
