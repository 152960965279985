<h1 class="page-title"><i class="fa fa-users small"></i> Usuarios</h1>
<div *ngIf="alert.message">
  <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
</div>
<div class="card mt-4">
  <div class="card-body p-3">
    <h5>Buscar por: </h5>
    <form [formGroup]="searchForm" (submit)="applyFilter()">
      <div class="form-row flex-fill">
        <div class="col">
          <input placeholder="Email" type="text" formControlName="email" class="form-control form-control-sm">
        </div>
        <button type="submit" class="btn btn-success btn-sm col-md-2">Buscar</button>
      </div>
    </form>
  </div>
</div>
<div class="card mt-2">
  <div class="card-body p-4">
    <ngx-datatable
      #table
      class="bootstrap"
      [rowHeight]="'auto'"
      [columns]="columns"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [limit]="10"
      [rows]="rows">
      <ngx-datatable-column *ngFor="let column of columns; let i = index;" name="{{column.name}}" prop="{{column.prop}}">
      </ngx-datatable-column>
      <ngx-datatable-column name="Maker" prop="maker">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <div *ngIf="row['maker']">
            <a class="btn btn-sm btn-success mr-1" [href]="'/editar-maker/'+row['maker'].replace('/makers/', '')" tooltip="Editar" placement="bottom">
              <i class="fa fa-pencil"></i>
            </a>
<!--            <button class="btn btn-sm btn-outline-primary" (click)="deleteMaker(row['maker'])" tooltip="Eliminar" placement="bottom">-->
<!--              <i class="fa fa-trash"></i>-->
<!--            </button>-->
          </div>
          <div *ngIf="!row['maker']">
            <a class="btn btn-sm btn-outline-success mr-1" [href]="'/nuevo-maker/'+row['@id'].replace('/users/', '')" tooltip="Añadir usuario Maker" placement="bottom">
              <i class="fa fa-user-plus"></i>
            </a>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Taker" prop="taker">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <div *ngIf="row['taker']">
            <a class="btn btn-sm btn-success mr-1" [href]="'/editar-taker/'+row['taker'].replace('/takers/', '')" tooltip="Editar" placement="bottom">
              <i class="fa fa-pencil"></i>
            </a>
<!--            <button class="btn btn-sm btn-outline-primary mr-1" tooltip="Eliminar" placement="bottom">-->
<!--              <i class="fa fa-trash"></i>-->
<!--            </button>-->
          </div>
          <div *ngIf="!row['taker']">
            <a class="btn btn-sm btn-outline-success mr-1" [href]="'/nuevo-taker/'+row['@id'].replace('/users/', '')" tooltip="Añadir usuario Taker" placement="bottom">
              <i class="fa fa-user-plus"></i>
            </a>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Admin" prop="admin">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <input type="checkbox" class="form-check-input" id="admin" (click)="changeAdmin($event.target['checked'], row['@id'].replace('/users/', ''), row.roles)" [checked]="row.isAdmin">
          <label class="form-check-label" for="admin"></label>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="User" prop="user">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <div>
            <a class="btn btn-sm btn-success mr-1" [href]="'/editar-usuario/'+row['@id'].replace('/users/', '')" tooltip="Editar" placement="bottom">
              <i class="fa fa-pencil"></i>
            </a>
            <button class="btn btn-sm btn-outline-primary" (click)="deleteUser(row['@id'])" tooltip="Eliminar" placement="bottom">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
