import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TakersService } from './takers.service';
import { Taker } from './taker.model';
import { User } from '../../users/users/user.model';
import { UsersService } from '../../users/users/users.service';
import { errorMessages, regExps } from '../../shared/custom-validators';

@Component({
  selector: 'app-takers',
  templateUrl: './takers.component.html',
  styleUrls: ['./takers.component.css']
})
export class TakersComponent implements OnInit {
  takerForm: UntypedFormGroup;
  userForm: UntypedFormGroup;
  taker: Taker;
  users: User[] = [];
  userSelected = [];
  selectedFile: File;
  user = false;
  title = 'Nuevo taker';
  alert = {message: null, type: null};
  errors = errorMessages;

  constructor(
    private el: ElementRef,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private takerService: TakersService,
    private userService: UsersService,
  ) {
    this.createForm();
    if (this.activatedRoute.snapshot.params.id) {
      this.title = 'Editar taker';
      this.editTaker(this.activatedRoute.snapshot.params.id);
    } else {
      this.createUserForm();
    }
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.userId) {
      this.takerForm.get('user').setValue('/users/' + this.activatedRoute.snapshot.params.userId);
      this.user = true;
    }
  }

  createForm() {
    this.takerForm = this.formBuilder.group({
      name: [ '',
        Validators.required
      ],
      surname: [ '',
        Validators.required
      ],
      user: [ '']
    });
  }

  createUserForm() {
    this.userForm = this.formBuilder.group({
      email: [ '', [
        Validators.required,
        Validators.email
      ]],
      password: [ '', [
        Validators.required,
        Validators.pattern(regExps.password)
      ]],
    });
  }

  deleteTaker(takerId) {
    const id = takerId.replace('/takers/', '');
    this.takerService.deleteTaker(id).subscribe(() => {
      },
      error => {
      });
  }

  editTaker(takerId) {
    this.takerService.getTaker(takerId).subscribe((taker: Taker) => {
      this.taker = taker;
      this.takerForm.get('name').setValue(taker.name);
      this.takerForm.get('surname').setValue(taker.surname);
    });
  }

  updateTaker() {
    if (this.takerForm.invalid) {
      this.showErrors(this.takerForm);
      return;
    }
    const taker = {
      name: this.takerForm.get('name').value,
      surname: this.takerForm.get('surname').value
      // user: this.makerForm.get('user').value
    };
    const editedTaker = new Taker(taker);
    this.takerService.putTaker(this.taker.id, editedTaker)
      .subscribe(
        (result) => {
          this.alert.type = 1;
          this.alert.message = 'Taker guardado correctamente';
          this.taker = null;
          this.createForm();
          location.replace('/takers');
          setTimeout(() => {
            this.alert = {message: null, type: null};
          }, 5000);
        },
        error => {
          this.alert.message = error.error.code + '- ' + error.error.message;
          this.alert.type = 2;
          setTimeout(() => {this.alert = {message: null, type: null};
          }, 5000);
        },
        () => console.log('complete'));
  }

  createTaker() {
    if (this.takerForm.invalid) {
      this.showErrors(this.takerForm);
      return;
    }
    const taker = {
      name: this.takerForm.get('name').value,
      surname: this.takerForm.get('surname').value,
      user: this.takerForm.get('user').value
    };
    if (this.user) {
      this.takerService.postTaker(taker).subscribe(() => {
        this.alert.type = 1;
        this.alert.message = 'Taker creado correctamente';
        setTimeout(() => {
          this.alert = {message: null, type: null};
        }, 5000);
        this.createForm();
        location.replace('/takers');
      }, (error) => {
        this.alert.message = error.error.code + '- ' + error.error.message;
        this.alert.type = 2;
        setTimeout(() => {this.alert = {message: null, type: null};
        }, 5000);
      });
    }
    else if (this.userForm) {
      if (this.userForm.invalid) {
        this.showErrors(this.userForm);
        return;
      }
      const user = {
        email: this.userForm.get('email').value,
        password: this.userForm.get('password').value,
        roles: ['ROLE_TAKER']
      };
      this.userService.postUser(user).subscribe(result => {
        taker.user = result['@id'];
        this.takerService.postTaker(taker).subscribe(() => {
          this.alert.type = 1;
          this.alert.message = 'Taker creado correctamente';
          setTimeout(() => {
            this.alert = {message: null, type: null};
          }, 5000);
          this.createForm();
          location.replace('/takers');
        }, (error) => {
          this.alert.message = error.error.code + '- ' + error.error.message;
          this.alert.type = 2;
          setTimeout(() => {this.alert = {message: null, type: null};
          }, 5000);
        });
      }, (error) => {
        this.alert.message = error.error.code + '- ' + error.error.message;
        this.alert.type = 2;
        setTimeout(() => {this.alert = {message: null, type: null};
        }, 5000);
      });
    }
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector( 'form .ng-invalid' );
    firstInvalidControl.focus();
    return;
  }

  showUserError(formValue) {
    return this.userForm.get(formValue).invalid && this.userForm.get(formValue).touched;
  }

  showError(formValue) {
    return this.takerForm.get(formValue).invalid && this.takerForm.get(formValue).touched;
  }
}
