<div class="row">
  <div class="col-md-6">
    <div class="elemento1">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputMediaExperiences"><i class="fa fa-image"></i></span>
        </div>
        <div class="custom-file">
          <input
            #fileInput1
            type="file"
            id="media"
            class="custom-file-input"
            accept=".png, .jpg, .jpeg"
            aria-describedby="inputMediaMakers"
            (change)="insertImage($event.target.files, 'featuredImageTemp1')"
            style="display: none"
            lang="es">
          <label class="custom-file-label" #labelUpload for="media">Seleccionar una imagen</label>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-6 border-right">
    <p class="small font-weight-bold" *ngIf="featuredImageTemp1">Selecciona el area de la imagen</p>
    <angular-cropper #angularCropper [cropperOptions]="config" [imageUrl]="featuredImageTemp1" *ngIf="featuredImageTemp1"></angular-cropper>
  </div>
  <div class="col-6 previewer">
    <p class="small font-weight-bold" *ngIf="featuredImageTemp1">Previsualización</p>
    <div>
      <div [ngClass]="preview" class="float-none" *ngIf="featuredImageTemp1"></div>
    </div>
    <button class="btn btn-success uploader float-none" type="button"  (click)="uploadFeaturedFile($event)" *ngIf="featuredImageTemp1" [disabled]="uploadingProgressing">
      <span>Subir imagen</span>
    </button>
  </div>
</div>
