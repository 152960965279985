import { Component, OnInit, ViewChild } from '@angular/core';
import { ZoneService } from '../zones/zone.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-zones-list',
  templateUrl: './zones-list.component.html',
  styleUrls: ['./zones-list.component.scss']
})
export class ZonesListComponent implements OnInit {

  columns = [
    { prop: 'name', name: 'Nombre' },
    { prop: 'country', name: 'País' },
    { prop: 'location', name: 'Ubicación' },
    { prop: 'civitatisId', name: 'Civitatis Id' }];
  rows = [];
  searchForm: UntypedFormGroup;
  alert = { message: null, type: null };
  @ViewChild(DatatableComponent) table: DatatableComponent;
  dialogRef: BsModalRef;

  constructor(
    private zonesService: ZoneService,
    private searchFormBuilder: UntypedFormBuilder,
    public dialog: BsModalService,
  ) {
    this.zonesService.getZones().subscribe((zones => {
      this.rows = zones['hydra:member'];
      console.log(this.rows);
    }));
  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      name: [''],
      country: [''],
      location: ['']
    });
  }

  applyFilter() {
    this.zonesService.getZones(this.searchForm.value).subscribe(
      data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
      }
    );
  }

  deleteZone(zoneId) {
    this.dialogRef = this.dialog.show(ConfirmationDialogComponent, {
      initialState: {
        confirmTitle: '¿Quieres eliminar esta zona?',
        confirmMessage: 'No podrás volver a recuperarla',
        callback: (confirm) => {
          if (confirm) {
            this.zonesService.deleteZone(zoneId).subscribe(
              (result) => {
                this.alert.type = 1;
                this.alert.message = 'Zona eliminada correctamente';
                setTimeout(() => {
                  this.alert = { message: null, type: null };
                }, 5000);
              },
              error => {
                this.alert.message = error.error.code + '- ' + error.error.message;
                this.alert.type = 2;
                setTimeout(() => {
                  this.alert = { message: null, type: null };
                }, 5000);
              },
            );
            setTimeout(() => {
              this.zonesService.getZones().subscribe((zones => {
                this.rows = zones['hydra:member'];
                this.table.offset = 0;
              }));
            }, 2000);
          }
          this.dialogRef = null;
        }
      }
    });
  }

}
