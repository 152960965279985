import { Component, OnInit, ViewChild } from '@angular/core';
import { MakersService } from '../makers/makers.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { User } from '../../users/users/user.model';
import { UsersService } from '../../users/users/users.service';

@Component({
  selector: 'app-makers-list',
  templateUrl: './makers-list.component.html',
  styleUrls: ['./makers-list.component.css']
})
export class MakersListComponent implements OnInit {
  columns;
  checkboxColumn = Boolean(sessionStorage.getItem('checkboxColumn'));
  rows = [];
  searchForm: UntypedFormGroup;
  alert = { message: null, type: null };
  @ViewChild(DatatableComponent) table: DatatableComponent;
  dialogRef: BsModalRef;

  page = 1;
  itemsPerPage = 100;
  totalItems = 0;
  allMakers: any = []; 
  allExperiences: any = [];


  constructor(
    private makersService: MakersService,
    private usersService: UsersService,
    private searchFormBuilder: UntypedFormBuilder,
    public dialog: BsModalService,
  ) {
    this.makersService.getMakers().subscribe((makers => {
      this.rows = makers['hydra:member'];
    }));

    //this.scriptSlugMakers();
    //this.gettingExperiences();


    if (!sessionStorage.getItem('makerColumns')) {
      this.columns = [
        { prop: 'name', name: 'Nombre', order: 1 },
        { prop: 'document', name: 'Documento', order: 2 },
        { prop: 'documentType', name: 'Tipo de Documento', order: 3 },
        { prop: 'registerDate', name: 'Fecha de Alta', order: 4 },
        { prop: 'bookingEngine', name: 'Motor de Reservas', order: 5 },
        { prop: 'currentPlan', name: 'Plan Actual', order: 6 },
        { prop: 'lastLogin', name: 'Ultimo Acceso', order: 7 },
        { prop: 'monthLogins', name: 'Nº Accesos ultimos 30 días', order: 8 },
        { prop: 'activeProducts', name: 'Nº Productos Activos', order: 9 },
        { prop: 'makerPinStatus', name: 'Maker Pin', order: 10 },
        { prop: 'makerPlan.plan.name', name: 'Plan', order: 11 }];
    } else {
      this.columns = JSON.parse(sessionStorage.getItem('makerColumns'))
    }
    if (!sessionStorage.getItem('checkboxColumn')) {
      this.checkboxColumn = true
    } else {
      if (sessionStorage.getItem('checkboxColumn') == 'true') {
        this.checkboxColumn = true
      } else {
        this.checkboxColumn = false
      }
    }
  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      name: [''],
      document: ['']
    });


  }

  applyFilter() {
    this.makersService.getMakers(this.searchForm.value).subscribe(
      data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
      }
    );
  }

  deleteMaker(makerId) {
    this.dialogRef = this.dialog.show(ConfirmationDialogComponent, {
      initialState: {
        confirmTitle: '¿Quieres eliminar este maker?',
        confirmMessage: 'No podrás volver a recuperarlo',
        callback: (confirm) => {
          if (confirm) {
            this.makersService.deleteMaker(makerId).subscribe(
              (result) => {
                this.alert.type = 1;
                this.alert.message = 'Maker eliminado correctamente';
                setTimeout(() => {
                  this.alert = { message: null, type: null };
                }, 5000);
              },
              error => {
                this.alert.message = error.error.code + '- ' + error.error.message;
                this.alert.type = 2;
                setTimeout(() => {
                  this.alert = { message: null, type: null };
                }, 5000);
              },
            );
            setTimeout(() => {
              this.makersService.getMakers().subscribe((makers => {
                this.rows = makers['hydra:member'];
                this.table.offset = 0;
              }));
            }, 2000);
          }
          this.dialogRef = null;
        }
      }
    });
  }

  changeMakerReseller(isMakerReseller, userId, userRoles) {
    if (isMakerReseller) {
      userRoles.push('ROLE_MAKER_RESELLER');
    } else {
      const index = userRoles.indexOf('ROLE_MAKER_RESELLER');
      userRoles.splice(index, 1);
    }
    const user = {
      roles: userRoles,
    };
    const editedUser = new User(user);
    this.usersService.putUser(userId, editedUser).subscribe();
  }

  enableColumns(filterColumn: any) {
    if (filterColumn != 'checkbox') {
      if (this.columns.some(column => column.prop === filterColumn.prop)) {
        this.columns = this.columns.filter(column => column.prop !== filterColumn.prop);
      } else {
        this.columns.push(filterColumn)
      }
    } else {
      this.checkboxColumn = !this.checkboxColumn
    }
    this.columns.sort((a, b) => a.order - b.order);
    sessionStorage.setItem('makerColumns', JSON.stringify(this.columns))
    sessionStorage.setItem('checkboxColumn', JSON.stringify(this.checkboxColumn))
  }

  checkCheckbox(prop: any) {
    if (prop != 'specialColumn') {
      if (!this.columns.some(column => column.prop === prop)) {
        return false
      } else {
        return true
      }
    } else {
      if (sessionStorage.getItem('checkboxColumn') == 'false') {
        return false
      } else {
        return true
      }
    }

  }

  goMaker(maker: any) {
    let makerStorage = {"id": maker.id, "name": maker.name}
    // const windowFeatures = 'width=600,height=400,location=no,toolbar=no,menubar=no';
    const newWindowURL = 'https://venntur.com' + '?token=' + localStorage.getItem('token') + '&user=' + localStorage.getItem('user') + '&maker=' + JSON.stringify(makerStorage);
    window.open(newWindowURL, '_blank');
  }


/* 
  scriptSlugExperiences(){

    const url = `?page=${this.page}&itemsPerPage=${this.itemsPerPage}`;


    var existentSlugs: any = [];
    
    this.experienceService.getAllExperiences(url).subscribe((experiences => {
      

      this.totalItems = experiences['hydra:totalItems'];


      experiences[ 'hydra:member' ].forEach(experience => {
        this.allExperiences.push(experience);
        console.log("")
        console.log("***********************")
        console.log("Experience: ", experience.slug);
        console.log("***********************")
        console.log("")
      });


  
      // Verifica si hay más resultados disponibles
      if (this.page * this.itemsPerPage < this.totalItems) {
        this.page++;
        this.scriptSlugExperiences(); // Realiza otra solicitud
      } else {
        //this.validateSlugs();
      }

      
      console.log(this.allExperiences);
    }));


  } */

  scriptSlugMakers(){
      
      const url = `?page=${this.page}&itemsPerPage=${this.itemsPerPage}`;

      var existentSlugs: any = [];

      console.log("ENTRA EN EL SCRIPT");
      console.log(url);

      this.makersService.getAllMakers(url).subscribe((makers => {
          
          this.totalItems = makers['hydra:totalItems'];

          makers[ 'hydra:member' ].forEach(maker => {
            this.allMakers.push(maker);
           /*  console.log("")
            console.log("***********************")
            console.log(maker);
            console.log("Maker: ", maker.slug);
            console.log("***********************")
            console.log("") */
          });

          // Verifica si hay más resultados disponibles
          if (this.page * this.itemsPerPage < this.totalItems) {
            this.page++;
            this.scriptSlugMakers(); // Realiza otra solicitud
          } else {
            console.log("ACABÓ");
            //this.validateMakers();
            //this.validateSlugs();
          }

      }));
  }

  validateMakers(){

    var makers = this.allMakers;

    var makersWithPlan = [];
    var makersWithoutPlan = [];

    var makersWithDirectory = [];
    var makersWithoutDirectory = [];

    var makersWithPin = [];
    var makersWithoutPin = [];

    var makersWithSheet = [];
    var makersWithoutSheet = [];

    var makersAnalitycs = [];
    var makersWithoutAnalitycs = [];

    makers.forEach(maker => {

     /*  console.log("***********************")
      console.log(maker);
      console.log(maker.slug);
      console.log(maker.makerPlan);
      console.log(maker.directoryCompleted);
      console.log(maker.pinCompleted);
      console.log(maker.sheetCompleted);
      console.log("");
      console.log("");
 */
      if(maker.makerPlan == null){
        makersWithoutPlan.push(maker);

      } else {
        makersWithPlan.push(maker);
      }

      if(maker.directoryCompleted == false){
        makersWithoutDirectory.push(maker);
      } else {
        makersWithDirectory.push(maker);
      }

      if(maker.pinCompleted == false){
        makersWithoutPin.push(maker);
      } else {
        makersWithPin.push(maker);
      }

      if(maker.sheetCompleted == false){
        makersWithoutSheet.push(maker);
      } else {
        makersWithSheet.push(maker);
      }

      if(maker.directoriesAnalytics.length == 0){
        makersWithoutAnalitycs.push(maker);
      } else {
        makersAnalitycs.push(maker);
      }

      
    });

    console.log("||||||||||||||||||||||||||||||||||");
    console.log(makersWithPlan);
    
    console.log(makersWithDirectory);
    
    console.log(makersWithPin);
    
    console.log(makersWithSheet);

    console.log(makersAnalitycs);
    
    
    console.log("||||||||||||||||||||||||||||||||||");
  
    console.log("");
    
    console.log("----------------------------------");
    console.log(makersWithoutPlan);
    console.log(makersWithoutDirectory);
    console.log(makersWithoutPin);
    console.log(makersWithoutSheet);
    console.log(makersWithoutAnalitycs);
    console.log("----------------------------------");


    //this.makePlansMakers(makersWithoutPlan);

    //makersWithoutAnalitycs = makersWithoutAnalitycs.slice(0, 3);
    //this.createAnalyticsMakers(makersWithoutAnalitycs);

    //makersWithoutPin = makersWithoutPin.slice(0, 3);

    //this.createPinMakers(makersWithPin);





  }


/*   makePlansMakers(makerList){

    makerList.forEach(maker => {

      var actualDate = new Date();

      var makerPlan = {
        price: "0",
        stripeCustomerId: "",
        plan: "/plans/df432580-3b93-11eb-80af-509a4c653a6c",
        maker: maker['@id'],
        startDate: actualDate,
      };

      console.log("***********************")
      console.log(maker);
      console.log("MAKER PLAN: ", makerPlan);
      console.log("***********************")


      if(makerPlan){
        this.makersService.postMakerPlan(makerPlan).subscribe((result) => {
          console.log("PLAN CREADO");
          console.log(result);
        });
      }


    });

  } */

/*   createAnalyticsMakers(makerList){


    makerList.forEach(maker => {

      if(maker.directoriesAnalytics.length == 0){
        this.makersService.createAnalytics(maker['@id']).subscribe((result) => {
  
          console.log("ANALYTICS CREADO");
          console.log(result);
  
        });

      }

    });

  }
 */

  createPinMakers(makerList){

    makerList.forEach(maker => {

      //Para activar el directorio el maker debe tener: name, phone, whatsapp, email, web, mediaMakers

      if(maker.name && maker.phone && maker.whatsapp && maker.email && maker.web && maker.mediaMakers.length > 0){
        maker.directoryCompleted = true;
      }

      //Para activar la ficha de empresa el maker debe tener:  country, location, address, postalCode, description, mediaMakersHeader

      if(maker.country && maker.location && maker.address && maker.postalCode && maker.description && maker.mediaMakersHeader.length > 0){
        maker.sheetCompleted = true;
      }

      //Para activar el pin debe tener: phrase, founders, director, operations, slug, technology, customerId, holdedId, googleSites, phone2, tripadvisor, bookingEngine, bookingEngineWidget, tripadvisorApiWidgetReview, linkedin, twitter, instagram, facebook, tags, mediaMakersStaff

      if(maker.phrase && maker.founders && maker.director && maker.operations && maker.slug && maker.technology && maker.customerId && maker.holdedId && maker.googleSites && maker.phone2 && maker.tripadvisor && maker.bookingEngine && maker.bookingEngineWidget && maker.tripadvisorApiWidgetReview && maker.linkedin && maker.twitter && maker.instagram && maker.facebook && maker.tags.length > 0 && maker.mediaMakersStaff.length > 0){
        maker.pinCompleted = true;
      }

      maker.user = maker.user['@id'];

      delete maker.mediaMakers;
      delete maker.makerPlan;
      delete maker.experiences;
      delete maker.directoriesAnalytics;
      delete maker.tags;
      delete maker.mediaMakersStaff;
      delete maker.mediaMakersLogo;
      delete maker.activeLocations;
      delete maker.activeCategories;
      delete maker.contacts;
      delete maker.contactsGroups;
      delete maker.mediaMakersHeader;
      delete maker.location;
      delete maker.activeTags;
      delete maker.email;
      delete maker.phone;
      delete maker.whatsapp;

      if(maker.directoryCompleted || maker.sheetCompleted || maker.pinCompleted){

        this.makersService.putMaker(maker.id, maker, 'es').subscribe((result) => {

          console.log("PIN CREADO");
          console.log(result);

        });

      }



    });

  }


  gettingExperiences(){


    // microsheetCompleted,sheetCompleted, pinCompleted


    const url = `?page=${this.page}&itemsPerPage=${this.itemsPerPage}`;


    console.log("ENTRA EN EL SCRIPT");
    console.log(url);

    this.makersService.getExperiences(url).subscribe((experiences => {
        
        this.totalItems = experiences['hydra:totalItems'];

        experiences[ 'hydra:member' ].forEach(experience => {
          this.allExperiences.push(experience);
          console.log("")
          console.log("***********************")
          console.log(experience);
          console.log("Slug Exp: ", experience.slug);
          console.log("***********************")
          console.log("")
        });

        // Verifica si hay más resultados disponibles
        if (this.page * this.itemsPerPage < this.totalItems) {
          this.page++;
          this.gettingExperiences(); // Realiza otra solicitud
        } else {
          console.log("ACABÓ");
          //this.validateExperiences();
          this.validateSlugs();
        }

    }));







  }

  validateExperiences(){

    var experiences = this.allExperiences;

    var experiencesWithPin = [];
    var experiencesWithoutPin = [];

    var experiencesWithSheet = [];
    var experiencesWithoutSheet = [];

    var experiencesWithMicroSheet = [];
    var experiencesWithoutMicroSheet = [];

    experiences.forEach(experience => {

      if(experience.pinCompleted == false || experience.pinCompleted == null ){
        experiencesWithoutPin.push(experience);
      } else {
        experiencesWithPin.push(experience);
      }

      if(experience.sheetCompleted == false || experience.sheetCompleted == null ){
        experiencesWithoutSheet.push(experience);
      } else {
        experiencesWithSheet.push(experience);
      }

      if(experience.microsheetCompleted == false || experience.microsheetCompleted == null ){
        experiencesWithoutMicroSheet.push(experience);
      } else {
        experiencesWithMicroSheet.push(experience);
      }

      
    });

    /* console.log("||||||||||||||||||||||||||||||||||");
    console.log(experiencesWithPin);
    
    console.log(experiencesWithSheet);
    
    console.log(experiencesWithMicroSheet);
    
    
    console.log("||||||||||||||||||||||||||||||||||");
  
    console.log("");
    
    console.log("----------------------------------");
    console.log(experiencesWithoutPin);
    console.log(experiencesWithoutSheet);
    console.log(experiencesWithoutMicroSheet);
    console.log("----------------------------------"); */

    //reduce array experiencesWithoutPin to 1 element
    //experiencesWithoutPin = experiencesWithoutPin.slice(0, 1);

    this.createPinExperiences(experiencesWithoutPin);

  }


  createPinExperiences(experienceList){

    var experiencesWithPin = [];
    var experiencesWithoutPin = [];

    var experiencesWithSheet = [];
    var experiencesWithoutSheet = [];

    var experiencesWithMicroSheet = [];
    var experiencesWithoutMicroSheet = [];

    experienceList.forEach(experience => {

      experience.months = [0, 1, 2, 3, 4, 5, 6, 7, 8 ,9 ,10 ,11];
      
      experience.pinCompleted = false;

      //delete: mediaExperiences, languages, location, maker, categories, modalities, resellerFavoriteExperiences, stopSales, tags, takers, zones

      //para activar la microficha debe tener: name, description, url, duration, categories, country, location, languages, mediaExperiences
      if(experience.name && experience.description && experience.url && experience.duration && experience.categories.length > 0 && experience.country && experience.location && experience.languages.length > 0 && experience.mediaExperiences.length > 0){
        experience.microsheetCompleted = true;
        experiencesWithMicroSheet.push(experience);
      } else {
        experience.microsheetCompleted = false;
        experiencesWithoutMicroSheet.push(experience);
      }

      //Para activar la ficha de producto debe tener: descriptionExtended, whyUs, includes, carryInfo
      if(experience.includes && experience.includes.length > 0){
        experience.sheetCompleted = true;
        experiencesWithSheet.push(experience);
        
      } else {
        experience.sheetCompleted = false;
        experiencesWithoutSheet.push(experience);
      }

      experiencesWithoutPin.push(experience);



      delete experience.mediaExperiences;
      delete experience.languages;
      delete experience.location;
      delete experience.maker;
      delete experience.categories;
      delete experience.modalities;
      delete experience.resellerFavoriteExperiences;
      delete experience.stopSales;
      delete experience.tags;
      delete experience.takers;
      delete experience.zones;
      



      //putExperience
      this.makersService.putExperience(experience.id, experience, 'es').subscribe((result) => {

        console.log("EXPERIENCIA ACTUALIZADA");
        console.log(result);

      });





    });

    console.log("||||||||||||||||||||||||||||||||||");
    console.log(experiencesWithPin);

    console.log(experiencesWithSheet);

    console.log(experiencesWithMicroSheet);
    console.log("||||||||||||||||||||||||||||||||||");

    console.log("");

    console.log("----------------------------------");
    console.log(experiencesWithoutPin);
    console.log(experiencesWithoutSheet);
    console.log(experiencesWithoutMicroSheet);
    console.log("----------------------------------");


  }



  
  /* createPinExperiences(experienceList){

    experienceList.forEach(experience => {

      //Para activar el directorio el maker debe tener: name, phone, whatsapp, email, web, mediaMakers

      if(experience.name && experience.phone && experience.whatsapp && experience.email && experience.web && experience.mediaMakers.length > 0){
        experience.directoryCompleted = true;
      }

      //Para activar la ficha de empresa el maker debe tener:  country, location, address, postalCode, description, mediaMakersHeader

      if(experience.country && experience.location && experience.address && experience.postalCode && experience.description && experience.mediaMakersHeader.length > 0){
        experience.sheetCompleted = true;
      }

      //Para activar el pin debe tener: phrase, founders, director, operations, slug, technology, customerId, holdedId, googleSites, phone2, tripadvisor, bookingEngine, bookingEngineWidget, tripadvisorApiWidgetReview, linkedin, twitter, instagram, facebook, tags, mediaMakersStaff

      if(maker.phrase && maker.founders && maker.director && maker.operations && maker.slug && maker.technology && maker.customerId && maker.holdedId && maker.googleSites && maker.phone2 && maker.tripadvisor && maker.bookingEngine && maker.bookingEngineWidget && maker.tripadvisorApiWidgetReview && maker.linkedin && maker.twitter && maker.instagram && maker.facebook && maker.tags.length > 0 && maker.mediaMakersStaff.length > 0){
        maker.pinCompleted = true;
      }

      maker.user = maker.user['@id'];

      delete maker.mediaMakers;
      delete maker.makerPlan;
      delete maker.experiences;
      delete maker.directoriesAnalytics;
      delete maker.tags;
      delete maker.mediaMakersStaff;
      delete maker.mediaMakersLogo;
      delete maker.activeLocations;
      delete maker.activeCategories;
      delete maker.contacts;
      delete maker.contactsGroups;
      delete maker.mediaMakersHeader;
      delete maker.location;
      delete maker.activeTags;
      delete maker.email;
      delete maker.phone;
      delete maker.whatsapp;

      if(maker.directoryCompleted || maker.sheetCompleted || maker.pinCompleted){

        this.makersService.putMaker(maker.id, maker, 'es').subscribe((result) => {

          console.log("PIN CREADO");
          console.log(result);

        });

      }



    });

  } */



  validateSlugs(){
      
    var experiences = this.allExperiences;
    
    var slugsExperiences = [];
    var slugExperiencesClean = [];
    var noSlugExperiences = [];

    experiences.forEach(experience => {

      //console.log(experience.slug);
      if(experience.slug){
        
        //if experience.slug has the string -1 on it then save slug
        if(experience.slug.indexOf('-1') !== -1){
          slugsExperiences.push(experience);
        } else { 
          slugExperiencesClean.push(experience);
        }


      } else {
        //console.log("NO SLUG!");
        noSlugExperiences.push(experience);
      }

    });

    console.log("EXPERIENCES: ", slugsExperiences);
    console.log("EXPERIENCES CLEAN: ", slugExperiencesClean);
    console.log("EXPERIENCES NO SLUG: ", noSlugExperiences);

    this.generateSlugs(noSlugExperiences);

    //this.validateSlugExperiences(slugsExperiences);



  }

  patchSlugsExperiences(){
   
  }

  validateSlugExperiences(experiences){

    experiences.forEach(experience => {

      //remove -1 from slug string
      experience.slug = experience.slug.replace('-1', '');


      var slugToPatch = {
        slug: experience.slug
      };



      this.makersService.getExperienceBySlug(experience.slug).subscribe(exist => {
        //console.log(exist['hydra:member']);

        if(exist['hydra:member'].length > 0){
          console.log("SLUG EXISTENTE");
          console.log(exist);
        } else {
          console.log("SLUG NO EXISTENTE");
          console.log(exist);

          this.makersService.patchExperience(experience.id, slugToPatch, 'es').subscribe(result => {
            console.log("SLUG EXPERIENCIA ACTUALIZADA");
            console.log(result);
          });

        }

      });
    });

    //this.patchSlugsExperiences();


  }


  generateSlugs(experiences){

    experiences.forEach(experience => {
      
      console.log("");
      console.log("******************************")
      console.log(experience.name);
      
      var slugCleared = experience.name;
      //clear accents (A-U)
      slugCleared = slugCleared.replace(/[ÀÁÂÃÄÅ]/g, "A");
      slugCleared = slugCleared.replace(/[àáâãäå]/g, "a");
      slugCleared = slugCleared.replace(/[ÈÉÊË]/g, "E");
      slugCleared = slugCleared.replace(/[éèêë]/g, "e");
      slugCleared = slugCleared.replace(/[íìîï]/g, "i");
      slugCleared = slugCleared.replace(/[ÍÌÎÏ]/g, "I");
      slugCleared = slugCleared.replace(/[óòôõö]/g, "o");
      slugCleared = slugCleared.replace(/[ÓÒÔÕÖ]/g, "O");
      slugCleared = slugCleared.replace(/[úùûü]/g, "u");
      slugCleared = slugCleared.replace(/[ÚÙÛÜ]/g, "U");
      slugCleared = slugCleared.replace(/[ñ]/g, "n");
      slugCleared = slugCleared.replace(/[Ñ]/g, "N");
      slugCleared = slugCleared.replace(/[ç]/g, "c");
      slugCleared = slugCleared.replace(/[Ç]/g, "C");
      slugCleared = slugCleared.replace(/[ÿý]/g, "y");
      slugCleared = slugCleared.replace(/[Ý]/g, "Y");
      slugCleared = slugCleared.replace(/[’]/g, "");
      slugCleared = slugCleared.replace(/[‘]/g, "");
      slugCleared = slugCleared.replace(/[“]/g, "");
      slugCleared = slugCleared.replace(/[”]/g, "");
      slugCleared = slugCleared.replace(/[«]/g, "");
      slugCleared = slugCleared.replace(/[»]/g, "");
      slugCleared = slugCleared.replace(/[—]/g, "-");
      slugCleared = slugCleared.replace(/[·]/g, "-");
      slugCleared = slugCleared.replace(/[\/]/g, "-");
      slugCleared = slugCleared.replace(/[\\]/g, "-");
      slugCleared = slugCleared.replace(/[?]/g, "");
      slugCleared = slugCleared.replace(/[¿]/g, "");
      slugCleared = slugCleared.replace(/[!]/g, "");
      slugCleared = slugCleared.replace(/[¡]/g, "");
      slugCleared = slugCleared.replace(/[º]/g, "");
      slugCleared = slugCleared.replace(/[ª]/g, "");
      slugCleared = slugCleared.replace(/[°]/g, "");
      slugCleared = slugCleared.replace(/[&]/g, "-");
      slugCleared = slugCleared.replace(/[<]/g, "");
      slugCleared = slugCleared.replace(/[>]/g, "");
      slugCleared = slugCleared.replace(/[;]/g, "");
      slugCleared = slugCleared.replace(/[']/g, "");
      slugCleared = slugCleared.replace(/["]/g, "");
      slugCleared = slugCleared.replace(/[,]/g, "");
      slugCleared = slugCleared.replace(/[.]/g, "-");
      slugCleared = slugCleared.replace(/[:]/g, "");
      slugCleared = slugCleared.replace(/[%]/g, "");
      slugCleared = slugCleared.replace(/[@]/g, "-");
      slugCleared = slugCleared.replace(/[*]/g, "");
      slugCleared = slugCleared.replace(/[+]/g, "");
      slugCleared = slugCleared.replace(/[=]/g, "");
      slugCleared = slugCleared.replace(/[|]/g, "");
      slugCleared = slugCleared.replace(/[()]/g, "");
      slugCleared = slugCleared.replace(/[[]/g, "");
      slugCleared = slugCleared.replace(/[]]/g, "");
      slugCleared = slugCleared.replace(/[{}]/g, "");
      slugCleared = slugCleared.replace(/[~]/g, "");

      //clear spaces
      slugCleared = slugCleared.replace(/ /g, "-");
      //clear double spaces and souble --
      slugCleared = slugCleared.replace(/--/g, "-");
      //clear begin and end -
      slugCleared = slugCleared.replace(/^-/g, "");
      slugCleared = slugCleared.replace(/-$/g, "");

      //to lower case
      slugCleared = slugCleared.toLowerCase();
      
      experience.slug = slugCleared;

      console.log("Slug: ", experience.slug); 
      
      console.log("******************************")
      console.log("");
      
    });

    this.validateSlugExperiences(experiences);



  }


}
