import { Component, OnInit, ViewChild } from '@angular/core';
import { CategoriesService } from '../categories/categories.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.css']
})
export class CategoriesListComponent implements OnInit {
  columns = [
    { prop: 'name', name: 'Nombre' },
    { prop: 'description', name: 'Descripción' }];
  rows = [];
  searchForm: UntypedFormGroup;
  alert = {message: null, type: null};
  @ViewChild(DatatableComponent) table: DatatableComponent;
  dialogRef: BsModalRef;

  constructor(
    private categoriesService: CategoriesService,
    private searchFormBuilder: UntypedFormBuilder,
    public dialog: BsModalService,
  ) {
    this.categoriesService.getCategories().subscribe((categories => {
      this.rows = categories[ 'hydra:member' ];
    }));
  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      name: ['']
    });
  }

  applyFilter() {
    this.categoriesService.getCategories(this.searchForm.value).subscribe(
      data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
      }
    );
  }

  deleteCategory(categoryId) {
    this.dialogRef = this.dialog.show(ConfirmationDialogComponent, {
      initialState: {
        confirmTitle: '¿Quieres eliminar esta categoría?',
        confirmMessage: 'No podrás volver a recuperarla',
        callback: (confirm) => {
          if (confirm) {
            this.categoriesService.deleteCategory(categoryId).subscribe(
              (result) => {
                this.alert.type = 1;
                this.alert.message = 'Categoría eliminada correctamente';
                setTimeout(() => {
                  this.alert = {message: null, type: null};
                }, 5000);
              },
              error => {
                this.alert.message = error.error.code + '- ' + error.error.message;
                this.alert.type = 2;
                setTimeout(() => {this.alert = {message: null, type: null};
                }, 5000);
              },
            );
            setTimeout(() => {
              this.categoriesService.getCategories().subscribe((categories => {
                this.rows = categories[ 'hydra:member' ];
                this.table.offset = 0;
              }));
            }, 2000);
          }
          this.dialogRef = null;
        }
      }
    });
  }
}
