export class UserCollection {
  total: number;
  makers: User[];
}

export class User {
  id: string;
  email: string;
  password: string;
  constructor(values: any = {}) {
    Object.assign(this, values);
  }
}
