import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Plan, PlanCollection } from './plan.model';
import { map } from 'rxjs/operators';


const API_URL = environment.apiUrl;

@Injectable()
export class PlansService {
  constructor(
    private http: HttpClient
  ) { }

  public postPlan(plan): Observable<Plan> {
    return this.http
      .post<Plan>(API_URL + '/plans', plan);
  }

  public getPlan(id: string, plan): Observable<Plan> {
    return this.http
      .get<Plan>(API_URL + '/plans/' + id, { headers: {
          'Accept-Language': plan
      }});
  }

  public getPlans(filters?: any, plan = 'es'): Observable<PlanCollection> {
    if (filters === undefined) { filters = {}; }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('name', String(filters.name));
    }
    return this.http
      .get<PlanCollection>(API_URL + '/plans', { params: httpParams , headers: {
          'Accept-Language': plan
      }});
  }

  public deletePlan(planId: string): any {
    return this.http.delete<Plan>(API_URL + planId);
  }

  public putPlan(planId: string, plan: Plan, locale = 'es'): Observable<Plan> {
    console.log(API_URL + planId);
    return this.http
      .put<Plan>(API_URL + planId, plan, { headers: {
          'Accept-Language': locale
      }});
  }
}
