import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Plan } from './plan.model';
import { PlansService } from './plans.service';
import { errorMessages } from '../../shared/custom-validators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css']
})
export class PlansComponent implements OnInit {
  planForm: UntypedFormGroup;
  plan: Plan;
  title = 'Nuevo plan';
  alert = {message: null, type: null};
  errors = errorMessages;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private planService: PlansService,
    private el: ElementRef,
    public translate: TranslateService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.id) {
      this.title = 'Editar plan';
      this.editPlan(this.activatedRoute.snapshot.params.id);
    }
  }

  // id, name, price, is_active, short_name, max_products, sku, payment_type, stripe_id, description
  createForm() {
    this.planForm = this.formBuilder.group({
      name: [ '',
        Validators.required
      ],
      price: [ '',
        Validators.required
      ],
      isActive: [ false,
        Validators.required
      ],
      shortName: [ '',
      ],
      maxProducts: [ 3,
      ],
      sku: [ '',
      ],
      paymentType: [ 'Monthly',
      ],
      stripeId: [ '',
      ],
      description: [ '',
      ],
      level: [ 0,
      ],
    });
  }

  deletePlan(planId) {
    const id = planId.replace('/plans/', '');
    this.planService.deletePlan(id).subscribe(() => {
      },
      error => {
      });
  }

  editPlan(planId) {
    this.planService.getPlan(planId, this.translate.getDefaultLang()).subscribe((plan: Plan) => {
      this.plan = plan;
      this.planForm.get('name').setValue(plan.name);
      this.planForm.get('price').setValue(plan.price);
      this.planForm.get('isActive').setValue(plan.isActive);
      this.planForm.get('shortName').setValue(plan.shortName);
      this.planForm.get('maxProducts').setValue(plan.maxProducts);
      this.planForm.get('sku').setValue(plan.sku);
      this.planForm.get('paymentType').setValue(plan.paymentType);
      this.planForm.get('stripeId').setValue(plan.stripeId);
      this.planForm.get('description').setValue(plan.description);
      this.planForm.get('level').setValue(plan.level);

    });
  }

  updatePlan() {
    if (this.planForm.invalid) {
      this.showErrors(this.planForm);
      return;
    }

    var price = this.planForm.get('price').value;
    price = price.toString();

    const plan = {
      name: this.planForm.get('name').value,
      price: price,
      isActive: this.planForm.get('isActive').value,
      shortName: this.planForm.get('shortName').value,
      maxProducts: this.planForm.get('maxProducts').value,
      sku: this.planForm.get('sku').value,
      paymentType: this.planForm.get('paymentType').value,
      stripeId: this.planForm.get('stripeId').value,
      description: this.planForm.get('description').value,
      level: this.planForm.get('level').value
    };
    const editedPlan = new Plan(plan);
    this.planService.putPlan(this.plan['@id'], editedPlan, this.translate.getDefaultLang())
      .subscribe(
        (result) => {
          this.plan = null;
          this.createForm();
          this.alert.type = 1;
          this.alert.message = 'Plan guardado correctamente';
          setTimeout(() => {
            this.alert = {message: null, type: null};
          }, 5000);
          location.replace('/planes');
        },
        error => {
          this.alert.message = error.error.code + '- ' + error.error.message;
          this.alert.type = 2;
          setTimeout(() => {this.alert = {message: null, type: null};
          }, 5000);
        },
        () => console.log('complete'));
  }

  createPlan() {
    if (this.planForm.invalid) {
      this.showErrors(this.planForm);
      return;
    }

    var price = this.planForm.get('price').value;

    // to string 
    price = price.toString();


    const plan = {
      name: this.planForm.get('name').value,
      price: price,
      isActive: this.planForm.get('isActive').value,
      shortName: this.planForm.get('shortName').value,
      maxProducts: this.planForm.get('maxProducts').value,
      sku: this.planForm.get('sku').value,
      paymentType: this.planForm.get('paymentType').value,
      stripeId: this.planForm.get('stripeId').value,
      description: this.planForm.get('description').value,
      level: this.planForm.get('level').value
    };
    this.planService.postPlan(plan).subscribe((result) => {
      this.createForm();
      this.alert.type = 1;
      this.alert.message = 'Plan creado correctamente';
      setTimeout(() => {
        this.alert = {message: null, type: null};
      }, 5000);
      location.replace('/planes');
    }, (error) => {
      this.alert.message = error.error.code + '- ' + error.error.message;
      this.alert.type = 2;
      setTimeout(() => {this.alert = {message: null, type: null};
      }, 5000);
    });
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector( 'form .ng-invalid' );
    firstInvalidControl.focus();
    return;
  }

  showError(formValue) {
    return this.planForm.get(formValue).invalid && this.planForm.get(formValue).touched;
  }

  changePlan(plan) {
    this.translate.setDefaultLang(plan);
    setTimeout(() => {
      this.editPlan(this.activatedRoute.snapshot.params.id);
    }, 1000);
  }
}
