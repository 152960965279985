import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Category, CategoryCollection } from './category.model';


const API_URL = environment.apiUrl;

@Injectable()
export class CategoriesService {
  constructor(
    private http: HttpClient
  ) { }

  public postCategory(category): Observable<Category> {
    return this.http
      .post<Category>(API_URL + '/categories', category);
  }

  public getCategory(id: string, language): Observable<Category> {
    return this.http
      .get<Category>(API_URL + '/categories/' + id, { headers: {
          'Accept-Language': language
      }});
  }

  public getCategories(filters?: any, language = 'es'): Observable<CategoryCollection> {
    if (filters === undefined) { filters = {}; }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('name', String(filters.name));
    }
    return this.http
      .get<CategoryCollection>(API_URL + '/categories', { params: httpParams, headers: {
          'Accept-Language': language
      }});
  }

  public deleteCategory(categoryId: string): any {
    return this.http.delete<Category>(API_URL + categoryId);
  }

  public putCategory(categoryId: string, category: Category, language = 'es'): Observable<Category> {
    return this.http
      .put<Category>(API_URL + categoryId, category, { headers: {
          'Accept-Language': language
      }});
  }
}
