import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable()
export class MediaMakersService {
  constructor(private http: HttpClient) {
  }

  mediaMakersUpload(fileItem: File, url, extraData?: object): Observable<any> {
    const apiCreateEndpoint = `${API_URL}${url}`;
    const formData: FormData = new FormData();

    formData.append('file', fileItem, fileItem.name);
    if (extraData) {
      for (const key in extraData) {
        if (extraData.hasOwnProperty(key)) {
          formData.append(key, extraData[ key ]);
        }
      }
    }
    const req = new HttpRequest('POST', apiCreateEndpoint, formData, {
      reportProgress: true // for progress data
    });
    return this.http.request(req);
  }

  deleteMediaMakers(mediaMakersId) {
    return this.http
      .delete(`${API_URL}${mediaMakersId}`);
  }

  getMediaMakers(mediaMakersId) {
    return this.http
      .get(`${API_URL}${mediaMakersId}`);
  }
}
