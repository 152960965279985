import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Reseller } from './reseller.model';
import { HttpEventType } from '@angular/common/http';
import { MediaResellerService } from './media-reseller';

import { CustomValidators, errorMessages, regExps } from '../../shared/custom-validators';
import { AuthService } from '../../shared/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

import { ReplaySubject } from 'rxjs';
import { Category } from '../../categories/categories/category.model';
import { Country } from '../../locations/locations/country.model';
import { Tag } from '../../tags/tags/tag.model';
import { UsersService } from '../../users/users/users.service';
import { CategoriesService } from '../../categories/categories/categories.service';
import { TagsService } from '../../tags/tags/tags.service';
import { CountryService } from '../../locations/locations/country.service';
import { User } from '../../users/users/user.model';
import { ResellerService } from './reseller.service';


const FRONT_URL = environment.frontUrl;

@Component({
  selector: 'app-reseller',
  templateUrl: './reseller.component.html',
})
export class ResellerComponent implements OnInit {
  resellerForm: UntypedFormGroup;
  userForm: UntypedFormGroup;
  reseller: Reseller;
  user = false;
  title = 'Nuevo reseller';
  selectedFile: File;
  selectedFiles = [];
  uploadedFiles = [];
  categories: Category[] = [];
  categoriesSelected = [];
  image = null;
  contactImage = null;
  images = [];
  logoImage = null;
  headerImage = null;
  uploadingProgressing = false;
  uploadComplete = false;
  serverResponse: any;
  alert = {message: null, type: null};
  errors = errorMessages;
  uploadProgress = 0;
  resellerId = null;
  countries = [];
  public filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
  countrySelected = null;
  countriesSelected = [];
  phraseText = '';
  phraseMaxChar = 160;
  descriptionText = '';
  descriptionMaxChar = 160;
  tags: Tag[] = [];
  tagsSelected = [];
  formLanguage = 'es';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private titleService: Title,
    private resellerService: ResellerService,
    private userService: UsersService,
    private categoryService: CategoriesService,
    private tagService: TagsService,
    private countryService: CountryService,
    private mediaResellerService: MediaResellerService,
    public translate: TranslateService
  ) {
    this.countryService.getCountries().subscribe(countries => {
      this.countries = countries;
    });
    this.tagService.getTags('es').subscribe(tags => {
      this.tags = tags['hydra:member'];
    });
    this.categoryService.getCategories(this.translate.getDefaultLang()).subscribe(categories => {
      this.categories = categories['hydra:member'];
    });
    this.createForm();
    if (this.activatedRoute.snapshot.params.id) {
      this.resellerId = '/resellers/' + this.activatedRoute.snapshot.params.id;
      this.title = 'Editar reseller';
      this.editReseller(this.resellerId);
    } else {
      this.createUserForm();
    }
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.userId) {
      this.resellerForm.get('user').setValue('/users/' + this.activatedRoute.snapshot.params.userId);
      this.user = true;
    }
  }

  createForm() {
    this.resellerForm = this.formBuilder.group({
      name: [ '',
        Validators.required
      ],
      fiscalName: [ '',
        Validators.required
      ],
      cif: [ '', [
        Validators.required,
        // CustomValidators.dniOrNieValidator
      ]],
      address: [ '', [
        Validators.required,
      ]],
      cp: [ '', [
        Validators.required,
      ]],
      country: [ '',
        Validators.required
      ],
      claim: [ '', [
        Validators.required,
      ]],
      whatsapp: [ '' , [
        Validators.pattern(regExps.phone)
      ]],
      instagram: [ ''],
      twitter: [ ''],
      linkedIn: [ ''],
      facebook: [ ''],
      observations: [ ''],
      urlWeb: [ '', [
        Validators.required,
        Validators.pattern(regExps.url)
        ]
      ],
      urlWebSupplier: [ '', [
        Validators.required,
        Validators.pattern(regExps.url)
      ]
      ],
      user: [ ''],
      tripadvisor: [ ''],
      mediaResellersLogos: [ [], [
        Validators.required,
      ]],
      // categories: [''],
      tags: [''],
      countries: ['']
    });
  }

  createUserForm() {
    this.userForm = this.formBuilder.group({
      email: [ '', [
        Validators.required,
        Validators.email
      ]],
      password: [ '', [
        Validators.required,
        Validators.pattern(regExps.password)
      ]],
    });
  }

  createReseller() {
    if (this.resellerForm.invalid) {
      this.showErrors(this.resellerForm);
      return;
    }
    const reseller = {
      name: this.resellerForm.get('name').value,
      fiscalName: this.resellerForm.get('fiscalName').value,
      cif: this.resellerForm.get('cif').value,
      address: this.resellerForm.get('address').value,
      cp: this.resellerForm.get('cp').value,
      country: this.resellerForm.get('country').value,
      claim: this.resellerForm.get('claim').value,
      whatsapp: this.resellerForm.get('whatsapp').value,
      instagram: this.resellerForm.get('instagram').value,
      twitter: this.resellerForm.get('twitter').value,
      linkedIn: this.resellerForm.get('linkedIn').value,
      facebook: this.resellerForm.get('facebook').value,
      observations: this.resellerForm.get('observations').value,
      urlWeb: this.resellerForm.get('urlWeb').value,
      urlWebSupplier: this.resellerForm.get('urlWebSupplier').value,
      user: this.resellerForm.get('user').value,
      tripadvisor: this.resellerForm.get('tripadvisor').value,
      mediaResellersLogos: this.resellerForm.get('mediaResellersLogos').value,
      // categories: this.resellerForm.get('categories').value,
      tags: this.resellerForm.get('tags').value,
      countries: this.resellerForm.get('countries').value
    };
    if (this.user) {
      this.resellerService.postReseller(reseller).subscribe(() => {
        this.alert.type = 1;
        this.alert.message = 'Reseller creado correctamente';
        setTimeout(() => {
          this.alert = {message: null, type: null};
        }, 5000);
        this.createForm();
        location.replace('/resellers');
      }, (error) => {
        this.alert.message = error.error.code + '- ' + error.error.message;
        this.alert.type = 2;
        setTimeout(() => {
          this.alert = {message: null, type: null};
        }, 5000);
      });
    } else if (this.userForm) {
      if (this.userForm.invalid) {
        this.showErrors(this.userForm);
        return;
      }
      const user = {
        email: this.userForm.get('email').value,
        password: this.userForm.get('password').value,
        roles: ['ROLE_RESELLER']
      };
      this.userService.postUser(user).subscribe(result => {
        reseller.user = result['@id'];
        this.resellerService.postReseller(reseller).subscribe(() => {
          this.alert.type = 1;
          this.alert.message = 'Reseller creado correctamente';
          setTimeout(() => {
            this.alert = {message: null, type: null};
          }, 5000);
          this.createForm();
          location.replace('/resellers');
        }, (error) => {
          this.alert.message = error.error.code + '- ' + error.error.message;
          this.alert.type = 2;
          setTimeout(() => {this.alert = {message: null, type: null};
          }, 5000);
        });
      }, (error) => {
        this.alert.message = error.error.code + '- ' + error.error.message;
        this.alert.type = 2;
        setTimeout(() => {this.alert = {message: null, type: null};
        }, 5000);
      });
    }
  }

  editReseller(resellerId) {
    this.resellerService.getReseller(resellerId, this.formLanguage).subscribe((reseller: Reseller) => {
      this.reseller = reseller;
      this.resellerForm.get('name').setValue(reseller.name);
      this.resellerForm.get('fiscalName').setValue(reseller.fiscalName);
      this.resellerForm.get('cif').setValue(reseller.cif);
      this.resellerForm.get('address').setValue(reseller.address);
      this.resellerForm.get('cp').setValue(reseller.cp);
      this.resellerForm.get('country').setValue(reseller.country);
      this.resellerForm.get('claim').setValue(reseller.claim);
      this.resellerForm.get('whatsapp').setValue(reseller.instagram);
      this.resellerForm.get('instagram').setValue(reseller.instagram);
      this.resellerForm.get('twitter').setValue(reseller.twitter);
      this.resellerForm.get('linkedIn').setValue(reseller.linkedIn);
      this.resellerForm.get('facebook').setValue(reseller.facebook);
      this.resellerForm.get('observations').setValue(reseller.observations);
      this.resellerForm.get('urlWeb').setValue(reseller.urlWeb);
      this.resellerForm.get('urlWebSupplier').setValue(reseller.urlWebSupplier);
      this.resellerForm.get('tripadvisor').setValue(reseller.tripadvisor);
      if (reseller.mediaResellersLogos.length > 0) {
        this.resellerForm.get('mediaResellersLogos').setValue([reseller.mediaResellersLogos[0]['@id']]);
        this.logoImage = '/assets/resellerLogo/files/' + reseller.mediaResellersLogos[0]['contentUrl'];
      }
      // this.resellerForm.get('categories').setValue(reseller.categories);
      const tags = [];
      if (reseller.tags) {
        reseller.tags.forEach(tag => {
          tags.push(tag['@id']);
        });
        this.resellerForm.get('tags').setValue(tags);
      }
      this.resellerForm.get('countries').setValue(reseller.countries);
    });
  }

  updateReseller() {
    if (this.resellerForm.invalid) {
      this.showErrors(this.resellerForm);
      return;
    }
    const reseller = {
      name: this.resellerForm.get('name').value,
      fiscalName: this.resellerForm.get('fiscalName').value,
      cif: this.resellerForm.get('cif').value,
      address: this.resellerForm.get('address').value,
      cp: this.resellerForm.get('cp').value,
      country: this.resellerForm.get('country').value,
      claim: this.resellerForm.get('claim').value,
      whatsapp: this.resellerForm.get('whatsapp').value,
      instagram: this.resellerForm.get('instagram').value,
      twitter: this.resellerForm.get('twitter').value,
      linkedIn: this.resellerForm.get('linkedIn').value,
      facebook: this.resellerForm.get('facebook').value,
      observations: this.resellerForm.get('observations').value,
      urlWeb: this.resellerForm.get('urlWeb').value,
      urlWebSupplier: this.resellerForm.get('urlWebSupplier').value,
      // user: this.resellerForm.get('user').value,
      tripadvisor: this.resellerForm.get('tripadvisor').value,
      mediaResellersLogos: this.resellerForm.get('mediaResellersLogos').value,
      // categories: this.resellerForm.get('categories').value,
      tags: this.resellerForm.get('tags').value,
      countries: this.resellerForm.get('countries').value
    };
    const editedReseller = new Reseller(reseller);
    this.resellerService.putReseller(this.reseller.id, editedReseller, this.formLanguage)
      .subscribe(
        (result) => {
          this.alert.type = 1;
          this.alert.message = 'Reseller guardado correctamente';
          this.reseller = null;
          this.createForm();
          location.replace('/resellers');
          setTimeout(() => {
            this.alert = {message: null, type: null};
          }, 5000);
        },
        error => {
          this.alert.message = error.error.code + '- ' + error.error.message;
          this.alert.type = 2;
          setTimeout(() => {this.alert = {message: null, type: null};
          }, 5000);
        },
        () => console.log('complete'));
  }

  onFileChanged(event) {
    if (event.target.files[ 0 ].type === 'image/jpeg' || event.target.files[ 0 ].type === 'image/png') {
      this.selectedFile = event.target.files[ 0 ];
      const reader = new FileReader();

      reader.onload = (event: any) => {
        this.image = event.target.result;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onUpload() {
    this.mediaResellerService.mediaResellersUpload(
      this.selectedFile, '/media_resellers_logos').subscribe(result => {
      this.handleProgress(result);
    }, error => {
      this.alert.message = error.error.code + '- ' + error.error.message;
      this.alert.type = 2;
      setTimeout(() => {this.alert = {message: null, type: null};
      }, 5000);
    });
  }

  handleProgress(event) {
    if (event.type === HttpEventType.DownloadProgress) {
      this.uploadingProgressing = true;
      this.uploadProgress = Math.round(100 * event.loaded / event.total);
    }

    if (event.type === HttpEventType.UploadProgress) {
      this.uploadingProgressing = true;
      this.uploadProgress = Math.round(100 * event.loaded / event.total);
    }

    if (event.type === HttpEventType.Response) {
      this.uploadComplete = true;
      this.uploadingProgressing = false;
      this.serverResponse = event.body;

      this.alert.type = 1;
      this.alert.message = this.translate.instant('Logo guardado correctamente');
      setTimeout(() => {
        this.alert = {message: null, type: null};
      }, 5000);

      this.resellerForm.get('mediaResellers').setValue([event.body['@id']]);
    }
  }

  onUploadedImage(uploadedImage: object, formControlName: string) {
    if (formControlName === 'mediaResellersLogos') {
      this.resellerForm.get(formControlName).patchValue([uploadedImage['id']]);
      this.logoImage = FRONT_URL + uploadedImage['url'];
    }
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    return;
  }

  showError(formValue) {
    return this.resellerForm.get(formValue).invalid && this.resellerForm.get(formValue).touched;
  }

  showUserError(formValue) {
    return this.userForm.get(formValue).invalid && this.userForm.get(formValue).touched;
  }

  changeLanguage(language) {
    this.formLanguage = language;
    // this.translate.setDefaultLang(language);
    this.editReseller(this.resellerId);
  }
}
