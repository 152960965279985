<div class="row">
  <div class="col-md-6">
    <div class="elemento1">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputMediaExperiences"><i class="fa fa-image"></i></span>
        </div>
        <div class="custom-file">
          <input
            #fileInput2
            type="file"
            id="media2"
            class="custom-file-input"
            accept=".png, .jpg, .jpeg"
            aria-describedby="inputMediaMakers"
            (change)="insertImage($event.target.files, 'featuredImageTemp2')"
            style="display: none"
            lang="es">
          <label class="custom-file-label" #labelUpload2 for="media2">{{ 'Seleccionar una imagen' | translate}}</label>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-6 border-right">
    <p class="small font-weight-bold" *ngIf="featuredImageTemp2">{{ 'Selecciona el area de la imagen' | translate}}</p>
    <angular-cropper #angularCropper2 [cropperOptions]="config" [imageUrl]="featuredImageTemp2" *ngIf="featuredImageTemp2"></angular-cropper>
  </div>
  <div class="col-6 previewer">
    <p class="small font-weight-bold" *ngIf="featuredImageTemp2">{{ 'Previsualización' | translate}}</p>
    <div>
      <div [ngClass]="preview" class="float-none" *ngIf="featuredImageTemp2"></div>
    </div>
    <button class="btn btn-success uploader float-none" type="button"  (click)="uploadFeaturedFile($event)" *ngIf="featuredImageTemp2" [disabled]="uploadingProgressing">
      <span>{{ 'Subir imagen' | translate}}</span>
    </button>
    <div *ngIf="loading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
