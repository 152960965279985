<h1 class="page-title"><i class="fa fa-tags small"></i> {{title}}</h1>
<div class="row justify-content-center">
  <button (click)="changeLanguage('es')" class="btn btn-primary mr-2 mb-2">ES</button>
  <button (click)="changeLanguage('en')" class="btn btn-primary mr-2 mb-2">EN</button>
</div>
<div class="card mt-4">
  <div class="card-body p-4">
    <form [formGroup]="categoryForm">
      <div class="row">
        <div class="col-12 form-group">
          <label>Nombre <span class="form-required text-danger">*</span></label>
          <input placeholder="Nombre" type="text" class="form-control" formControlName="name">
          <small class="text-danger" *ngIf="showError('name')">
            {{errors['categoryName']}}
          </small>
        </div>
        <div class="col-12 form-group">
          <label>Descripción</label>
          <textarea placeholder="Descripción" class="form-control" formControlName="description"></textarea>
        </div>

        <div class="col-12 form-group">
          <label>Categoría padre</label>

          <ng-select [items]="categories"
                     bindLabel="name"
                     bindValue="@id"
                     placeholder="Selecciona categorías padre"
                     formControlName="parents"
                     multiple="true">

                    
                    </ng-select>

         <!--  <select class="form-control" formControlName="parents" multiple>
            <option value="">Ninguna</option>
            <option *ngFor="let category of categories" [value]="category['@id']">{{category.name}}</option>
          </select> -->
        </div>

      </div>
      <div class="row">
        <div class="col-12">
          <button type="button" class="btn btn-primary mr-2" *ngIf="category"
                  (click)="updateCategory()" >
            Guardar Categoría
          </button>
          <button type="button" class="btn btn-primary mr-2" *ngIf="!category"
                  (click)="createCategory()" >
            Crear nueva Categoría
          </button>
          <a href="/categorias" class="btn btn-light">Cancelar</a>
        </div>
      </div>
    </form>
    <div class="mt-4" *ngIf="alert.message">
      <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
    </div>
  </div>
</div>
