import { Component, OnInit, ViewChild } from '@angular/core';
import { ExperiencesService } from '../experiences/experiences.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { Experience } from '../experiences/experience.model';

@Component({
  selector: 'app-experiences-list',
  styleUrls: ['./experiences-list.component.scss'],
  templateUrl: './experiences-list.component.html',

})
export class ExperiencesListComponent implements OnInit {
  columns = [];
  rows = [];
  searchForm: UntypedFormGroup;
  alert = { message: null, type: null };
  @ViewChild(DatatableComponent) table: DatatableComponent;
  dialogRef: BsModalRef;
  activeColumn = Boolean(sessionStorage.getItem('activeColumn'));
  spanishColumn = Boolean(sessionStorage.getItem('spanishColumn'));
  englishColumn = Boolean(sessionStorage.getItem('englishColumn'));
  actionsColumn = Boolean(sessionStorage.getItem('actionsColumn'));

  constructor(
    private experienceService: ExperiencesService,
    private searchFormBuilder: UntypedFormBuilder,
    public dialog: BsModalService,
  ) {
    this.experienceService.getExperiences().subscribe((experiences => {
      this.rows = experiences['hydra:member'];
    }));

    if (!sessionStorage.getItem('activeColumn')) {
      this.activeColumn = true
    } else {
      if (sessionStorage.getItem('activeColumn') == 'true') {
        this.activeColumn = true
      } else {
        this.activeColumn = false
      }
    }

    if (!sessionStorage.getItem('spanishColumn')) {
      this.spanishColumn = true
    } else {
      if (sessionStorage.getItem('spanishColumn') == 'true') {
        this.spanishColumn = true
      } else {
        this.spanishColumn = false
      }
    }
    if (!sessionStorage.getItem('englishColumn')) {
      this.englishColumn = true
    } else {
      if (sessionStorage.getItem('englishColumn') == 'true') {
        this.englishColumn = true
      } else {
        this.englishColumn = false
      }
    }

    if (!sessionStorage.getItem('actionsColumn')) {
      this.actionsColumn = true
    } else {
      if (sessionStorage.getItem('actionsColumn') == 'true') {
        this.actionsColumn = true
      } else {
        this.actionsColumn = false
      }
    }

  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      name: [''],
      maker: [''],
      location: [''],
      id: ['']
    });
    this.columns = [
      { prop: 'id', name: 'ID', order: 1 },
      { prop: 'name', name: 'Nombre', order: 2 },
      { prop: 'location.name', name: 'Ubicación', order: 3 },
      { prop: 'maker.email', name: 'Maker', order: 4 }];
  }

  applyFilter() {
    this.experienceService.getExperiences(this.searchForm.value).subscribe(
      data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
      }
    );
  }

  deleteExperience(experienceId) {
    this.dialogRef = this.dialog.show(ConfirmationDialogComponent, {
      initialState: {
        confirmTitle: '¿Quieres eliminar esta experiencia?',
        confirmMessage: 'No podrás volver a recuperarla',
        callback: (confirm) => {
          if (confirm) {
            this.experienceService.deleteExperience(experienceId).subscribe(
              (result) => {
                this.alert.type = 1;
                this.alert.message = 'Experiencia eliminada correctamente';
                setTimeout(() => {
                  this.alert = { message: null, type: null };
                }, 5000);
              },
              error => {
                this.alert.message = error.error.code + '- ' + error.error.message;
                this.alert.type = 2;
                setTimeout(() => {
                  this.alert = { message: null, type: null };
                }, 5000);
              },
            );
            setTimeout(() => {
              this.experienceService.getExperiences().subscribe((experiences => {
                this.rows = experiences['hydra:member'];
                this.table.offset = 0;
              }));
            }, 2000);
          }
          this.dialogRef = null;
        }
      }
    });
  }

  isActive(isActive, experience) {
    const editedExperience = new Experience({
      isActive: isActive,
    });

    let data = {
      name: experience.name,
      makerName: experience.maker['name'],
      email: experience.maker['email']
    }

    this.experienceService.putExperience(experience['@id'].replace('/experiences/', ''), editedExperience).subscribe(() => {
      this.experienceService.sendActiveNotification(data).subscribe();
    });
  }

  enableColumns(filterColumn: any) {
    switch (filterColumn) {
      case 'activeColumn':
        this.activeColumn = !this.activeColumn
        sessionStorage.setItem('activeColumn', JSON.stringify(this.activeColumn))
        break;
      case 'spanishColumn':
        this.spanishColumn = !this.spanishColumn
        sessionStorage.setItem('spanishColumn', JSON.stringify(this.spanishColumn))
        break;
      case 'englishColumn':
        this.englishColumn = !this.englishColumn
        sessionStorage.setItem('englishColumn', JSON.stringify(this.englishColumn))
        break;
      case 'actionsColumn':
        this.actionsColumn = !this.actionsColumn
        sessionStorage.setItem('actionsColumn', JSON.stringify(this.actionsColumn))
        break;
      default:
        if (this.columns.some(column => column.prop === filterColumn.prop)) {
          this.columns = this.columns.filter(column => column.prop !== filterColumn.prop);
        } else {
          this.columns.push(filterColumn)
        }
    }

    this.columns.sort((a, b) => a.order - b.order);
    sessionStorage.setItem('makerColumns', JSON.stringify(this.columns))

  }

  checkCheckbox(prop: any) {
    switch (prop) {
      case 'activeColumn':
        if (sessionStorage.getItem('activeColumn') == 'false') {
          return false
        } else {
          return true
        }
      case 'spanishColumn':
        if (sessionStorage.getItem('spanishColumn') == 'false') {
          return false
        } else {
          return true
        }
      case 'englishColumn':
        if (sessionStorage.getItem('englishColumn') == 'false') {
          return false
        } else {
          return true
        }
      case 'actionsColumn':
        if (sessionStorage.getItem('actionsColumn') == 'false') {
          return false
        } else {
          return true
        }
      default:
        if (!this.columns.some(column => column.prop === prop)) {
          return false
        } else {
          return true
        }
    }
  }

  goExperience(experience: any) {
    let makerStorage = { "id": experience.maker.id, "name": experience.maker.name }
    let experienceId = experience.id
    // const windowFeatures = 'width=600,height=400,location=no,toolbar=no,menubar=no';
    const newWindowURL = 'https://venntur.com' + '?token=' + localStorage.getItem('token') + '&user=' + localStorage.getItem('user') + '&maker=' + JSON.stringify(makerStorage) + '&experience=' + experienceId;
    window.open(newWindowURL, '_blank');
  }

}
