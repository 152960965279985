import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { of as observableOf,  Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import {Location} from '../locations/locations/location.model';
@Injectable()
export class AuthService {
  private token: string;
  private user: object;
  private refreshToken: string;
  private isAdmin: boolean;

  constructor(private http: HttpClient,
              public router: Router
  ) {
    this.loadFromStorage();
  }

  login(email: string, password: string): Promise<any> {
    const loginUrl = environment.apiUrl + '/login_check';

    return this.http.post(
      loginUrl, {
        username: email,
        password
      }).pipe(
        map((resp: any) => {
          this.saveToStorage(resp);

          return true;
        }))
      .toPromise();
  }

  doRefreshToken(): Observable<string> {
    const data = {
      refresh_token: this.refreshToken
    };
    return this.http.post<any>(environment.apiUrl + '/token/refresh', data).pipe(map(resp => resp.token));
  }

  logout() {
    this.token = '';
    this.user = null;
    this.refreshToken = '';

    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('refresh_token');

    this.router.navigate(['/login']);
  }

  isAuthenticated(): Observable<boolean> {
    return observableOf(typeof this.token !== 'undefined' && this.token.length > 5 && this.isAdmin);
  }

  isLoggedIn(): boolean {
    return this.token.length > 5 && this.isAdmin;
  }

  isReallyLoggedIn(): boolean {
    return localStorage.getItem('token') && localStorage.getItem('token').length > 5 && this.isLoggedIn() && this.isAdmin;
  }

  saveToStorage(resp: object) {
    if (resp.hasOwnProperty('token')) {
      // @ts-ignore
      localStorage.setItem('token', resp.token);
      // @ts-ignore
      this.token = resp.token;
    }

    // @ts-ignore
    if (resp.hasOwnProperty('user') && resp.user.hasOwnProperty('id')) {
      // @ts-ignore
      localStorage.setItem('user', JSON.stringify(resp.user));
      // @ts-ignore
      this.user = resp.user;
      // @ts-ignore
      this.isAdmin = this.user.roles.includes('ROLE_ADMIN');
    }

    if (resp.hasOwnProperty('refresh_token')) {
      // @ts-ignore
      localStorage.setItem('refresh_token', resp.refresh_token);
      // @ts-ignore
      this.refreshToken = resp.refresh_token;
    }

    this.http.get<any>(environment.apiUrl + '/imports/civitatis-credentials').subscribe(result => {
      localStorage.setItem('civitatis_token', result.token);
    });
  }

  loadFromStorage() {
    if ( localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.user = JSON.parse(localStorage.getItem('user'));
      this.refreshToken = localStorage.getItem('refresh_token');
      // @ts-ignore
      this.isAdmin = this.user.roles.includes('ROLE_ADMIN');
    } else {
      this.token = '';
      this.user = null;
      this.refreshToken = '';
    }
  }

  getToken(): string {
    return this.token;
  }
}
