<h1 class="page-title"><i class="fa fa-comments small"></i> {{title}}</h1>
<div class="row justify-content-center">
  <button (click)="changePlan('es')" class="btn btn-primary mr-2 mb-2">ES</button>
  <button (click)="changePlan('en')" class="btn btn-primary mr-2 mb-2">EN</button>
</div>
<div class="card mt-4">
  <div class="card-body p-4">
    <form [formGroup]="planForm">
      <div class="row">
        <div class="col-12 col-md-5 form-group">
          <label>Nombre <span class="form-required text-danger">*</span></label>
          <input name="name" placeholder="Nombre" type="text" class="form-control" formControlName="name">
          <small class="text-danger" *ngIf="showError('name')">
            {{errors['planName']}}
          </small>
        </div>
        <div class="col-8 col-md-5 form-group">
          <label>Precio <span class="form-required text-danger">*</span></label>
          <input name="price" placeholder="Precio" type="number" class="form-control" formControlName="price">
          <small class="text-danger" *ngIf="showError('price')">
            {{errors['planPrice']}}
          </small>
        </div>
        <div class="col-2 col-md-2 form-group">
          <label>Activo <span class="form-required text-danger">*</span></label>
          <!-- Checkbox -->
          <input name="isActive" type="checkbox" class="form-control" formControlName="isActive" [checked]="plan?.isActive">
        </div>
      </div>

      <!-- id, name, price, isActive, shortName, maxProducts, sku, paymentType, stripeId, description -->

      <div class="row">
        <div class="col-12 col-md-12 form-group">
          <label>Descripción</label>
          <textarea name="description" placeholder="Descripción" class="form-control" formControlName="description"></textarea>
          <small class="text-danger" *ngIf="showError('description')">
            {{errors['description']}}
          </small>
        </div>
        
        <div class="col-12 col-md-6 form-group">
          <label>Nombre corto</label>
          <input name="shortName" placeholder="Nombre" type="text" class="form-control" formControlName="shortName">
          <small class="text-danger" *ngIf="showError('shortName')">
            {{errors['shortName']}}
          </small>
        </div>

        <div class="col-12 col-md-6 form-group">
          <label>Tipo de pago</label>
          <!-- Monthly, yearly -->
          <select name="paymentType" class="form-control" formControlName="paymentType">
            <option value="">Selecciona un tipo de pago</option>
            <option [value]="'monthly'">Mensual</option>
            <option [value]="'yearly'">Anual</option>
          </select>
        </div>
        

        <div class="col-12 col-md-4 form-group">
          <label>Nº máximo de productos</label>
          <input name="maxProducts" placeholder="Maximo de productos" type="number" class="form-control" formControlName="maxProducts">
          <small class="text-danger" *ngIf="showError('maxProducts')">
            {{errors['maxProducts']}}
          </small>
        </div>

        <div class="col-12 col-md-4 form-group">
          <label>SKU</label>
          <input name="sku" placeholder="SKU" type="text" class="form-control" formControlName="sku">
          <small class="text-danger" *ngIf="showError('sku')">
            {{errors['sku']}}
          </small>
        </div>

        

        <div class="col-12 col-md-4 form-group">
          <label>Stripe ID</label>
          <input name="stripeId" placeholder="Stripe ID" type="text" class="form-control" formControlName="stripeId">
          <small class="text-danger" *ngIf="showError('stripeId')">
            {{errors['stripeId']}}
          </small>
        </div>

        <div class="col-12 form-group">
          <!-- Level -->
          <label>Nivel</label>
          <input name="level" placeholder="Nivel" type="number" class="form-control" formControlName="level">
          <small class="text-danger" *ngIf="showError('level')">
            {{errors['level']}}
          </small>
        </div>

      </div>

      <div class="row">
        <div class="col-12">
          <button type="button" class="btn btn-primary mr-2" *ngIf="plan"
                  (click)="updatePlan()" >
            Guardar Plan
          </button>
          <button type="button" class="btn btn-primary mr-2" *ngIf="!plan"
                  (click)="createPlan()" >
            Nuevo Plan
          </button>
          <a href="/plans" class="btn btn-light">Cancelar</a>
        </div>
      </div>
    </form>
    <div class="mt-4" *ngIf="alert.message">
      <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
    </div>
  </div>
</div>
