<nav class="sidebar sidebar-offcanvas pt-4" id="sidebar">
  <ul class="nav">
    <li class="nav-item pb-4">
      <a class="nav-link" routerLink="/dashboard" routerLinkActive #dashboard="routerLinkActive">
        <i class="fa fa-home menu-icon"></i>
        <span class="ml-2 menu-title">Escritorio</span>
      </a>
    </li>
    <li class="nav-item pb-4">
      <a class="nav-link submenu-toggle" (click)="isCollapsed.Experiences = !isCollapsed.Experiences"
        [attr.aria-expanded]="!isCollapsed.Experiences" aria-controls="Experiences">
        <i class="fa fa-binoculars menu-icon"></i>
        <span class="ml-2 menu-title">Experiencias</span>
        <i class="fa fa-chevron-down small"></i>
      </a>
      <ul id="Experiences" class="submenu list-unstyled" [collapse]="isCollapsed.Experiences" [isAnimated]="true">
        <li><a href="/experiencias">Experiencias</a></li>
        <li><a href="/nueva-experiencia">Nueva experiencia</a></li>
        <li><a href="/posicionamiento">Posicionamiento</a></li>
      </ul>
    </li>
    <li class="nav-item pb-4">
      <a class="nav-link submenu-toggle" (click)="isCollapsed.Categories = !isCollapsed.Categories"
        [attr.aria-expanded]="!isCollapsed.Categories" aria-controls="Categories">
        <i class="fa fa-certificate menu-icon"></i>
        <span class="ml-2 menu-title">Categorías</span>
        <i class="fa fa-chevron-down small"></i>
      </a>
      <ul id="Categories" class="submenu list-unstyled" [collapse]="isCollapsed.Categories" [isAnimated]="true">
        <li><a href="/categorias">Categorías</a></li>
        <li><a href="/nueva-categoria">Nueva Categoría</a></li>
      </ul>
    </li>
    <li class="nav-item pb-4">
      <a class="nav-link submenu-toggle" (click)="isCollapsed.Tags = !isCollapsed.Tags"
        [attr.aria-expanded]="!isCollapsed.Tags" aria-controls="Tags">
        <i class="fa fa-tags menu-icon"></i>
        <span class="ml-2 menu-title">Etiquetas</span>
        <i class="fa fa-chevron-down small"></i>
      </a>
      <div>
        <ul id="Tags" class="submenu list-unstyled" [collapse]="isCollapsed.Tags" [isAnimated]="true">
          <li><a href="/etiquetas">Etiquetas</a></li>
          <li><a href="/nueva-etiqueta">Nueva Etiqueta</a></li>
        </ul>
      </div>
    </li>
    <li class="nav-item pb-4">
      <a class="nav-link submenu-toggle" (click)="isCollapsed.Languages = !isCollapsed.Languages"
        [attr.aria-expanded]="!isCollapsed.Languages" aria-controls="Languages">
        <i class="fa fa-comments menu-icon"></i>
        <span class="ml-2 menu-title">Idiomas</span>
        <i class="fa fa-chevron-down small"></i>
      </a>
      <div>
        <ul id="Languages" class="submenu list-unstyled" [collapse]="isCollapsed.Languages" [isAnimated]="true">
          <li><a href="/idiomas">Idiomas</a></li>
          <li><a href="/nuevo-idioma">Nuevo Idioma</a></li>
        </ul>
      </div>
    </li>
    <li class="nav-item pb-4">
      <a class="nav-link submenu-toggle" (click)="isCollapsed.Locations = !isCollapsed.Locations"
        [attr.aria-expanded]="!isCollapsed.Locations" aria-controls="Locations">
        <i class="fa fa-map-pin menu-icon"></i>
        <span class="ml-2 menu-title">Ubicaciones</span>
        <i class="fa fa-chevron-down small"></i>
      </a>
      <div>
        <ul id="Locations" class="submenu list-unstyled" [collapse]="isCollapsed.Locations" [isAnimated]="true">
          <li><a href="/localidades">Ubicaciones</a></li>
          <li><a href="/nueva-localidad">Nueva Ubicación</a></li>
          <li><a href="/zonas">Zonas</a></li>
          <li><a href="/nueva-zona">Nueva Zona</a></li>
        </ul>
      </div>
    </li>
    <li class="nav-item pb-4">
      <a class="nav-link submenu-toggle" (click)="isCollapsed.Users = !isCollapsed.Users"
        [attr.aria-expanded]="!isCollapsed.Users" aria-controls="Users">
        <i class="fa fa-users menu-icon"></i>
        <span class="ml-2 menu-title">Usuarios</span>
        <i class="fa fa-chevron-down small"></i>
      </a>
      <div>
        <ul id="Users" class="submenu list-unstyled" [collapse]="isCollapsed.Users" [isAnimated]="true">
          <li><a href="/usuarios">Todos los usuarios</a></li>
          <li><a href="/makers">Todos los makers</a></li>
          <li><a href="/takers">Todos los takers</a></li>
          <li><a href="/resellers">Todos los resellers</a></li>
          <li><a href="/nuevo-maker">Nuevo maker</a></li>
          <li><a href="/nuevo-reseller">Nuevo reseller</a></li>
        </ul>
      </div>
    </li>
    <li class="nav-item pb-4">
      <a class="nav-link submenu-toggle" (click)="isCollapsed.Plans = !isCollapsed.Plans"
         [attr.aria-expanded]="!isCollapsed.Plans" aria-controls="Plans">
        <i class="fa fa-comments menu-icon"></i>
        <span class="ml-2 menu-title">Planes</span>
        <i class="fa fa-chevron-down small"></i>
      </a>
      <div>
        <ul id="Plans" class="submenu list-unstyled" [collapse]="isCollapsed.Plans" [isAnimated]="true">
          <li><a href="/planes">Planes</a></li>
          <li><a href="/nuevo-plan">Nuevo Plan</a></li>
        </ul>
      </div>
    <li class="nav-item pb-4">
      <a class="nav-link submenu-toggle" (click)="isCollapsed.Imports = !isCollapsed.Imports"
         [attr.aria-expanded]="!isCollapsed.Imports" aria-controls="Imports">
        <i class="fa fa-upload menu-icon"></i>
        <span class="ml-2 menu-title">Importaciones</span>
        <i class="fa fa-chevron-down small"></i>
      </a>
      <div>
        <ul id="Importations" class="submenu list-unstyled" [collapse]="isCollapsed.Imports" [isAnimated]="true">
          <li><a href="/importaciones">Importaciones</a></li>
        </ul>
      </div>
    </li>

  </ul>
</nav>
