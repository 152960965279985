import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent {

  public confirmTitle: string;
  public confirmMessage: string;

  constructor(public dialogRef: BsModalRef) { }

  confirm() {
    if (this.dialogRef.content.callback != null) {
      this.dialogRef.content.callback(true);
      this.dialogRef.hide();
    }
  }

  decline() {
    if (this.dialogRef.content.callback != null) {
      this.dialogRef.content.callback(false);
      this.dialogRef.hide();
    }
  }

}
