import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { TagsService } from '../tags/tags.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.css']
})
export class TagsListComponent implements OnInit {
  columns = [
    { prop: 'name', name: 'Nombre' },
    { prop: 'description', name: 'Descripción' },
    { prop: 'civitatisId', name: 'Civitatis Id' }];
  rows = [];
  searchForm: UntypedFormGroup;
  alert = {message: null, type: null};
  @ViewChild(DatatableComponent) table: DatatableComponent;
  dialogRef: BsModalRef;

  constructor(
    private tagsService: TagsService,
    private searchFormBuilder: UntypedFormBuilder,
    public dialog: BsModalService,
  ) {
    this.tagsService.getTags().subscribe((tags => {
      this.rows = tags[ 'hydra:member' ];
    }));
  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      name: ['']
    });
  }

  applyFilter() {
    this.tagsService.getTags(this.searchForm.value).subscribe(
      data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
      }
    );
  }

  deleteTag(tagId) {
    this.dialogRef = this.dialog.show(ConfirmationDialogComponent, {
      initialState: {
        confirmTitle: '¿Quieres eliminar esta etiqueta?',
        confirmMessage: 'No podrás volver a recuperarla',
        callback: (confirm) => {
          if (confirm) {
            this.tagsService.deleteTag(tagId).subscribe(
              (result) => {
                this.alert.type = 1;
                this.alert.message = 'Etiqueta eliminada correctamente';
                setTimeout(() => {
                  this.alert = {message: null, type: null};
                }, 5000);
              },
              error => {
                this.alert.message = error.error.code + '- ' + error.error.message;
                this.alert.type = 2;
                setTimeout(() => {this.alert = {message: null, type: null};
                }, 5000);
              },
            );
            setTimeout(() => {
              this.tagsService.getTags().subscribe((tags => {
                this.rows = tags[ 'hydra:member' ];
                this.table.offset = 0;
              }));
            }, 2000);
          }
          this.dialogRef = null;
        }
      }
    });
  }
}
