import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersService } from '../users/users.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { MakersService } from '../../makers/makers/makers.service';
import { User } from '../users/user.model';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {
  columns = [
    { prop: 'email', name: 'Email' }];
  rows = [];
  searchForm: UntypedFormGroup;
  alert = {message: null, type: null};
  @ViewChild(DatatableComponent) table: DatatableComponent;
  dialogRef: BsModalRef;

  constructor(
    private usersService: UsersService,
    private searchFormBuilder: UntypedFormBuilder,
    private makersService: MakersService,
    public dialog: BsModalService,
  ) {
    this.usersService.getUsers().subscribe((users => {
      this.rows = users[ 'hydra:member' ];
    }));
  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      email: ['']
    });
  }

  applyFilter() {
    this.usersService.getUsers(this.searchForm.value).subscribe(
      data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
      }
    );
  }

  addMaker(user) {
    console.log(user);
  }

  deleteMaker(makerId) {
    this.dialogRef = this.dialog.show(ConfirmationDialogComponent, {
      initialState: {
        confirmTitle: '¿Quieres eliminar el acceso maker a este usuario?',
        confirmMessage: '',
        callback: (confirm) => {
          if (confirm) {
            this.makersService.deleteMaker(makerId).subscribe(
              (result) => {
                this.alert.type = 1;
                this.alert.message = 'Maker eliminado correctamente';
                setTimeout(() => {
                  this.alert = {message: null, type: null};
                }, 5000);
              },
              error => {
                this.alert.message = error.error.code + '- ' + error.error.message;
                this.alert.type = 2;
                setTimeout(() => {this.alert = {message: null, type: null};
                }, 5000);
              },
            );
            setTimeout(() => {
              this.makersService.getMakers().subscribe((makers => {
                this.rows = makers[ 'hydra:member' ];
                this.table.offset = 0;
              }));
            }, 2000);
          }
          this.dialogRef = null;
        }
      }
    });
  }

  // deleteTaker(takerId) {
  //   this.takersService.deleteTaker(takerId).subscribe();
  //   setTimeout(() => {
  //     this.takersService.getTakers().subscribe((takers => {
  //       this.rows = takers[ 'hydra:member' ];
  //       this.table.offset = 0;
  //     }));
  //   }, 2000);
  // }

  deleteUser(userId) {
    this.dialogRef = this.dialog.show(ConfirmationDialogComponent, {
      initialState: {
        confirmTitle: '¿Quieres eliminar este usuario?',
        confirmMessage: 'No podrás volver a recuperarlo',
        callback: (confirm) => {
          if (confirm) {
            this.usersService.deleteUser(userId.replace('/users/', '')).subscribe(
              (result) => {
                this.alert.type = 1;
                this.alert.message = 'User eliminado correctamente';
                setTimeout(() => {
                  this.alert = {message: null, type: null};
                }, 5000);
              },
              error => {
                this.alert.message = error.error.code + '- ' + error.error.message;
                this.alert.type = 2;
                setTimeout(() => {this.alert = {message: null, type: null};
                }, 5000);
              },
            );
            setTimeout(() => {
              this.usersService.getUsers().subscribe((users => {
                this.rows = users[ 'hydra:member' ];
                this.table.offset = 0;
              }));
            }, 2000);
          }
          this.dialogRef = null;
        }
      }
    });
  }

  changeAdmin(isAdmin, userId, userRoles){
    if (isAdmin) {
      userRoles.push('ROLE_ADMIN');
    } else {
      const index = userRoles.indexOf('ROLE_ADMIN');
      userRoles.splice(index, 1);
    }
    const user = {
      roles: userRoles,
    };
    const editedUser = new User(user);
    this.usersService.putUser(userId, editedUser).subscribe();
  }
}
