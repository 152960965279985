import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ExperiencesListComponent } from './pages/experiences/experiences-list/experiences-list.component';
import { ExperiencesComponent } from './pages/experiences/experiences/experiences.component';
import { CategoriesComponent } from './pages/categories/categories/categories.component';
import { LanguagesComponent } from './pages/languages/languages/languages.component';
import { CategoriesListComponent } from './pages/categories/categories-list/categories-list.component';
import { LanguagesListComponent } from './pages/languages/languages-list/languages-list.component';
import { LocationsListComponent } from './pages/locations/locations-list/locations-list.component';
import { LocationsComponent } from './pages/locations/locations/locations.component';
import { UsersListComponent } from './pages/users/users-list/users-list.component';
import { UsersComponent } from './pages/users/users/users.component';
import { MakersListComponent } from './pages/makers/makers-list/makers-list.component';
import { MakersComponent } from './pages/makers/makers/makers.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './pages/shared/auth-guard.service';
import { LoggedInGuard } from './pages/shared/logged-in-guard.service';
import { TagsListComponent } from './pages/tags/tags-list/tags-list.component';
import { TagsComponent } from './pages/tags/tags/tags.component';
import { TakersComponent } from './pages/takers/takers/takers.component';
import { TakersListComponent } from './pages/takers/takers-list/takers-list.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PlansListComponent } from './pages/plans/plans-list/plans-list.component';
import { PlansComponent } from './pages/plans/plans/plans.component';
import { ResellerComponent } from './pages/resellers/reseller/reseller.component';
import { ResellersListComponent } from './pages/resellers/resellers-list/resellers-list.component';
import { ImportsComponent } from './pages/imports/imports.component';
import { ZonesComponent } from './pages/locations/zones/zones.component';
import { ZonesListComponent } from './pages/locations/zones-list/zones-list.component';
import { PositioningComponent } from './pages/positioning/positioning.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent , canActivate: [AuthGuard]},
  { path: 'usuarios', component: UsersListComponent, canActivate: [AuthGuard]},
  { path: 'planes', component: PlansListComponent, canActivate: [AuthGuard]},
  { path: 'makers', component: MakersListComponent, canActivate: [AuthGuard]},
  { path: 'takers', component: TakersListComponent, canActivate: [AuthGuard]},
  { path: 'resellers', component: ResellersListComponent, canActivate: [AuthGuard]},
  { path: 'experiencias', component: ExperiencesListComponent, canActivate: [AuthGuard]},
  { path: 'posicionamiento', component: PositioningComponent, canActivate: [AuthGuard]},
  { path: 'categorias', component: CategoriesListComponent, canActivate: [AuthGuard]},
  { path: 'etiquetas', component: TagsListComponent, canActivate: [AuthGuard]},
  { path: 'idiomas', component: LanguagesListComponent, canActivate: [AuthGuard]},
  { path: 'localidades', component: LocationsListComponent, canActivate: [AuthGuard]},
  { path: 'zonas', component: ZonesListComponent, canActivate: [AuthGuard]},
  { path: 'nuevo-usuario', component: UsersComponent, canActivate: [AuthGuard]},
  { path: 'editar-usuario/:id', component: UsersComponent, canActivate: [AuthGuard]},
  { path: 'nuevo-plan', component: PlansComponent, canActivate: [AuthGuard]},
  { path: 'editar-plan/:id', component: PlansComponent, canActivate: [AuthGuard]},
  { path: 'nuevo-maker', component: MakersComponent, canActivate: [AuthGuard]},
  { path: 'nuevo-maker/:userId', component: MakersComponent, canActivate: [AuthGuard]},
  { path: 'editar-maker/:id', component: MakersComponent, canActivate: [AuthGuard]},
  { path: 'nuevo-taker', component: TakersComponent, canActivate: [AuthGuard]},
  { path: 'nuevo-taker/:userId', component: TakersComponent, canActivate: [AuthGuard]},
  { path: 'editar-taker/:id', component: TakersComponent, canActivate: [AuthGuard]},
  { path: 'nuevo-reseller', component: ResellerComponent, canActivate: [AuthGuard]},
  { path: 'nuevo-reseller/:userId', component: ResellerComponent, canActivate: [AuthGuard]},
  { path: 'editar-reseller/:id', component: ResellerComponent, canActivate: [AuthGuard]},
  { path: 'nueva-experiencia', component: ExperiencesComponent, canActivate: [AuthGuard]},
  { path: 'editar-experiencia/:id', component: ExperiencesComponent, canActivate: [AuthGuard]},
  { path: 'nueva-categoria', component: CategoriesComponent, canActivate: [AuthGuard]},
  { path: 'editar-categoria/:id', component: CategoriesComponent, canActivate: [AuthGuard]},
  { path: 'nueva-etiqueta', component: TagsComponent, canActivate: [AuthGuard]},
  { path: 'editar-etiqueta/:id', component: TagsComponent, canActivate: [AuthGuard]},
  { path: 'nuevo-idioma', component: LanguagesComponent, canActivate: [AuthGuard]},
  { path: 'editar-idioma/:id', component: LanguagesComponent, canActivate: [AuthGuard]},
  { path: 'nueva-localidad', component: LocationsComponent, canActivate: [AuthGuard]},
  { path: 'editar-localidad/:id', component: LocationsComponent, canActivate: [AuthGuard]},
  { path: 'nueva-zona', component: ZonesComponent, canActivate: [AuthGuard]},
  { path: 'editar-zona/:id', component: ZonesComponent, canActivate: [AuthGuard]},
  { path: 'importaciones', component: ImportsComponent, canActivate: [AuthGuard]},
  { path: 'login', component: LoginComponent, canActivate: [LoggedInGuard]},
  { path: '**', component: NotFoundComponent, canActivate: [AuthGuard]},
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
})
export class AppRoutingModule { }
