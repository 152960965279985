import { Experience } from '../../experiences/experiences/experience.model';
import {Language} from '../../languages/languages/language.model';

export class LocationCollection {
  total: number;
  locations: Location[];
}

export class Location {
  id: number;
  name: string;
  country: string;
  civitatisId: number;
  experiences?: Experience[];

  constructor(values: any = {}) {
    Object.assign(this, values);
  }

  getName() {
    if (this['experiences']) {
      return this.name;
    }
    return this.name;
  }

  getNameAndEventCount() {
    if (this['experiences']) {
      return this.name + ' (' + this.experiences.length + ')';
    }
    return this.name;
  }
}
