<div class="container-scroller">
  <app-navbar></app-navbar>
  <div class="container-fluid page-body-wrapper d-flex">
    <app-sidebar *ngIf="showSidebar"></app-sidebar>
    <div class="main-panel w-100 bg-light" [ngClass]="{'p-5' : showSidebar}">
      <div class="content-wrapper">
        <div class="h-100">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
