import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MakersService } from './makers.service';
import { Maker } from './maker.model';
import { User } from '../../users/users/user.model';
import { UsersService } from '../../users/users/users.service';
import { HttpEventType } from '@angular/common/http';
import { MediaMakersService } from './media-makers';
import { CustomValidators, errorMessages, regExps } from '../../shared/custom-validators';
import { TranslateService } from '@ngx-translate/core';
import { Tag } from '../../tags/tags/tag.model';
import { TagsService } from '../../tags/tags/tags.service';
import { environment } from '../../../../environments/environment';

const FRONT_URL = environment.frontUrl;

@Component({
  selector: 'app-makers',
  templateUrl: './makers.component.html',
  styleUrls: ['./makers.component.css']
})
export class MakersComponent implements OnInit {
  makerForm: UntypedFormGroup;
  userForm: UntypedFormGroup;
  maker: Maker;
  makerId = '';
  documentTypes = ['DNI', 'NIE', 'Otros'];
  documentTypeSelected = null;
  users: User[] = [];
  userSelected = [];
  selectedFile: File;
  selectedFiles = [];
  uploadedFiles = [];
  image = null;
  images = [];
  logoImage = null;
  headerImage = null;
  uploadingProgressing = false;
  uploadComplete = false;
  serverResponse: any;
  uploadProgress = 0;
  user = false;
  title = 'Nuevo maker';
  alert = {message: null, type: null};
  errors = errorMessages;
  phraseText = '';
  phraseMaxChar = 160;
  descriptionText = '';
  descriptionMaxChar = 160;
  plan = null;
  invoices = null;
  tags: Tag[] = [];
  tagsSelected = [];
  mediaMakersStaff = [];
  fileToUpload: File = null;
  formLanguage = 'es';
  @ViewChild('labelUpload')
  labelUpload: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private makerService: MakersService,
    private userService: UsersService,
    private tagService: TagsService,
    private mediaMakerService: MediaMakersService,
    private el: ElementRef,
    private translate: TranslateService
  ) {
    this.tagService.getTags(this.formLanguage).subscribe(tags => {
      this.tags = tags['hydra:member'];
    });
    this.createForm();
    if (this.activatedRoute.snapshot.params.id) {
      this.makerId = this.activatedRoute.snapshot.params.id;
      this.title = 'Editar maker';
      this.editMaker(this.makerId);
    } else {
      this.createUserForm();
    }
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.userId) {
      this.makerForm.get('user').setValue('/users/' + this.activatedRoute.snapshot.params.userId);
      this.user = true;
    }
  }

  createForm() {
    this.makerForm = this.formBuilder.group({
      name: [ '',
        Validators.required
      ],
      documentType: [ '',
        Validators.required
      ],
      document: [ '', [
        Validators.required,
        // CustomValidators.dniOrNieValidator
      ]],
      mediaMakers: [ '',
        Validators.required
      ],
      mediaMakersHeader: [ []
      ],
      mediaMakersLogo: [ []
      ],
      mediaMakersStaff: [[]
      ],
      phrase: [ '' ],
      phone: [ '', [
        Validators.required,
        Validators.pattern(regExps.phone),
        Validators.minLength(4),
        Validators.maxLength(14),
      ]],
      whatsapp: [ '', [
        Validators.pattern(regExps.phone),
      ]],
      email: [ '', [
        Validators.required,
        Validators.email
      ]],
      web: [ '', [
        Validators.required,
        Validators.pattern(regExps.url)
      ]],
      slug: [ '', [
        Validators.required,
        Validators.pattern(regExps.slug)
      ]],
      sustainableSeal: [ false ],
      user: [ ''],
      tripadvisor: [ ''],
      description: [ ''],
      founders: [ ''],
      director: [ ''],
      operations: [ ''],
      technology: [ ''],
      customerId: [ ''],
      instagram: [ ''],
      twitter: [ ''],
      linkedIn: [ ''],
      facebook: [ ''],
      tags: [ '']
    });
  }

  createUserForm() {
    this.userForm = this.formBuilder.group({
      email: [ '', [
        Validators.required,
        Validators.email
      ]],
      password: [ '', [
        Validators.required,
        Validators.pattern(regExps.password)
      ]],
    });
  }

  deleteMaker(makerId) {
    const id = makerId.replace('/makers/', '');
    this.makerService.deleteMaker(id).subscribe(() => {
      },
      error => {
      });
  }

  editMaker(makerId) {
    this.makerService.getMaker(makerId, this.formLanguage).subscribe((maker: Maker) => {
      this.maker = maker;
      this.makerForm.get('documentType').setValue(maker.documentType);
      this.makerForm.get('document').setValue(maker.document);
      this.makerForm.get('name').setValue(maker.name);
      this.makerForm.get('phrase').setValue(maker.phrase);
      this.makerForm.get('phone').setValue(maker.phone);
      this.makerForm.get('whatsapp').setValue(maker.whatsapp);
      this.makerForm.get('email').setValue(maker.email);
      this.makerForm.get('web').setValue(maker.web);
      this.makerForm.get('slug').setValue(maker.slug);
      this.makerForm.get('sustainableSeal').setValue(maker.sustainableSeal);
      if (maker.mediaMakers.length > 0) {
        this.makerForm.get('mediaMakers').setValue([maker.mediaMakers[0]['@id']]);
        this.image = 'https://venntur.com/assets/maker/files/' + maker.mediaMakers[0]['contentUrl'];
      }
      if (maker['makerPlan'] && maker['makerPlan']['stripeCustomerId']) {
        if (maker['makerPlan']['plan']['name'] === "Básico") {
          this.enableBasicFields();
        }
        this.makerService.getInvoices(maker['makerPlan']['stripeCustomerId']).subscribe((result) => {
          this.invoices = result['invoices'];
        });
      }
      this.makerForm.get('tripadvisor').setValue(maker.tripadvisor);
      this.makerForm.get('description').setValue(maker.description);
      this.makerForm.get('founders').setValue(maker.founders);
      this.makerForm.get('director').setValue(maker.director);
      this.makerForm.get('operations').setValue(maker.operations);
      this.makerForm.get('technology').setValue(maker.technology);
      this.makerForm.get('customerId').setValue(maker.customerId);
      this.makerForm.get('instagram').setValue(maker.instagram);
      this.makerForm.get('twitter').setValue(maker.twitter);
      this.makerForm.get('linkedIn').setValue(maker.linkedIn);
      this.makerForm.get('facebook').setValue(maker.facebook);
      this.makerForm.get('tags').setValue(maker.tags);
      if (maker.mediaMakersHeader.length > 0) {
        this.makerForm.get('mediaMakersHeader').setValue([maker.mediaMakersHeader[0]['@id']]);
        this.headerImage = 'https://venntur.com/assets/makerHeader/files/' + maker.mediaMakersHeader[0]['contentUrl'];
      }
      if (maker.mediaMakersLogo.length > 0) {
        this.makerForm.get('mediaMakersLogo').setValue([maker.mediaMakersLogo[0]['@id']]);
        this.logoImage = 'https://venntur.com/assets/makerLogo/files/' + maker.mediaMakersLogo[0]['contentUrl'];
      }
      maker.mediaMakersStaff.forEach(mediaMakerStaff => {
        if (!this.mediaMakersStaff.some(e => e.id === mediaMakerStaff['@id'])) {
          this.mediaMakersStaff.push({url: 'https://venntur.com/assets/makerStaff/files/' + mediaMakerStaff['contentUrl'],
            id: mediaMakerStaff['@id']});
          this.uploadedFiles.push(mediaMakerStaff['@id']);
        } else if (this.mediaMakersStaff.length <= 0) {
          this.mediaMakersStaff.push({url: 'https://venntur.com/assets/makerStaff/files/' + mediaMakerStaff['contentUrl'],
            id: mediaMakerStaff['@id']});
          this.uploadedFiles.push(mediaMakerStaff['@id']);
        }

      });
      this.makerForm.get('mediaMakersStaff').setValue(this.uploadedFiles);
    });
  }

  updateMaker() {
    if (this.makerForm.invalid) {
      this.showErrors(this.makerForm);
      return;
    }
    const maker = {
      name: this.makerForm.get('name').value,
      documentType: this.makerForm.get('documentType').value,
      document: this.makerForm.get('document').value,
      mediaMakers: this.makerForm.get('mediaMakers').value,
      mediaMakersHeader: this.makerForm.get('mediaMakersHeader').value,
      mediaMakersLogo: this.makerForm.get('mediaMakersLogo').value,
      mediaMakersStaff: this.makerForm.get('mediaMakersStaff').value,
      phrase: this.makerForm.get('phrase').value,
      phone: this.makerForm.get('phone').value,
      whatsapp: this.makerForm.get('whatsapp').value,
      email: this.makerForm.get('email').value,
      web: this.makerForm.get('web').value,
      slug: this.makerForm.get('slug').value,
      sustainableSeal: this.makerForm.get('sustainableSeal').value,
      tripadvisor: this.makerForm.get('tripadvisor').value,
      description: this.makerForm.get('description').value,
      founders: this.makerForm.get('founders').value,
      director: this.makerForm.get('director').value,
      operations: this.makerForm.get('operations').value,
      technology: this.makerForm.get('technology').value,
      customerId: this.makerForm.get('customerId').value,
      instagram: this.makerForm.get('instagram').value,
      twitter: this.makerForm.get('twitter').value,
      linkedIn: this.makerForm.get('linkedIn').value,
      facebook: this.makerForm.get('facebook').value,
      tags: this.makerForm.get('tags').value
      // user: this.makerForm.get('user').value
    };
    const editedMaker = new Maker(maker);
    this.makerService.putMaker(this.maker.id, editedMaker, this.formLanguage)
      .subscribe(
        (result) => {
          this.alert.type = 1;
          this.alert.message = 'Maker guardado correctamente';
          this.maker = null;
          this.createForm();
          location.replace('/makers');
          setTimeout(() => {
            this.alert = {message: null, type: null};
          }, 5000);
        },
        error => {
          this.alert.message = error.error.code + '- ' + error.error.message;
          this.alert.type = 2;
          setTimeout(() => {this.alert = {message: null, type: null};
          }, 5000);
        },
        () => console.log('complete'));
  }

  createMaker() {
    if (this.makerForm.invalid) {
      this.showErrors(this.makerForm);
      return;
    }
    const maker = {
      name: this.makerForm.get('name').value,
      documentType: this.makerForm.get('documentType').value,
      document: this.makerForm.get('document').value,
      mediaMakers: this.makerForm.get('mediaMakers').value,
      mediaMakersHeader: this.makerForm.get('mediaMakersHeader').value,
      mediaMakersLogo: this.makerForm.get('mediaMakersLogo').value,
      mediaMakersStaff: this.makerForm.get('mediaMakersStaff').value,
      phrase: this.makerForm.get('phrase').value,
      phone: this.makerForm.get('phone').value,
      whatsapp: this.makerForm.get('whatsapp').value,
      email: this.makerForm.get('email').value,
      web: this.makerForm.get('web').value,
      slug: this.makerForm.get('slug').value,
      sustainableSeal: this.makerForm.get('sustainableSeal').value,
      user: this.makerForm.get('user').value,
      tripadvisor: this.makerForm.get('tripadvisor').value,
      description: this.makerForm.get('description').value,
      founders: this.makerForm.get('founders').value,
      director: this.makerForm.get('director').value,
      operations: this.makerForm.get('operations').value,
      technology: this.makerForm.get('technology').value,
      customerId: this.makerForm.get('customerId').value,
      instagram: this.makerForm.get('instagram').value,
      twitter: this.makerForm.get('twitter').value,
      linkedIn: this.makerForm.get('linkedIn').value,
      facebook: this.makerForm.get('facebook').value,
      tags: this.makerForm.get('tags').value
    };
    if (this.user) {
      this.makerService.postMaker(maker).subscribe(() => {
        this.alert.type = 1;
        this.alert.message = 'Maker creado correctamente';
        setTimeout(() => {
          this.alert = {message: null, type: null};
        }, 5000);
        this.createMakerPlan();
        location.replace('/makers');
      }, (error) => {
        this.alert.message = error.error.code + '- ' + error.error.message;
        this.alert.type = 2;
        setTimeout(() => {this.alert = {message: null, type: null};
        }, 5000);
      });
    }
    else if (this.userForm) {
      if (this.userForm.invalid) {
        this.showErrors(this.userForm);
        return;
      }
      const user = {
        email: this.userForm.get('email').value,
        password: this.userForm.get('password').value,
        roles: ['ROLE_MAKER']
      };
      this.userService.postUser(user).subscribe(result => {
        maker.user = result['@id'];
        this.makerService.postMaker(maker).subscribe(() => {
          this.alert.type = 1;
          this.alert.message = 'Maker creado correctamente';
          setTimeout(() => {
            this.alert = {message: null, type: null};
          }, 5000);
          this.createMakerPlan();
          location.replace('/makers');
        }, (error) => {
          this.alert.message = error.error.code + '- ' + error.error.message;
          this.alert.type = 2;
          setTimeout(() => {this.alert = {message: null, type: null};
          }, 5000);
        });
      }, (error) => {
        this.alert.message = error.error.code + '- ' + error.error.message;
        this.alert.type = 2;
        setTimeout(() => {this.alert = {message: null, type: null};
        }, 5000);
      });
    }
  }

  onFileChanged(event, files: FileList) {
    if (event.target.files[ 0 ].type === 'image/jpeg' || event.target.files[ 0 ].type === 'image/png') {
      this.selectedFile = event.target.files[ 0 ];
      const reader = new FileReader();

      reader.onload = (event: any) => {
        this.image = event.target.result;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  enableBasicFields() {
    this.makerForm.get('phrase').enable();
    this.makerForm.get('tripadvisor').enable();
    this.makerForm.get('description').enable();
    // this.makerForm.get('tripadvisor').setValidators([Validators.required]);
    // this.makerForm.get('description').setValidators([Validators.required]);
    this.makerForm.get('founders').enable();
    this.makerForm.get('director').enable();
    // this.makerForm.get('founders').setValidators([Validators.required]);
    // this.makerForm.get('director').setValidators([Validators.required]);
    this.makerForm.get('operations').enable();
    this.makerForm.get('technology').enable();
    // this.makerForm.get('operations').setValidators([Validators.required]);
    // this.makerForm.get('technology').setValidators([Validators.required]);
    this.makerForm.get('customerId').enable();
    this.makerForm.get('instagram').enable();
    // this.makerForm.get('customerId').setValidators([Validators.required]);
    // this.makerForm.get('instagram').setValidators([Validators.required]);
    this.makerForm.get('twitter').enable();
    this.makerForm.get('linkedIn').enable();
    // this.makerForm.get('twitter').setValidators([Validators.required]);
    // this.makerForm.get('linkedIn').setValidators([Validators.required]);
    this.makerForm.get('facebook').enable();
    // this.makerForm.get('facebook').setValidators([Validators.required]);
    this.makerForm.get('tags').enable();
    // this.makerForm.get('tags').setValidators([Validators.required]);
    this.makerForm.updateValueAndValidity();
  }

  onUpload() {
    this.mediaMakerService.mediaMakersUpload(
      this.selectedFile, '/media_makers').subscribe(result => {
      this.handleProgress(result);
    }, error => {
      this.alert.message = error.error.code + '- ' + error.error.message;
      this.alert.type = 2;
      setTimeout(() => {this.alert = {message: null, type: null};
      }, 5000);
    });
  }

  handleProgress(event) {
    if (event.type === HttpEventType.DownloadProgress) {
      this.uploadingProgressing = true;
      this.uploadProgress = Math.round(100 * event.loaded / event.total);
    }

    if (event.type === HttpEventType.UploadProgress) {
      this.uploadingProgressing = true;
      this.uploadProgress = Math.round(100 * event.loaded / event.total);
    }

    if (event.type === HttpEventType.Response) {
      this.uploadComplete = true;
      this.uploadingProgressing = false;
      this.serverResponse = event.body;

      this.alert.type = 1;
      this.alert.message = 'Logo guardado correctamente';
      setTimeout(() => {
        this.alert = {message: null, type: null};
      }, 5000);

      this.makerForm.get('mediaMakers').setValue([event.body['@id']]);
    }
  }

  onUploadedImage(uploadedImage: object, formControlName: string) {
    if (formControlName === 'mediaMakersStaff') {
      this.mediaMakersStaff.push({
        url: 'https://venntur.com/' + uploadedImage['url'],
        id: uploadedImage['id']
      });
      this.uploadedFiles.push(uploadedImage['id']);
      this.makerForm.get(formControlName).patchValue(this.uploadedFiles);
    }
    if (formControlName === 'mediaMakers') {
      this.makerForm.get(formControlName).patchValue([uploadedImage['id']]);
      this.image = 'https://venntur.com/' + uploadedImage['url'];
    }
    if (formControlName === 'mediaMakersLogo') {
      this.makerForm.get(formControlName).patchValue([uploadedImage['id']]);
      this.logoImage = 'https://venntur.com/' + uploadedImage['url'];
    }
    if (formControlName === 'mediaMakersHeader') {
      this.makerForm.get(formControlName).patchValue([uploadedImage['id']]);
      this.headerImage = 'https://venntur.com/' + uploadedImage['url'];
    }
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector( 'form .ng-invalid' );
    firstInvalidControl.focus();
    return;
  }

  showUserError(formValue) {
    return this.userForm.get(formValue).invalid && this.userForm.get(formValue).touched;
  }

  showError(formValue) {
    // if (formValue === 'documentType' && this.makerForm.get('documentType').value === 'Otros') {
    //   this.makerForm.get('document').clearValidators();
    //   this.makerForm.get('document').setValidators([Validators.required]);
    //   this.makerForm.updateValueAndValidity();
    // } else if (this.makerForm.get('documentType').value !== 'Otros') {
    //   this.makerForm.get('document').setValidators([
    //     Validators.required,
    //     CustomValidators.dniOrNieValidator]);
    //   this.makerForm.updateValueAndValidity();
    // }
    return this.makerForm.get(formValue).invalid && this.makerForm.get(formValue).touched;
  }

  changeLanguage(language) {
    this.formLanguage = language;
    this.editMaker(this.makerId);
  }

  deleteMediaMakersStaff(image, uploaded) {
    if (uploaded) {
      this.mediaMakerService.deleteMediaMakers(image).subscribe(() => {
          const index = this.uploadedFiles.indexOf(image);
          this.uploadedFiles.splice(index, 1);
          this.mediaMakersStaff = this.mediaMakersStaff.filter((el) => el.id !== image);
          this.makerForm.get('mediaMakersStaff').setValue(this.uploadedFiles);
        }
      );
    } else {
      this.selectedFiles = this.selectedFiles.filter((el) => el.name !== image);
      this.images = this.images.filter((el) => el.name !== image);
    }
  }

  createMakerPlan() {
    this.makerService.postFreeMakerPlan({price: 0, email: this.userForm.get('email').value}).subscribe(() => {});
  }
}
