import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Category } from '../../categories/categories/category.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CategoriesService } from '../../categories/categories/categories.service';
import { CountryService } from './country.service';
import { LocationService } from './location.service';
import { Location } from './location.model';
import { errorMessages } from '../../shared/custom-validators';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../shared/auth.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {
  locationForm: UntypedFormGroup;
  location: Location;
  civitatisLocations = null;
  countries = [];
  countrySelected = null;
  civitatisLocationSelected = null;
  title = 'Nueva ubicación';
  alert = {message: null, type: null};
  errors = errorMessages;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private categoryService: CategoriesService,
    private countryService: CountryService,
    private locationService: LocationService,
    private el: ElementRef,
    public translate: TranslateService,
    private authService: AuthService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.id) {
      this.title = 'Editar ubicación';
      this.editLocation(this.activatedRoute.snapshot.params.id);
    }
    this.countryService.getCountries().subscribe(countries => {
      this.countries = countries;
    });
    this.locationService.getCivitatisLocations(localStorage.getItem('civitatis_token')).subscribe(result => {
      this.civitatisLocations = result['civitatisLocations'];
    });
  }

  createForm() {
    this.locationForm = this.formBuilder.group({
      name: [ '',
        Validators.required
      ],
      country: [ '',
        Validators.required
      ],
      civitatisId: []
    });
  }

  deleteLocation(locationId) {
    const id = locationId.replace('/locations/', '');
    this.locationService.deleteLocation(id).subscribe(() => {
      },
      error => {
      });
  }

  editLocation(locationId) {
    this.locationService.getLocation(locationId, this.translate.getDefaultLang()).subscribe((location: Location) => {
      this.location = location;
      this.locationForm.get('name').setValue(location.name);
      this.locationForm.get('country').setValue(location.country);
      this.locationForm.get('civitatisId').setValue(location.civitatisId);
    });
  }

  updateLocation() {
    if (this.locationForm.invalid) {
      this.showErrors(this.locationForm);
      return;
    }
    const locationPlace = {
      name: this.locationForm.get('name').value,
      country: this.locationForm.get('country').value,
      civitatisId: this.locationForm.get('civitatisId').value
    };
    const editedLocation = new Location(locationPlace);
    this.locationService.putLocation(this.location['@id'], editedLocation, this.translate.getDefaultLang())
      .subscribe(
        (result) => {
          this.location = null;
          this.createForm();
          this.alert.type = 1;
          this.alert.message = 'Ubicación guardada correctamente';
          setTimeout(() => {
            this.alert = {message: null, type: null};
          }, 5000);
          location.replace('/localidades');
        },
        error => {
          this.alert.message = error.error.code + '- ' + error.error.message;
          this.alert.type = 2;
          setTimeout(() => {this.alert = {message: null, type: null};
          }, 5000);
        },
        () => console.log('complete'));
  }

  createLocation() {
    if (this.locationForm.invalid) {
      this.showErrors(this.locationForm);
      return;
    }
    const locationPlace = {
      name: this.locationForm.get('name').value,
      country: this.locationForm.get('country').value,
      civitatisId: this.locationForm.get('civitatisId').value
    };
    this.locationService.postLocation(locationPlace).subscribe((result) => {
      this.createForm();
      this.alert.type = 1;
      this.alert.message = 'Ubicación creada correctamente';
      setTimeout(() => {
        this.alert = {message: null, type: null};
      }, 5000);
      location.replace('/localidades');
    }, (error) => {
      this.alert.message = error.error.code + '- ' + error.error.message;
      this.alert.type = 2;
      setTimeout(() => {this.alert = {message: null, type: null};
      }, 5000);
    });
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector( 'form .ng-invalid' );
    firstInvalidControl.focus();
    return;
  }

  showError(formValue) {
    return this.locationForm.get(formValue).invalid && this.locationForm.get(formValue).touched;
  }

  changeLanguage(language) {
    this.translate.setDefaultLang(language);
    setTimeout(() => {
      this.editLocation(this.activatedRoute.snapshot.params.id);
    }, 1000);
  }
}
