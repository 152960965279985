import { Component, OnInit, ViewChild } from '@angular/core';
import { LanguagesService } from '../languages/languages.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-languages-list',
  templateUrl: './languages-list.component.html',
  styleUrls: ['./languages-list.component.css']
})
export class LanguagesListComponent implements OnInit {
  columns = [];
  rows = [];
  searchForm: UntypedFormGroup;
  alert = {message: null, type: null};
  @ViewChild(DatatableComponent) table: DatatableComponent;
  dialogRef: BsModalRef;

  constructor(
    private languagesService: LanguagesService,
    private searchFormBuilder: UntypedFormBuilder,
    public dialog: BsModalService,
  ) {
    this.languagesService.getLanguages().subscribe((languages => {
      this.rows = languages[ 'hydra:member' ];
    }));
  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      name: ['']
    });
    this.columns = [
      { prop: 'name', name: 'Nombre' }];
  }

  applyFilter() {
    this.languagesService.getLanguages(this.searchForm.value).subscribe(
      data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
      }
    );
  }

  deleteLanguage(languageId) {
    this.dialogRef = this.dialog.show(ConfirmationDialogComponent, {
      initialState: {
        confirmTitle: '¿Quieres eliminar este idioma?',
        confirmMessage: 'No podrás volver a recuperarlo',
        callback: (confirm) => {
          if (confirm) {
            this.languagesService.deleteLanguage(languageId).subscribe(
              (result) => {
                this.alert.type = 1;
                this.alert.message = 'Idioma eliminado correctamente';
                setTimeout(() => {
                  this.alert = {message: null, type: null};
                }, 5000);
              },
              error => {
                this.alert.message = error.error.code + '- ' + error.error.message;
                this.alert.type = 2;
                setTimeout(() => {this.alert = {message: null, type: null};
                }, 5000);
              },
            );
            setTimeout(() => {
              this.languagesService.getLanguages().subscribe((languages => {
                this.rows = languages[ 'hydra:member' ];
                this.table.offset = 0;
              }));
            }, 2000);
          }
          this.dialogRef = null;
        }
      }
    });
  }
}
