<h1 class="page-title"><i class="fa fa-upload small"></i> {{title}}</h1>
<div class="mt-4 text-center">
  <p class="alert alert-info">{{quantityImports}}</p>
</div>
<div class="card mt-4">
  <div class="card-body p-4">
    <form [formGroup]="importForm">
      <div class="row">
        <div class="col-6 form-group">
          <label>Makers distribuidores<span class="form-required text-danger">*</span></label>
          <ng-select placeholder="Seleccionar la importación" aria-label="Import" multiple formControlName="imports" [(ngModel)]="importsSelected" required>
            <ng-option *ngFor="let import of imports" [value]="import">{{ import }}</ng-option>
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <button type="button" class="btn btn-primary mr-2"
                  (click)="makeImport()" [disabled]="isLoading">
            Importar
          </button>
          <a href="/dashboard" class="btn btn-light">Cancelar</a>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="mt-4" *ngIf="alert.message">
  <p class="alert text-center" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
</div>
<div *ngIf="isLoading" class="text-center mt-4">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only"></span>
  </div>
</div>
