<h1 class="page-title"><i class="fa fa-users small"></i> {{title}}</h1>
<!--<div class="row justify-content-center">-->
<!--  <button (click)="changeLanguage('es')" class="btn btn-primary mr-2 mb-2">ES</button>-->
<!--  <button (click)="changeLanguage('en')" class="btn btn-primary mr-2 mb-2">EN</button>-->
<!--</div>-->
<div *ngIf="maker" class="card mb-4">
  <div class="card-body p-4">
    <h4 class="border-bottom pb-2 mb-3"><i class="fa fa-certificate small mr-1 text-black-50"></i> Tu plan Maker </h4>
    <div class="row maker-admin-form">
      <div class="col-md-4 form-group">
        <label>Tipo de plan<span class="form-required text-danger"></span></label>
        <p class="form-control" readonly>{{maker['makerPlan']['plan']['name']}}</p>
      </div>
      <div class="col-md-4 form-group">
        <label>Precio tarifa<span class="form-required text-danger"></span></label>
        <p class="form-control" readonly>{{maker['makerPlan']['price'] > 0 ? maker['makerPlan']['price'].substr(0, 1) + '.' + maker['makerPlan']['price'].substr(1) + '€' : 0 + '€'}}</p>
      </div>
      <div class="col-md-4 form-group">
        <label>Fecha de inicio<span class="form-required text-danger"></span></label>
        <p class="form-control" readonly>{{maker['makerPlan']['startDate'] | date: 'dd/MM/yyyy'}}</p>
      </div>
<!--      <div class="col-md-4 form-group">-->
<!--        <button *ngIf="maker && maker['makerPlan']['plan']['name'] === 'Gratis'" type="button" class="btn btn-primary mr-2 mb-2"-->
<!--                (click)="createMakerPlan(true)" >-->
<!--          {{ 'Cambiar a plan básico' | translate}}-->
<!--        </button>-->
<!--      </div>-->
    </div>
    <div *ngIf="maker['makerPlan'] && maker['makerPlan']['stripeCustomerId']">
      <h5>{{ 'Recibos' | translate}}</h5>
      <ul *ngFor="let invoice of invoices">
        <li><a [href]="invoice['invoice_pdf']">{{invoice['created']*1000 | date: 'dd/MM/yyyy'}}</a></li>
      </ul>
    </div>
  </div>
</div>
<!--<ul class="nav nav-tabs mb-n1 text-uppercase small">-->
<!--  <li class="nav-item">-->
<!--    <a class="nav-link active font-weight-bold" (click)="changeLanguage('es')">Español</a>-->
<!--  </li>-->
<!--  <li class="nav-item">-->
<!--    <a class="nav-link active bg-light" (click)="changeLanguage('en')">'Inglés</a>-->
<!--  </li>-->
<!--</ul>-->
<div class="card mt-3">
  <div class="card-body p-4">
    <h4 class="border-bottom pb-2 mb-3"><i class="fa fa-lock small mr-1 text-black-50"></i> Datos privados</h4>
    <form *ngIf="!user && !maker" [formGroup]="userForm">
      <div class="row">
        <div class="col-md-6 form-group">
          <label>Email <span class="form-required text-danger">*</span></label>
          <input placeholder="Email del usuario" type="email" class="form-control" formControlName="email">
          <small class="text-danger" *ngIf="showUserError('email')">
            {{errors['email']}}
          </small>
        </div>
        <div class="col-md-6 form-group">
          <label>Contraseña <span class="form-required text-danger">*</span></label>
          <input type="password" appPassToggle placeholder="Contraseña"  class="form-control" formControlName="password">
          <small class="text-danger" *ngIf="showUserError('password')">
            {{errors['password']}}
          </small>
        </div>
      </div>
    </form>
    <form [formGroup]="makerForm">
      <div class="row">
        <div class="col-md-6 form-group">
          <label>Tipo de documento <span class="form-required text-danger">*</span></label>
          <ng-select bindLabel="Tipo de promoción"
                     placeholder="Seleccionar tipo de documento"
                     appendTo="body"
                     formControlName="documentType"
                     [(ngModel)]="documentTypeSelected">
            <ng-option [value]="documentType" *ngFor="let documentType of documentTypes">{{documentType}}</ng-option>
          </ng-select>
          <small class="text-danger" *ngIf="showError('documentType')">
            {{errors['makerDocumentType']}}
          </small>
        </div>
        <div class="col-md-6 form-group">
          <label>Documento <span class="form-required text-danger">*</span></label>
          <input placeholder="Documento" type="text" class="form-control" formControlName="document">
          <small class="text-danger" *ngIf="showError('document')">
            {{errors['document']}}
          </small>
        </div>
      </div>
      <fieldset class="mt-5">
        <legend><h4 class="border-bottom pb-2 mb-3"><i class="fa fa-eye small mr-1"></i> Datos públicos</h4></legend>
        <div class="row">
          <div class="col-12 form-group">
            <label>Nombre <span class="form-required text-danger">*</span></label>
            <input placeholder="Nombre empresa u organizador" type="text" class="form-control" formControlName="name">
            <small class="text-danger" *ngIf="showError('name')">
              {{errors['makerName']}}
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-group">
            <label>Teléfono <span class="form-required text-danger">*</span></label>
            <input placeholder="Teléfono o móvil de contacto" type="text" class="form-control" formControlName="phone">
            <small class="text-danger" *ngIf="showError('phone')">
              {{errors['phone']}}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>WhatsApp</label>
            <input placeholder="WhatsApp" type="text" class="form-control" formControlName="whatsapp">
            <small class="text-danger" *ngIf="showError('whatsapp')">
              {{errors['phone']}}
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-group">
            <label>Email <span class="form-required text-danger">*</span></label>
            <input placeholder="Email de contacto" type="email" class="form-control" formControlName="email">
            <small class="text-danger" *ngIf="showError('email')">
              {{errors['email']}}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>Página web <span class="form-required text-danger">*</span></label>
            <input placeholder="Página web" type="url" class="form-control" formControlName="web">
            <small class="text-danger" *ngIf="showError('web')">
              {{errors['makerWeb']}}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>Slug (URL corta)<span class="form-required text-danger">*</span></label>
            <input placeholder="slug" type="text" class="form-control" formControlName="slug">
            <small class="text-danger" *ngIf="showError('slug')">
              {{errors['slug']}}
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col form-group">
            <label for="mediaCropper">Subir avatar Maker<span class="form-required text-danger">*</span> </label>
            <small class="d-block text-muted mb-2">Selecciona un avatar de Maker. Formatos aceptados: .jpeg, .jpg, .png</small>
            <small class="form-text text-muted">Dimensiones recomendadas: 150x150</small>
            <app-cropper id="mediaCropper" [type]="'maker'" [aspectratio]= "0" [preview]="'preview1'" (uploadedImage)="onUploadedImage($event, 'mediaMakers')"></app-cropper>
            <small class="text-danger" *ngIf="showError('mediaMakers')">
              {{errors['mediaMaker']}}
            </small>
          </div>
        </div>
        <div *ngIf="image" class="mb-4">
          <p class="small">Avatar subido:</p>
          <img [src]="image" height="150" width="150"> <br/>
        </div>
        <h4 class="border-bottom pb-2 my-3"><i class="fa fa-user-circle small mr-1 text-black-50"></i>Datos de tu ficha Maker</h4>
        <div class="row">
          <div class="col form-group">
            <label for="mediaCropper">Subir cabecera para tu ficha Maker<span class="form-required text-danger">*</span> </label>
            <small class="d-block text-muted mb-2">Selecciona una cabecera para mostrar en tu ficha pública de Maker. Formatos aceptados: .jpeg, .jpg, .png</small>
            <small class="form-text text-muted">Dimensiones recomendadas: 1920x640</small>
            <app-cropper-2 id="mediaCropper2" [type]="'makerHeader'" [aspectratio]= "1920/640" [preview]="'preview2'" (uploadedImage)="onUploadedImage($event, 'mediaMakersHeader')"></app-cropper-2>
            <small class="text-danger" *ngIf="showError('mediaMakersHeader')">
              {{errors['mediaMakersHeader']}}
            </small>
          </div>
        </div>
        <div *ngIf="headerImage" class="mb-4">
          <p class="small">Cabecera subida a tu ficha:</p>
          <img [src]="headerImage" height="150" width="300"> <br/>
        </div>
        <div class="row">
          <div class="col form-group">
            <label for="mediaCropper4">Subir logo Maker<span class="form-required text-danger">*</span> </label>
            <small class="d-block text-muted mb-2">Selecciona un logo de Maker. Formatos aceptados: .jpeg, .jpg, .png</small>
            <small class="form-text text-muted">Dimensiones recomendadas: 250x100</small>
            <app-cropper-4 id="mediaCropper4" [type]="'maker'" [aspectratio]= "2.9" [preview]="'preview4'" (uploadedImage)="onUploadedImage($event, 'mediaMakersLogo')"></app-cropper-4>
            <small class="text-danger" *ngIf="showError('mediaMakersLogo')">
              {{errors['mediaMakersLogo']}}
            </small>
          </div>
        </div>
        <div *ngIf="logoImage" class="mb-4">
          <p class="small">Logo subido:</p>
          <img [src]="logoImage" height="100" width="250"> <br/>
        </div>
        <div class="row">
          <div class="col-12 form-group">
            <label>Frase</label>
            <textarea placeholder="Frase, claim o llamada a la acción..." class="form-control" formControlName="phrase"
                      [(ngModel)]="phraseText" maxlength="{{ phraseMaxChar }}" rows="3" disabled></textarea>
            <span class="pull-right badge badge-secondary">{{phraseText ? phraseText.length : 0}} / {{ phraseMaxChar }}</span>
            <small class="form-text text-muted">Max. 160 caracteres</small>
          </div>
        </div>
        <div class="col-12 form-group">
          <label>Descripción<span class="form-required text-danger"></span></label>
          <textarea placeholder="Descripción del maker" class="form-control" formControlName="description"
                    [(ngModel)]="descriptionText" maxlength="{{ descriptionMaxChar }}" rows="3" disabled></textarea>
          <span class="pull-right badge badge-dark mt-1">{{descriptionText ? descriptionText.length : 0}} / {{ descriptionMaxChar }}</span>
          <small class="form-text text-muted">Max. 160 caracteres</small>
          <!--          <small class="text-danger" *ngIf="showError('description')">-->
          <!--            {{translate.instant(errors['description'])}}-->
          <!--          </small>-->
        </div>
        <div class="row">
          <div class="col-md-6 form-group">
            <label>Fundadores<span class="form-required text-danger"></span></label>
            <input placeholder="Fundadores" type="text" class="form-control" formControlName="founders" disabled>
            <small class="text-danger" *ngIf="showError('founders')">
              {{errors['founders']}}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>Director/CEO<span class="form-required text-danger"></span></label>
            <input placeholder="Director/CEO" type="text" class="form-control" formControlName="director" disabled>
            <small class="text-danger" *ngIf="showError('director')">
              {{errors['director']}}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>Operaciones & Viper Team manager<span class="form-required text-danger"></span></label>
            <input placeholder="Operaciones & Viper Team manager" type="text" class="form-control" formControlName="operations" disabled>
            <small class="text-danger" *ngIf="showError('operations')">
              {{errors['operations']}}
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-group">
            <label>Tecnología de reservas<span class="form-required text-danger"></span></label>
            <input placeholder="Tecnología de reservas" type="text" class="form-control" formControlName="technology" disabled>
            <small class="text-danger" *ngIf="showError('technology')">
              {{errors['technology']}}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>ID cliente<small>(de tu motor de reservas)</small> <span class="form-required text-danger"></span></label>
            <input placeholder="Id cliente" type="text" class="form-control" formControlName="customerId" disabled>
            <small class="text-danger" *ngIf="showError('customerId')">
              {{errors['customerId']}}
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-group">
            <label>Tripavisor<span class="form-required text-danger"></span></label>
            <input placeholder="Id de tripadvisor" type="text" class="form-control" formControlName="tripadvisor" disabled>
          </div>
          <div class="col-md-6 form-group">
            <label>LinkedIn<span class="form-required text-danger"></span></label>
            <input placeholder="LinkedIn" type="text" class="form-control" formControlName="linkedIn" disabled>
            <small class="form-text text-muted">Usuario de LinkedIn(Ej: company/12345678/admin/)</small>
            <small class="text-danger" *ngIf="showError('linkedIn')">
              {{errors['linkedIn']}}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>Facebook<span class="form-required text-danger"></span></label>
            <input placeholder="Facebook" type="text" class="form-control" formControlName="facebook" disabled>
            <small class="form-text text-muted">Nombre de usuario de Facebook</small>
            <small class="text-danger" *ngIf="showError('facebook')">
              {{errors['facebook']}}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>Instagram<span class="form-required text-danger"></span></label>
            <input placeholder="Instagram" type="text" class="form-control" formControlName="instagram" disabled>
            <small class="form-text text-muted">Nombre de usuario de Instagram</small>
            <small class="text-danger" *ngIf="showError('instagram')">
              {{errors['instagram']}}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>Twitter<span class="form-required text-danger"></span></label>
            <input placeholder="Twitter" type="text" class="form-control" formControlName="twitter" disabled>
            <small class="form-text text-muted">Nombre de usuario de Twitter</small>
            <small class="text-danger" *ngIf="showError('twitter')">
              {{errors['twitter']}}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>Etiquetas que te definen<span class="form-required text-danger">*</span></label>
            <ng-select bindLabel="Etiquetas"
                       placeholder="Seleccionar etiquetas"
                       multiple="true"
                       appendTo="body"
                       formControlName="tags"
                       [(ngModel)]="tagsSelected" [disabled]="true">
              <ng-option [value]="tag['@id']" *ngFor="let tag of tags">{{tag.name}}</ng-option>
            </ng-select>
          </div>
        </div>
        <div class="row">
          <div class="col form-group">
            <label for="mediaCropper" class="mb-0">Seleccionar imágenes del staff<span class="form-required text-danger">*</span></label>
            <small class="d-block text-muted mb-2">Selecciona imágenes del staff<strong>Mín. 1 y máx. 5 imágenes.</strong>Formatos aceptados: .jpeg, .jpg, .png</small>
            <small class="form-text text-muted">Dimensiones recomendadas: 520x520</small>
            <app-cropper-3 id="mediaCropper3" [type]="'makerStaff'" [aspectratio]= "1/1" [preview]="'preview3'" (uploadedImage)="onUploadedImage($event, 'mediaMakersStaff')"></app-cropper-3>
            <small class="text-danger" *ngIf="showError('mediaMakersStaff')">
              {{errors['mediaMakersStaff']}}
            </small>
          </div>
        </div>
        <div *ngIf="mediaMakersStaff" class="container__uploaded-images">
          <h5 *ngIf="mediaMakersStaff?.length"><i class="fa fa-image small"></i>Imágenes subidas</h5>
          <div class="row">
            <div class="col-lg-2 col-md-4" *ngFor='let mediaMakerStaff of mediaMakersStaff' >
              <img [src]="mediaMakerStaff.url" class="img-fluid"><br/>
              <button class="btn btn-outline-primary btn--delete-image" (click)="deleteMediaMakersStaff(mediaMakerStaff.id, true)">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </fieldset>
      <div class="row">
        <div class="col-12">
          <button type="button" class="btn btn-primary mr-2" *ngIf="maker"
                  (click)="updateMaker()" >
            Guardar Maker
          </button>
          <button type="button" class="btn btn-primary mr-2" *ngIf="!maker"
                  (click)="createMaker()" >
            Crear nuevo Maker
          </button>
          <a href="/makers" class="btn btn-light">Cancelar</a>
        </div>
      </div>
    </form>
    <div class="mt-4" *ngIf="alert.message">
      <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
    </div>
  </div>
</div>
