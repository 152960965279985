<h1 class="page-title"><i class="fa fa-binoculars small"></i> {{title}}</h1>
<div class="row justify-content-center">
  <button (click)="changeLanguage('es')" class="btn btn-primary mr-2 mb-2">ES</button>
  <button (click)="changeLanguage('en')" class="btn btn-primary mr-2 mb-2">EN</button>
</div>
<div class="card mt-4">
  <div class="card-body p-4">
    <form [formGroup]="experienceForm">
      <div class="row">
        <div class="col-12 form-group">
          <label>Título de la experiencia <span class="form-required text-danger">*</span></label>
          <input placeholder="Título de la experiencia" type="text" class="form-control" formControlName="name">
          <small class="text-danger" *ngIf="showError('name')">
            {{errors['experienceName']}}
          </small>
        </div>
        <div class="col-md-6 form-group">
          <label>URL de la experiencia <span class="form-required text-danger">*</span></label>
          <input placeholder="URL de la experiencia" type="url" class="form-control" formControlName="url">
          <small class="text-danger" *ngIf="showError('url')">
            {{errors['experienceUrl']}}
          </small>
        </div>
        <div class="col-md-6 form-group">
          <label>Texto del botón <small>(URL experiencia)</small>&nbsp;<span class="form-required text-danger">*</span></label>
          <select class="form-control" formControlName="urlButtonText">
            <option disabled>Seleccionar una opción</option>
            <option *ngFor="let buttonText of urlButtonTexts" [value]="buttonText.value">{{ buttonText.text }}</option>
          </select>
          <small class="text-danger" *ngIf="showError('urlButtonText')">
            {{errors['experienceUrlButtonText']}}
          </small>
        </div>
        <div class="col-12 form-group">
          <label>Descripción <span class="form-required text-danger">*</span></label>
          <textarea placeholder="Descripción" class="form-control" formControlName="description"
                    [(ngModel)]="descriptionText" maxlength="{{ descriptionMaxChar }}" rows="4"></textarea>
          <span class="pull-right badge badge-secondary">{{descriptionText ? descriptionText.length : 0}} / {{ descriptionMaxChar }}</span>
          <small class="form-text text-muted">Mín. 120 y máx. 600 caracteres</small>
          <small class="text-danger" *ngIf="showError('description')">
            {{errors['experienceDescription']}}
          </small>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-6 form-group">
          <label>Inicio de temporada</label>
          <ng-select placeholder="Seleccionar mes" formControlName="seasonStartMonth">
            <ng-option *ngFor="let month of monthList" [value]="month">{{ month }}</ng-option>
          </ng-select>
        </div>
        <div class="col-md-6 form-group">
          <label>Fin de temporada</label>
          <ng-select placeholder="Seleccionar mes" formControlName="seasonEndMonth">
            <ng-option *ngFor="let month of monthList" [value]="month">{{ month }}</ng-option>
          </ng-select>
        </div>
      </div> -->
      <div class="row">
        <div class="col-md-6 form-group">
          <label>Duración de la experiencia <span class="form-required text-danger">*</span></label>
          <input placeholder="Duración" type="time" class="form-control" formControlName="duration">
          <small class="text-danger" *ngIf="showError('duration')">
            {{errors['experienceDuration']}}
          </small>
        </div>
        <div class="col-md-6 form-group">
          <label>Idiomas <span class="form-required text-danger">*</span></label>
          <ng-select bindLabel="Idiomas"
                     placeholder="Seleccionar idioma"
                     multiple="true"
                     appendTo="body"
                     formControlName="languages"
                     [(ngModel)]="languagesSelected">
            <ng-option [value]="language['@id']" *ngFor="let language of languages">{{language.name}}</ng-option>
          </ng-select>
          <small class="text-danger" *ngIf="showError('languages')">
            {{errors['experienceLanguages']}}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-group">
          <label>Categorías <span class="form-required text-danger">*</span></label>
          <ng-select bindLabel="Categorías"
                     placeholder="Seleccionar categoría"
                     multiple="true"
                     appendTo="body"
                     formControlName="categories"
                     [(ngModel)]="categoriesSelected"
                     (change)="loadSubcategories($event)">
            <ng-option [value]="category['@id']" *ngFor="let category of categories">{{category.name}}</ng-option>
          </ng-select>
          <small class="text-danger" *ngIf="showError('categories')">
            {{errors['experienceCategories']}}
          </small>
        </div>


        <div class="col-md-6 form-group">
          <label>{{ "Subcategorías" | translate }}
            <span class="form-required text-danger"></span></label>
          <ng-select bindLabel="Subcategorías" [placeholder]="translate.instant('Seleccionar subcategorías')"
            multiple="true" appendTo="body" [(ngModel)]="subcategoriesSelected" formControlName="subcategories">
            <ng-option [value]="category['@id']" *ngFor="let category of subcategories">{{ category.name
              }}</ng-option>
          </ng-select>
          <small class="text-danger" *ngIf="showError('categories')">
            {{ translate.instant(errors["experienceCategories"]) }}
          </small>
        </div>


        <div class="col-md-6 form-group">
          <label>Etiquetas <span class="form-required text-danger">*</span></label>
          <ng-select bindLabel="Etiquetas"
                     placeholder="Seleccionar etiquetas"
                     multiple="true"
                     appendTo="body"
                     formControlName="tags"
                     [(ngModel)]="tagsSelected">
            <ng-option [value]="tag['@id']" *ngFor="let tag of tags">{{tag.name}}</ng-option>
          </ng-select>
          <small class="text-danger" *ngIf="showError('tags')">
            {{errors['experienceTags']}}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-group">
          <label>País <span class="form-required text-danger">*</span></label>
          <ng-select placeholder="Seleccionar el país" aria-label="Country" formControlName="country" [(ngModel)]="countrySelected" (change)="loadLocations($event)" required>
            <ng-option *ngFor="let country of countries" [value]="country.code">{{ country.text }}</ng-option>
          </ng-select>
          <small class="text-danger" *ngIf="showError('country')">
            {{errors['experienceCountry']}}
          </small>
        </div>
        <div class="col-md-6 form-group">
          <label>Ubicación <span class="form-required text-danger">*</span></label>
          <ng-select placeholder="Seleccionar la ubicación" aria-label="Location" formControlName="location" [(ngModel)]="locationSelected" required>
            <ng-option *ngFor="let location of filteredLocations | async" [value]="location['@id']">{{ location.getName() }}</ng-option>
          </ng-select>
          <small class="text-danger" *ngIf="showError('location')">
            {{errors['experienceLocation']}}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-group">
          <label>Tipo de promoción</label>
          <ng-select bindLabel="Tipo de promoción"
                     placeholder="Seleccionar tipo de promoción"
                     appendTo="body"
                     formControlName="promotionType"
                     [(ngModel)]="promotionTypeSelected">
            <ng-option [value]="promotionType" *ngFor="let promotionType of promotionTypes">{{promotionType}}</ng-option>
          </ng-select>
        </div>
        <div class="col-md-6 form-group">
          <label>Descripción de la promoción</label> <small class="text-muted pl-2 align-text-top">Máx. 160 caracteres</small>
          <input placeholder="Promoción" type="text" class="form-control" formControlName="promotion" maxlength="160">
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-group">
          <label>Tripadvisor</label>
          <input placeholder="Tripadvisor" type="text" class="form-control" formControlName="reviewTripadvisor">
        </div>
        <div class="col-md-6 form-group">
          <label>Maker <span class="form-required text-danger">*</span></label>
          <ng-select bindLabel="Maker"
                     placeholder="Seleccionar maker"
                     appendTo="body"
                     formControlName="maker"
                     [(ngModel)]="makerSelected">
            <ng-option [value]="maker['@id']" *ngFor="let maker of makers">{{maker.name}}</ng-option>
          </ng-select>
          <small class="text-danger" *ngIf="showError('maker')">
            {{errors['experienceMaker']}}
          </small>
        </div>
        <!-- <div class="col-md-6 form-group form-check">
          <label class="form-check-label" for="sustainableSeal">Experiencia sostenible</label>
          <div class="form-group form-check mt-2">
            <input type="checkbox" class="form-check-input" formControlName="sustainableSeal" id="sustainableSeal">
            <label class="form-check-label" for="sustainableSeal">Sello sostenibilidad</label>
          </div>
        </div> -->
      </div>
      <div class="row">

      </div>
      <div class="row">
        <div class="col form-group">
          <label for="mediaCropper" class="mb-0">{{ 'Seleccionar imágenes' }} <span class="form-required text-danger">*</span></label>
          <small class="d-block text-muted mb-2">Selecciona imágenes para mostrar en el listado de Experiencias. <strong>Mín. 1 y máx. 5 imágenes.</strong> Formatos aceptados: .jpeg, .jpg, .png</small>
          <app-cropper id="mediaCropper" [type]="'experience'" [aspectratio]= "3/2" [preview]="'preview2'" (uploadedImage)="onUploadedImage($event, 'mediaExperiences')"></app-cropper>
          <small class="text-danger" *ngIf="showError('mediaExperiences')">
            {{errors['mediaExperiences']}}
          </small>
        </div>
      </div>
      <div *ngIf="mediaExperiences" class="container__uploaded-images">
        <h5 *ngIf="mediaExperiences?.length"><i class="fa fa-image small"></i> Imágenes subidas</h5>
        <div class="row">
          <div class="col-lg-2 col-md-4" *ngFor='let mediaExperience of mediaExperiences'>
            <img [src]="mediaExperience.url" class="img-fluid"><br/>
            <button class="btn btn-outline-primary btn--delete-image" (click)="deleteMediaExperience(mediaExperience.id, true)">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button type="button" class="btn btn-primary mr-2" *ngIf="experience"
                  (click)="updateExperience()" >
            Guardar Experiencia
          </button>
          <button type="button" class="btn btn-primary mr-2" *ngIf="!experience"
                  (click)="createExperience()">
            Crear nueva Experiencia
          </button>
          <a href="/experiencias" class="btn btn-light">Cancelar</a>
        </div>
      </div>
    </form>
    <div class="mt-4" *ngIf="alert.message">
      <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
    </div>
  </div>
</div>
