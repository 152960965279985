import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Maker, MakerCollection } from './maker.model';


const API_URL = environment.apiUrl;

@Injectable()
export class MakersService {
  constructor(
    private http: HttpClient
  ) { }

  public postMaker(maker): Observable<Maker> {
    return this.http
      .post<Maker>(API_URL + '/makers', maker);
  }

  public getMaker(id: string, language): Observable<Maker> {
    return this.http
      .get<Maker>(API_URL + '/makers/' + id, { headers: {
          'Accept-Language': language
      }});
  }

  public getInvoices(stripeCustomerId): Observable<any> {
    return this.http
      .get<any>(API_URL + '/maker_plans/invoices/' + stripeCustomerId);
  }

  public getMakers(filters?: any): Observable<MakerCollection> {
    if (filters === undefined) { filters = {}; }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('name', String(filters.name));
    }
    if (filters['document']) {
      httpParams = httpParams.set('document', String(filters.document));
    }
    return this.http
      .get<MakerCollection>(API_URL + '/makers', { params: httpParams });
  }

  public deleteMaker(makerId: string): any {
    return this.http.delete<Maker>(API_URL + makerId);
  }

  public putMaker(makerId: string, maker: Maker, language): Observable<Maker> {
    return this.http
      .put<Maker>(API_URL + '/makers/' + makerId, maker, { headers: {
          'Accept-Language': language
      }});
  }

  public postFreeMakerPlan(makerPlan): Observable<object> {
    return this.http
      .post<any>(API_URL + '/maker_plans/maker-plan-request/gratis', makerPlan);
  }


public getAllMakers(url: any): Observable<any> {
    return this.http
    .get<any>(API_URL + '/makers' + url);
  }

  public getExperiences(url: any): Observable<any> {
    return this.http
    .get<any>(API_URL + '/experiences' + url);
  }

  public putExperience(experienceId: string, experience: any, language): Observable<any> {
    return this.http
    .put<any>(API_URL + '/experiences/' + experienceId, experience, { headers: { 'Accept-Language': language }});
  }


  public patchExperience(experienceId: string, experience: any, language): Observable<any> {
    return this.http
    .patch<any>(API_URL + '/experiences/' + experienceId, experience, { headers: 
      { 'Accept-Language': language ,
        'content-type': 'application/merge-patch+json'
      }});
  }

public getExperienceBySlug(slug: string): Observable<any> {
  return this.http
  .get<any>(API_URL + '/experiences?slug=' + slug);
}



  

}
