import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  alert = {message: null, type: null};
  constructor(private authService: AuthService,
              private fb: UntypedFormBuilder,
              private router: Router) {
    this.loginForm = fb.group({
      email: ['',
        [Validators.required, Validators.email]
      ],
      password: ['',
        Validators.required
      ]
    });
  }

  ngOnInit() { }

  login() {
    const loginModel = this.loginForm.value;
    this.authService.login(loginModel.email, loginModel.password)
      .then(() => {
        this.router.navigateByUrl('experiencias')
          .then((isAdmin) => {
            this.alert.type = 1;
            (isAdmin ? this.alert.message = 'Login correcto' : this.alert.message = 'El usuario no tiene permisos de acceso');
            setTimeout(() => {
              this.alert = {message: null, type: null};
            }, 5000);
          });
      })
      .catch(error => {
        this.alert.message = error.error.code + '- ' + error.error.message;
        this.alert.type = 2;
        setTimeout(() => {this.alert = {message: null, type: null};
        }, 5000); });
  }
}
